<template>
  <v-card>
    <v-card-title
      class="black--text headline grey lighten-2 d-flex justify-center"
    >
      REGISTRO USUARIO
    </v-card-title>
    <v-card-text>
      <v-form v-if="!QRGenerated" ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="registry.tipoDocumento"
              :items="documentTypes"
              item-text="Title"
              item-value="Type"
              :rules="[(v) => !!v || 'El campo es obligatorio.']"
              label="Tipo Documento"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="registry.id"
              :rules="registry.tipoDocumento == 'PASS' ? passRules : ccRules"
              label="Número Documento"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="registry.firstname"
              :rules="namesRule"
              label="Primer Nombre"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="registry.secondname"
              label="Segundo Nombre"
              :rules="secondNameRule"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="registry.surname1"
              :rules="namesRule"
              label="Primer Apellido"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="registry.surname2"
              label="Segundo Apellido"
              :rules="namesRule"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              v-model="datepicker1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="birthDatePicker"
                  label="Fecha Nacimiento"
                  prepend-icon="mdi-calendar"
                  :rules="[(v) => !!v || 'El campo es obligatorio.']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="birthDatePicker"
                @input="datepicker1 = false"
                locale="es-MX"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="registry.sex"
              :items="genres"
              item-text="Title"
              item-value="Type"
              :rules="[(v) => !!v || 'El campo es obligatorio.']"
              label="Género"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="registry.blood"
              :items="bloodTypes"
              item-text="Title"
              :rules="[(v) => !!v || 'El campo es obligatorio.']"
              label="Tipo de Sangre"
              required
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-btn
              :disabled="!valid"
              block
              color="primary"
              class="mr-4"
              @click="generateQR(registry)"
            >
              GENERAR QR
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="QRGenerated" class="pt-5">
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <p class="font-weight-medium text-center text-h6">
            Ubique este código QR en el lector del dispositivo para proceder con
            su registro.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <qrcode-vue
            class="d-flex justify-center align-center"
            :value="QRValue"
            :size="size"
            level="H"
          />
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-btn
            block
            color="error"
            class="mr-4"
            @click="
              QRGenerated = false;
              QRValue = '';
              registry.id = ''
            "
          >
            ATRAS
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-btn block color="primary" class="mr-4" @click="clearRegistry">
            GENERAR NUEVO QR
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import auth from "../services/auth";
import backlog from "../services/logs";

export default {
  name: "UserRegistry",
  components: {
    QrcodeVue,
  },
  data: () => ({
    datepicker1: false,
    birthDatePicker: "",
    QRValue: "",
    size: 250,
    QRGenerated: false,
    valid: true,
    namesRule: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/.test(v) || "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    secondNameRule: [
      (v) => /^$|[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/.test(v) || "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    ccRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => (v && v.length >= 7) || "El documento debe tener minimo 7 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El documento solo debe tener números",
    ],
    passRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => (v && v.length >= 7) || "El documento debe tener minimo 7 digitos.",
      (v) => /^[0-9A-Za-z _]*[0-9A-Za-z][0-9A-Za-z _]*$/.test(v) || "El documento no debe tener caracteres especiales o tildes.",
    ],
    documentTypes: [
      { Type: "CC", Title: "Cédula de Ciudadanía" },
      { Type: "CE", Title: "Cédula de Extranjería" },
      { Type: "TI", Title: "Tarjeta de Identidad" },
      { Type: "PASS", Title: "Pasaporte" },
    ],
    genres: [
      { Type: "M", Title: "MASCULINO" },
      { Type: "F", Title: "FEMENINO" },
    ],
    bloodTypes: ["A+", "B+", "O+", "AB+", "A-", "B-", "O-", "AB-"],
    registry: {
      tipoDocumento: "",
      id: "",
      firstname: "",
      secondname: "",
      surname1: "",
      surname2: "",
      birthdate: "",
      sex: "",
      blood: "",
    },
  }),
  methods: {
    generateQR(reg) {
      if (this.$refs.form.validate() == true) {
          if (reg.id.length == 7) {
            reg.id = "000" + reg.id;
          } else if (reg.id.length == 8) {
            reg.id = "00" + reg.id;
          } else if (reg.id.length == 9) {
            reg.id = "0" + reg.id;
          }
        reg.birthdate = this.birthDatePicker.split("-").reverse().join("-");
        reg.firstname = reg.firstname.toUpperCase().trim();
        reg.secondname = reg.secondname.toUpperCase().trim();
        reg.surname1 = reg.surname1.toUpperCase().trim();
        reg.surname2 = reg.surname2.toUpperCase().trim();
        const str = JSON.stringify(reg, null, 0);
        this.QRValue = "QR" + str;
        this.QRGenerated = true;
        this.QRValue = this.QRValue.normalize("NFD").replace(/[\u0300-\u0302]/g, "");
        this.QRValue = this.QRValue.normalize("NFD").replace(/[\u0304-\u036f]/g, "");
        this.QRValue = this.QRValue.replace(/Ñ/g, "%");
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "create/QR/registry"})
      }
    },

    clearRegistry() {
      this.registry.tipoDocumento = "";
      this.registry.id = "";
      this.registry.firstname = "";
      this.registry.secondname = "";
      this.registry.surname1 = "";
      this.registry.surname2 = "";
      this.registry.birthdate = "";
      this.registry.sex = "";
      this.registry.blood = "";
      this.birthDateIcker = "";
      this.QRGenerated = false;
      this.QRValue = "";
      this.valid = true;
    },
  },
  mounted() {},
};
</script>
