<template>
  <v-container fluid>
    <v-overlay absolute opacity="0.8" :value="overlay">
      <v-card color="primary" dark>
        <v-card-text>
          Validando imagen, por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <v-stepper v-model="e1" class="no-boxshadow">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> SELFIE </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          DOCUMENTO 1
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3">
          DOCUMENTO 2
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" step="4">
          VALIDACIÓN DATOS
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="5"> RESUMEN </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="d-flex justify-center">
            <v-card width="600px" class="no-boxshadow">
              <v-card-title class="d-flex justify-center text-center text-h5">
                Subir una selfie reciente
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-img
                      v-if="firstImg == null"
                      src="../../assets/vt-module-id-img.svg"
                      width="600px"
                      height="400px"
                      class="white--text"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                      aspect-ratio="2"
                      contain
                    >
                    </v-img>
                    <v-img
                      v-if="firstImg != null"
                      :src="firstImg"
                      width="600px"
                      height="400px"
                      class="white--text align-center"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                      aspect-ratio="2"
                      contain
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      color="blue-grey"
                      class="white--text"
                      @click="onPickFirstFile"
                    >
                      SUBIR SELFIE
                      <v-icon class="pl-2" dark> mdi-camera</v-icon>
                    </v-btn>
                    <input
                      type="file"
                      @change="uploadFirstImg"
                      style="display: none"
                      ref="firstFileInput"
                      accept="image/*"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  rounded
                  large
                  :disabled="firstImg == null"
                  @click="validateSelfie"
                >
                  CONTINUAR
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="d-flex justify-center">
            <v-card width="600px" class="no-boxshadow">
              <v-card-title class="d-flex justify-center text-center text-h5">
                Subir documento por el lado del rostro
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                      <v-row v-if="frontDocImg == null">
                        <v-col cols="12" class="text-center pt-10">
                          <p class="text'h6">IMAGEN DE REFERENCIA</p>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center align-center">
                          <v-img
                            src="../../assets/cedula_frontal.jpg"
                            width="400px"
                            height="200px"
                            class="white--text"
                            aspect-ratio="2"
                            contain
                          >
                          </v-img>
                        </v-col>
                      </v-row>
                      <v-img
                        v-if="frontDocImg != null"
                        :src="frontDocImg"
                        width="600px"
                        height="400px"
                        class="white--text align-center"
                        aspect-ratio="2"
                        contain
                      >
                      </v-img>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      color="blue-grey"
                      class="white--text"
                      @click="onPickSecondFile"
                    >
                      DOCUMENTO LADO 1
                      <v-icon class="pl-2" dark>
                        mdi-card-account-details-outline</v-icon
                      >
                    </v-btn>
                    <input
                      type="file"
                      @change="uploadSecondImg"
                      style="display: none"
                      ref="secondFileInput"
                      accept="image/*"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  rounded
                  large
                  :disabled="frontDocImg == null"
                  @click="validateFrontDocumentImg"
                >
                  CONTINUAR
                </v-btn>
                <v-btn rounded large @click="e1 = 1"> ATRÁS </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="d-flex justify-center">
            <v-card width="600px" class="no-boxshadow">
              <v-card-title class="d-flex justify-center text-center text-h5">
                Subir documento por el lado de atrás
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                      <v-row v-if="backDocImg == null">
                        <v-col cols="12" class="text-center pt-10">
                          <p class="text'h6">IMAGEN DE REFERENCIA</p>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-content align-center">
                          <v-img
                            src="../../assets/cedula_reves.png"
                            width="400px"
                            height="200px"
                            class="white--text"
                            aspect-ratio="2"
                            contain
                          >
                          </v-img>
                        </v-col>
                      </v-row>
                    <v-img
                      v-if="backDocImg != null"
                      :src="backDocImg"
                      width="600px"
                      height="400px"
                      class="white--text align-center"
                      aspect-ratio="2"
                      contain
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      color="blue-grey"
                      class="white--text"
                      @click="onPickBackDocFile"
                    >
                      DOCUMENTO LADO 2
                      <v-icon class="pl-2" dark>
                        mdi-card-account-details-outline</v-icon
                      >
                    </v-btn>
                    <input
                      type="file"
                      @change="uploadBackDocImg"
                      style="display: none"
                      ref="backDocFileInput"
                      accept="image/*"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  rounded
                  large
                  :disabled="backDocImg == null"
                  @click="validateBackDocumentImg"
                >
                  CONTINUAR
                </v-btn>
                <v-btn rounded large @click="e1 = 2"> ATRÁS </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <div class="d-flex justify-center">
            <v-card width="600px" class="no-boxshadow">
              <v-card-title class="d-flex justify-center text-center text-h4">
                Validación de datos
              </v-card-title>
              <v-card-text class="py-10">
                <v-row class="pb-5">
                  <v-col cols="12">
                    <span
                      >Si por alguna razón sus datos están incompletos o
                      erroneos haga clic en su respectivo botón y
                      editelos.</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="nombre1"
                      label="Primer Nombre."
                      placeholder="Primer Nombre."
                      type="text"
                      outlined
                      :disabled="!editNamesFields"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="nombre2"
                      label="Segundo Nombre."
                      placeholder="Segundo Nombre."
                      type="text"
                      outlined
                      :disabled="!editNamesFields"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="apellido1"
                      label="Primer Apellido."
                      placeholder="Primer Apellido."
                      type="text"
                      outlined
                      :disabled="!editNamesFields"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="apellido2"
                      label="Segundo Nombre."
                      placeholder="Segundo Nombre."
                      type="text"
                      outlined
                      :disabled="!editNamesFields"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="text-center">
                    <p v-if="!editCedulaField" class="text-h5">
                      Documento de Identificación:
                      <strong>{{ cedula }}</strong>
                    </p>
                    <v-text-field
                      v-if="editCedulaField"
                      v-model="cedula"
                      label="Documento de Identificación."
                      placeholder="Documento de Identificación."
                      type="number"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" v-if="!editDateField" class="text-center">
                    <p class="text-h5">
                      Fecha de Expedición:
                      <strong>{{ expeditionDate }}</strong>
                    </p>
                  </v-col>
                  <v-col cols="12" md="4" v-if="editDateField">
                    <v-text-field
                      v-model="expeditionDay"
                      label="Día de Expedición."
                      placeholder="Día de Expedición."
                      type="number"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="editDateField">
                    <v-text-field
                      v-model="expeditionMonth"
                      label="Mes de Expedición."
                      placeholder="Mes de Expedición."
                      type="number"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="editDateField">
                    <v-text-field
                      v-model="expeditionYear"
                      label="Año de Expedición."
                      placeholder="Año de Expedición."
                      type="number"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      block
                      dark
                      color="primary"
                      small
                      @click="editNamesFields = !editNamesFields ; editDateField = !editDateField ; editCedulaField = !editCedulaField"
                      title="Corregir Datos"
                    >
                      Corregir datos
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" rounded large @click="validateResume">
                  FINALIZAR
                </v-btn>
                <v-btn rounded large @click="e1 = 3"> ATRÁS </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card>

            <v-overlay absolute opacity="1" :value="overlaySimilarity">
              <v-card color="primary" dark>
                <v-card-text>
                  Generando porcentaje de compatibilidad, por favor espere...
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-overlay>

            <v-card-title class="d-flex justify-center text-center text-h5"> Evaluación de Identidad </v-card-title>
            <v-card-text>
              <v-row v-if="!overlaySimilarity">
                <v-col cols="12" md="3" class="d-flex justify-center align-center">
                  <v-img
                    :src="firstImg"
                    width="150px"
                    height="150px"
                    class="white--text"
                    aspect-ratio="2"
                    contain
                  >
                  </v-img>
                </v-col>
                <v-col cols="12" md="1" class="d-flex justify-center align-center">
                  <v-btn
                    icon
                    class="unclickable"
                  >
                    <v-icon x-large>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="3" class="d-flex justify-center align-center">
                  <v-img
                      :src="frontDocImg"
                      width="200px"
                      height="150px"
                      class="white--text"
                      aspect-ratio="2"
                      contain
                    >
                    </v-img>
                </v-col>
                <v-col cols="12" md="1" class="d-flex justify-center align-center">
                    <v-btn
                    icon
                    class="unclickable"
                  >
                    <v-icon x-large>mdi-equal</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-center align-center">
                  <v-card
                    elevation="5"
                    class="px-4 py-2 dark--text"
                  >
                    <div class="d-flex align-center justify-center text-h6">
                      COMPATIBILIDAD
                    </div>
                    <div class="d-flex align-center justify-center text-h5" :class="similarityCardColor(similarityNumber)">
                      {{ similarityNumber }} %
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="overlaySimilarity == false && overlayResume == false">
                <v-col cols="12">
                  <v-btn color="primary" block @click="restartProcess">VALIDAR OTRA PERSONA</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card>

            <v-overlay absolute opacity="1" :value="overlayResume">
              <v-card color="primary" dark>
                <v-card-text>
                  Generando Resumen, esto podría tardar un poco, por favor espere...
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-overlay>

            <v-card-title class="d-flex justify-center text-center text-h5">Antecedentes</v-card-title>
            <v-card-text>
              <v-btn v-if="e1 == 5 && overlayResume == false"
                fab
                x-large
                light
                color="success"
                fixed
                right
                bottom
                title="Exportar Resumen"
                class="mx-2"
                :disabled="!antecedentes || !antecedentes.antecedentes || antecedentes.antecedentes.length == 0"
                @click="generateReport()">
                  <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
              <v-row>
                <v-col cols="12" md="12">
                  <v-card height="100%">
                    <!-- //TODO: descomentar caundo se solucione lo de las afiliaciones --)  <v-card-title class="d-flex justify-center primary--text text-h5">Policía Nacional</v-card-title> -->
                    <!-- Respuesta si el ciudadano no aparece en la bases de datos de la policia -->
                    <v-card-text v-if="antecedentes && !antecedentes.ciudadano">
                      <p class="d-flex justify-center">NO HAY REGISTRO</p>
                    </v-card-text>
                    <!-- Muestra de datos del ciudadano sin antecedentes -->
                      <v-list
                      two-line
                      subheader
                      v-if="antecedentes && antecedentes.antecedentes && antecedentes.antecedentes.length == 0"
                      >
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Ciudadano: </v-list-item-title>
                            <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                              {{ antecedentes.ciudadano }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Antecedentes: </v-list-item-title>
                            <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                              NO REGISTRA
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    <!-- Lista de antecedentes del ciudadano SI tiene antecedentes-->
                    <v-list
                      two-line
                      subheader
                      v-if="antecedentes && antecedentes.antecedentes && antecedentes.antecedentes.length > 0"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Ciudadano: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                            {{ antecedentes.ciudadano }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- ANTECEDENTES: Lista de sanciones -->
                      <v-list-item>
                        <v-expansion-panels
                        multiple
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>Sanciones: </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-list
                              two-line
                                v-for="(item, i) in antecedentes.antecedentes[0].sanciones" :key="i"
                              >
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Sanción: </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                                      {{ item.sancion != '' ? item.sancion : '---' }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Término: </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                                      {{ item.termino != '' ? item.termino : '---' }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Clase: </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                                      {{ item.clase != '' ? item.clase : '---' }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-list-item>
                      <!-- ANTECEDENTES: Lista de delitos -->
                      <v-list-item>
                        <v-expansion-panels
                        multiple
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>Delitos: </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-list
                              two-line
                                v-for="(item, i) in antecedentes.antecedentes[0].delitos" :key="i"
                              >
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Delito: </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                                      {{ item.delito != '' ? item.delito : '---' }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-list-item>
                      <!-- ANTECEDENTES: Lista de instancias -->
                      <v-list-item>
                        <v-expansion-panels
                        multiple
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>Instancias: </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-list
                              two-line
                                v-for="(item, i) in antecedentes.antecedentes[0].instancias" :key="i"
                              >
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Nombre: </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                                      {{ item.nombre != '' ? item.nombre : '---' }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Autoridad: </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                                      {{ item.autoridad != '' ? item.autoridad : '---' }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Fecha Providencia: </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                                      {{ item.fechaProvidencia != '' ? item.fechaProvidencia : '---' }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Fecha Efectos Jurídicos: </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                                      {{ item.fechaEfectosJuridicos != '' ? item.fechaEfectosJuridicos : '---' }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <!-- Parafiscales: Lista de datos de la EPS -->
                <!-- <v-col cols="12" md="3">
                  <v-card height="100%">
                    <v-card-title class="d-flex justify-center primary--text text-h5">EPS</v-card-title>
                    <v-card-text v-if="!eps">
                      <p class="d-flex justify-center">NO REGISTRA EPS</p>
                    </v-card-text>
                    <v-list
                      two-line
                      subheader
                      v-if="eps"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Administradora: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ eps.eps }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Estado Afiliación: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ eps.estadoAfiliacion }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Municipio: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ eps.departamentoMunicipio }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Régimen:</v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ eps.regimen }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>T. Afiliado: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ eps.tipoAfiliado }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col> -->
                <!-- Parafiscales: Lista de datos de la Pensión -->
                <!-- <v-col cols="12" md="3">
                  <v-card height="100%">
                    <v-card-title class="d-flex justify-center primary--text text-h5">Pensión</v-card-title>
                    <v-card-text v-if="!pension">
                      <p class="d-flex justify-center">NO REGISTRA PENSIÓN</p>
                    </v-card-text>
                    <v-list
                      two-line
                      subheader
                       v-if="pension"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Administradora: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">
                            {{ pension.administradoraPensional }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Estado: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ pension.estadoPensional }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Régimen</v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ pension.regimenPensional }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col> -->
                <!-- Parafiscales: Lista de datos de la ARL -->
                <!-- <v-col cols="12" md="3">
                  <v-card height="100%">
                    <v-card-title class="d-flex justify-center primary--text text-h5">ARL</v-card-title>
                    <v-card-text v-if="!arl || arl.length == 0">
                      <p class="d-flex justify-center">NO REGISTRA ARL</p>
                    </v-card-text>
                    <v-list
                      two-line
                      subheader
                      v-if="arl && arl.length > 0"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Administradora: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ arl[0].administradora }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Estado Afiliación</v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ arl[0].estado.substr(0, 6) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Municipio: </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap text-right text-uppercase">{{ arl[0].municipioLaboral }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-expansion-panels
                          multiple
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>Actividad Empresa</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              {{ arl[0].actividadEmpresa }}
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-snackbar v-model="snackbar" timeout="8000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import globals from "../../globals";
import axios from "axios";
import xlsx from "xlsx";
import S3 from "aws-s3";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "DashImgs",
  data() {
    return {
      e1: 1,
      overlay: false,
      cedula: "",
      nombre1: "",
      nombre2: "",
      apellido1: "",
      apellido2: "",
      editCedulaField: false,
      editDateField: false,
      editNamesFields: false,
      similarityNumber: null,
      firstImg: null,
      selfieImg: null,
      frontDocImg: null,
      frontDocumentImg: null,
      backDocImg: null,
      backDocumentImg: null,
      overlaySimilarity: false,
      overlayResume: false,
      snackbarText: "",
      snackbarColor: "",
      snackbar: false,
      expeditionDate: "",
      expeditionDay: "",
      expeditionMonth: "",
      expeditionYear: "",
      antecedentes: null,/* {
        ciudadano: "AIDER ALEXIS AGUDELO GARCIA",
        antecedentes: []
      }, */
      eps: null,
      pension: null,
      arl: [],
      /* 
      eps: {
          "fechaCorteEPS": "2021-03-05",
          "eps": "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A",
          "regimen": "Contributivo",
          "fechaAfiliacion": "11/07/2005",
          "estadoAfiliacion": "Activo",
          "tipoAfiliado": "COTIZANTE",
          "departamentoMunicipio": "ENVIGADO"
      },
      pension: {
          "fechaCortePensiones": "2021-03-05",
          "regimenPensional": "PENSIONES: PRIMA MEDIA",
          "administradoraPensional": "ADMINISTRADORA COLOMBIANA DE PENSIONES COLPENSIONES",
          "fechaAfiliacionPensional": "2016-04-15",
          "estadoPensional": "Activo cotizante"
      },
      arl: [
          {
              "administradora": "POSITIVA COMPAÑIA DE SEGUROSPOSITIVA COMPAÑIA DE SEGUROS",
              "fechaAfiliacion": "2020-01-232020-01-23",
              "estado": "ActivaActiva",
              "actividadEmpresa": "EMPRESAS DEDICADAS A ACTIVIDADES DE ARQUITECTURA E INGENIERÍA Y ACTIVIDADES CONEXAS DE ASESORAMIENTO TÉCNICO INCLUYE ACTIVIDADES DE DIRECCIÓN DE OBRAS DE CONSTRUCCIÓN, AGRIMENSURA Y DE EXPLOTACIÓN Y PROSPECCIÓN GEOLÓGICAS ASÍ COMO LA PRESTACIÓN DE ASESORAMIENTO TÉCNICO CONEXO, EL DISEÑO INDUSTRIAL Y DE MÁQUINAS (SIN INTERVENCIÓN DIRECTA EN LAS OBRAS)EMPRESAS DEDICADAS A ACTIVIDADES DE ARQUITECTURA E INGENIERÍA Y ACTIVIDADES CONEXAS DE ASESORAMIENTO TÉCNICO INCLUYE ACTIVIDADES DE DIRECCIÓN DE OBRAS DE CONSTRUCCIÓN, AGRIMENSURA Y DE EXPLOTACIÓN Y PROSPECCIÓN GEOLÓGICAS ASÍ COMO LA PRESTACIÓN DE ASESORAMIENTO TÉCNICO CONEXO, EL DISEÑO INDUSTRIAL Y DE MÁQUINAS (SIN INTERVENCIÓN DIRECTA EN LAS OBRAS)",
              "municipioLaboral": "Antioquia- MEDELLÍNAntioquia- MEDELLÍN"
          }
      ],
      antecedentes: {
        "ciudadano": "SAMUEL MORENO ROJAS",
        "antecedentes": [
            {
                "siri": "201097296",
                "sanciones": [
                    {
                        "sancion": "MULTA EN SMLV",
                        "termino": "361.00 SMLV",
                        "clase": "PRINCIPAL",
                        "suspendida": ""
                    },
                    {
                        "sancion": "PRISION",
                        "termino": "24 AÑOS  10 MESES  ",
                        "clase": "PRINCIPAL",
                        "suspendida": ""
                    },
                    {
                        "sancion": "INHABILIDAD PARA EL EJERCICIO DE DERECHOS Y FUNCIONES PUBLICAS",
                        "termino": "20 AÑOS  ",
                        "clase": "ACCESORIA",
                        "suspendida": ""
                    },
                    {
                        "sancion": "INHABILIDAD PERMANENTE PARA EL DESEMPEÑO DE FUNCIONES PUBLICAS",
                        "termino": "",
                        "clase": "ACCESORIA",
                        "suspendida": ""
                    }
                ],
                "delitos": [
                    {
                        "delito": "COHECHO PROPIO (LEY 599 DE 2000)"
                    },
                    {
                        "delito": "INTERES INDEBIDO EN LA CELEBRACION DE CONTRATOS (LEY 599 DE 2000)"
                    }
                ],
                "instancias": [
                    {
                        "nombre": "PRIMERA",
                        "autoridad": "JUZGADO 14 PENAL DEL CIRCUITO CON FUNCIONES DE CONOCIMIENTO - BOGOTA DC",
                        "fechaProvidencia": "29/03/2016",
                        "fechaEfectosJuridicos": "27/09/2017"
                    },
                    {
                        "nombre": "SEGUNDA",
                        "autoridad": "TRIBUNAL SUPERIOR - SALA PENAL    DE BOGOTA",
                        "fechaProvidencia": "18/01/2017",
                        "fechaEfectosJuridicos": "27/09/2017"
                    },
                    {
                        "nombre": "CASACIÓN",
                        "autoridad": "CORTE SUPREMA DE JUSTICIA - SALA DE CASACION PENAL",
                        "fechaProvidencia": "27/09/2017",
                        "fechaEfectosJuridicos": "27/09/2017"
                    }
                ]
            }
        ],
      }  
      */
     selfieFile: null,
     frontDocFile: null,
     backDocFile: null,
     selfieDocImgKey: "",
     frontDocImgKey: "",
     backDocImgKey: "",
     userUUID: ""
    };
  },
  computed: {
    config() {
      return {
        bucketName: process.env.VUE_APP_S3_BUCKET_NAME,
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY
      }
    },

    S3Client() {
      return new S3(this.config)
    }
  },
  methods: {
    validateSelfie() {
      this.overlay = true;
      const searchTerm = "base64,";
      const searchIndex = this.firstImg.indexOf(searchTerm);
      this.selfieImg = this.firstImg.substr(searchIndex + searchTerm.length);

      this.S3Client
        .uploadFile(this.selfieFile, "selfie_" + this.userUUID)
        .then(data => {
          if (data.key && data.key != '') {
            console.log("Respuesta de imagen subida a S3 en la validacion de la selfie", data)
            this.selfieDocImgKey = data.key

            axios({
              method: 'POST',
              baseURL: globals.APIURL + "identity/dvf",
              headers: {
                  "Access-Control-Allow-Origin": "*"
              },
              data: {
                SelfieImage: data.key,
              },
            }).then((res) => {
                console.log(res.data)
                if (res.data) {
                  if (
                    res.data.faceAnalysisData.FaceDetails &&
                    res.data.faceAnalysisData.FaceDetails.length > 0
                  ) {
                    if (
                      !res.data.ppeData.Summary.PersonsWithRequiredEquipment ||
                      res.data.ppeData.Summary.PersonsWithRequiredEquipment.length ==
                        0
                    ) {
                      if (
                        res.data.faceAnalysisData.FaceDetails[0].Eyeglasses.Value ==
                          false &&
                        res.data.faceAnalysisData.FaceDetails[0].Sunglasses.Value ==
                          false
                      ) {
                        this.overlay = false;
                        this.e1 = 2;
                      } else {
                        this.overlay = false;
                        this.snackbar = true;
                        this.snackbarColor = "error";
                        this.snackbarText =
                          "La selfie tiene lentes, favor ingresar una sin ellos.";
                      }
                    } else {
                      this.overlay = false;
                      this.snackbar = true;
                      this.snackbarColor = "error";
                      this.snackbarText =
                        "La selfie tiene tapabocas, favor ingresar una sin el.";
                    }
                  } else {
                    this.overlay = false;
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText =
                      "La imagen ingresada no tiene un rostro reconocible.";
                  }
                }
              })
              .catch((err) => {
                this.overlay = false;
                console.log(err);
              });

          }
        })
        .catch(err => console.log(err))
    },

    validateFrontDocumentImg() {
      this.overlay = true;
      const searchTerm = "base64,";
      const searchIndex = this.frontDocImg.indexOf(searchTerm);
      this.frontDocumentImg = this.frontDocImg.substr(
        searchIndex + searchTerm.length
      );

      this.S3Client
        .uploadFile(this.frontDocFile, "front-doc_" + this.userUUID)
        .then(data => {
          if (data.key && data.key != '') {
            this.frontDocImgKey = data.key

            axios({
              method: 'POST',
              baseURL: globals.APIURL + "identity/vfd",
              headers: {
                  "Access-Control-Allow-Origin": "*"
              },
              data: {
                FrontDocumentImage: data.key,
              },
            }).then((res) => {
                if (res.data) {
                  if (
                    res.data.faceAnalysisData.FaceDetails &&
                    res.data.faceAnalysisData.FaceDetails.length > 0
                  ) {
                    if (
                      res.data.textDetectData.DocumentNumber
                    ) {
                      this.cedula = res.data.textDetectData.DocumentNumber.replace(
                        /\./gi,
                        ""
                      );
                      this.cedula = this.cedula.replace(/\D/g, "");
                      if (res.data.textDetectData.Names.length > 0 && res.data.textDetectData.Names[0] !== '') this.nombre1 = res.data.textDetectData.Names[0]
                      if (res.data.textDetectData.Names.length > 1 && res.data.textDetectData.Names[1] !== '') this.nombre2 = res.data.textDetectData.Names[1]
                      if (res.data.textDetectData.Lastnames.length > 0 && res.data.textDetectData.Lastnames[0] !== '') this.apellido1 = res.data.textDetectData.Lastnames[0]
                      if (res.data.textDetectData.Lastnames.length > 1 && res.data.textDetectData.Lastnames[1] !== '') this.apellido2 = res.data.textDetectData.Lastnames[1]
                      this.overlay = false;
                      this.e1 = 3;
                    } else {
                      this.overlay = false;
                      this.snackbar = true;
                      this.snackbarColor = "error";
                      this.snackbarText =
                        "La imagen del documento puede estar muy alejada, borrosa, incompleta o no es un documento, por favor intente nuevamente con otra imagen.";
                    }
                  } else {
                    this.overlay = false;
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText =
                      "La imagen del documento no tiene un rostro reconocible, por favor intente nuevamente con otra imagen.";
                  }
                }
              })
              .catch((err) => {
                this.overlay = false;
                console.log(err);
              });
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    validateBackDocumentImg() {
      this.overlay = true;
      const searchTerm = "base64,";
      const searchIndex = this.backDocImg.indexOf(searchTerm);
      this.backDocumentImg = this.backDocImg.substr(
        searchIndex + searchTerm.length
      );

      this.S3Client
        .uploadFile(this.backDocFile, "back-doc_" + this.userUUID)
        .then(data => {
          if (data.key && data.key != '') {
            this.backDocImgKey = data.key

            axios({
              method: 'POST',
              baseURL: globals.APIURL + "identity/vbd",
              headers: {
                  "Access-Control-Allow-Origin": "*"
              },
              data: {
                BackDocumentImage: data.key
              },
            }).then((res) => {
                if (res.data) {
                  if (res.data.DetectedText) {
                    this.overlay = false;
                    let responseDate = res.data.DetectedText.replace(/[^\d.-]/g, ''); 
                    this.expeditionDay = responseDate.substr(0, 2);
                    this.expeditionMonth = responseDate.substr(3, 2);
                    this.expeditionYear = responseDate.substr(6, 4);
                    this.expeditionDate = responseDate;

                    this.e1 = 4;
                  } else {
                    this.overlay = false;
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText =
                      "La imagen del documento puede estar muy alejada, borrosa, en contraste o no es una foto del documento, por favor intente nuevamente con otra imagen.";
                  }
                } else {
                  this.overlay = false;
                }
              })
              .catch((err) => {
                this.overlay = false;
                console.log(err);
              });
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    validateResume() {
      if (this.cedula != "" && this.expeditionDay != "" && this.expeditionMonth != "" && this.expeditionYear != "") {
        this.e1 = 5;
        this.compareFaces();
        //TODO: Descomentar esta linea cuando se solucione la traida de los datos de parafiscales --> /this.getAfiliaciones();/
        this.getAntecedentes();
      } else {
        this.overlayResume = false;
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText = "No puede dejar los campos vacíos, por favor llenarlos.";
      }
    },
    
    compareFaces() {
        this.overlaySimilarity = true;
        axios
          .post(globals.APIURL + "identity/cf", {
            SelfieImage: this.selfieDocImgKey,
            DocumentImage: this.frontDocImgKey,
          }, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
          .then((res) => {
            if (res.data.FaceMatches && res.data.FaceMatches.length > 0) {
              this.overlaySimilarity = false;
              this.similarityNumber = Math.round(res.data.FaceMatches[0].Similarity);
              if (Math.round(res.data.FaceMatches[0].Similarity) > 85) {
                this.indexFace()
              }
            }
          })
          .catch((err) => {
            this.overlaySimilarity = false;
            console.log(err);
          });
    },

    indexFace() {
      axios
        .post(globals.APIURL + "identity/index-face", {
          FaceImage: this.selfieDocImgKey,
          RekImgName: this.nombre1 + '_' + this.apellido1 + '-' + this.cedula + '.png',
          Person: {
            first_name: this.nombre1,
            second_name: this.nombre2,
            first_last_name: this.apellido1,
            second_last_name: this.apellido2,
            birthdate: "",
            rh: "",
            gender: "",
            document_number: this.cedula 
          }
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then((res) => {
        console.log("FACE INDEXING", res)
      })
      .catch((err) => {
        console.log(err);
      });
    },

    // Funcion para traer datos de EPS, Pension y ARL
    getAfiliaciones() {
        this.overlayResume = true;
        this.expeditionDate = this.expeditionDay + "-" + this.expeditionMonth + "-" + this.expeditionYear;
        
        axios
          .post(globals.APIURL + "identity/gafil", {
            Document: this.cedula,
            ExpeditionDate: this.expeditionDate,
          })
          .then((res) => {
            if (res.data) {
              this.overlayResume = false; 
              this.eps = res.data.parafiscales.eps;
              this.pension = res.data.parafiscales.pensiones;
              this.arl = res.data.parafiscales.arl.riesgos;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      
    },

    // Función para traer datos antecedentes como: Delitos, Procesos e inhabilidades.
    getAntecedentes() {
        this.overlayResume = true;
        
        axios
          .post(globals.APIURL + "identity/gant", {
            Document: this.cedula
          })
          .then((res) => {
            if (res.data) {
              this.overlayResume = false;
              this.antecedentes = res.data.antecedentes;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      
    },

    isValidImg(fileName) {
      let fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)
      if (fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png" || fileExtension == "jfif" || fileExtension == "JPG" ||  fileExtension == "JPEG" ||  fileExtension == "PNG" || fileExtension == "JFIF") {
        return true;
      } else {
        return false;
      }
    },

    uploadFirstImg(e) {
      const files = e.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }
      if (!this.isValidImg(filename)) {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText = "El archivo que está tratando de anexar no es una imagen";
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.userUUID = uuidv4()
        console.log("UUID Cuando se genera inicialmente: ", this.userUUID)
        this.selfieFile = files[0]
        this.firstImg = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    },

    uploadSecondImg(e) {
      const files = e.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }
      if (this.isValidImg(filename) == false) {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText = "El archivo que está tratando de anexar no es una imagen";
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.frontDocFile = files[0]
        this.frontDocImg = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    },

    uploadBackDocImg(e) {
      const files = e.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }
      if (this.isValidImg(filename) == false) {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText = "El archivo que está tratando de anexar no es una imagen";
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.backDocFile = files[0]
        this.backDocImg = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    },

    onPickFirstFile() {
      this.$refs.firstFileInput.click();
    },

    onPickSecondFile() {
      this.$refs.secondFileInput.click();
    },

    onPickBackDocFile() {
      this.$refs.backDocFileInput.click();
    },

    similarityCardColor(value) {
      if (value >= 80) {
        return "success--text";
      } else if (value >= 50 && value <= 79) {
        return "warning--text";
      } else {
        return "error--text";
      }
    },

    getMiddlePosition(a, b, c) {
      let median = Math.max(Math.min(a, b), Math.min(Math.max(a, b), c));
      return median;
    },

    restartProcess() {
      this.e1 = 1;
      this.openDialog = false;
      this.overlay = false;
      this.cedula = "";
      this.nombre1 = "";
      this.nombre2 = "";
      this.apellido1 = "";
      this.apellido2 = "";
      this.editCedulaField = false;
      this.editDateField = false;
      this.editNamesFields = false;
      this.similarityNumber = null;
      this.firstImg = null;
      this.selfieImg = null;
      this.frontDocImg = null;
      this.frontDocumentImg = null;
      this.backDocImg = null;
      this.backDocumentImg = null;
      this.overlaySimilarity = false;
      this.overlayResume = false;
      this.snackbarText = "";
      this.snackbarColor = "";
      this.snackbar = false;
      this.expeditionDate = "";
      this.expeditionDay = "";
      this.expeditionMonth = "";
      this.expeditionYear = "";
      this.antecedentes = null;
      this.eps = null;
      this.pension = null;
      this.arl = [];
      this.selfieFile = null;
      this.frontDocFile = null;
      this.backDocFile = null;
      this.selfieDocImgKey = "";
      this.frontDocImgKey = "";
      this.backDocImgKey = "";
      this.userUUID = "";
    },

    generateReport() {
      let ciudadano = this.antecedentes.ciudadano;
      let jsonEPSContent = [];
      let jsonPensionContent = [];
      let jsonAntecedentesContent = [];
      
      let newWB = xlsx.utils.book_new();

      if (this.antecedentes) {
        if (this.antecedentes.antecedentes && this.antecedentes.antecedentes.length > 0) {
          jsonAntecedentesContent.push(this.antecedentes);
          jsonAntecedentesContent[0].CIUDADANO = jsonAntecedentesContent[0].ciudadano;
          let sanciones = this.antecedentes.antecedentes[0].sanciones; 
          let delitos = this.antecedentes.antecedentes[0].delitos; 
          let instancias = this.antecedentes.antecedentes[0].instancias; 
          if (delitos && delitos.length > 0) {
            for (let i = 0; i < delitos.length; i++) {
              const element = delitos[i];
              jsonAntecedentesContent[0]["DELITO " + (i+1)] = element.delito   
            }
          }

          if (sanciones && sanciones.length > 0) {
            for (let i = 0; i < sanciones.length; i++) {
              const element = sanciones[i];
              jsonAntecedentesContent[0]["SANCIÓN " + (i+1)] = element.sancion   
            }
          }

          if (instancias && instancias.length > 0) {
            for (let i = 0; i < instancias.length; i++) {
              const element = instancias[i];
              jsonAntecedentesContent[0]["INSTANCIA " + (i+1)] = element.nombre   
            }
          }
          
          delete jsonAntecedentesContent[0].ciudadano; 
          delete jsonAntecedentesContent[0].antecedentes; 
          
          let newWSAntecedentes = xlsx.utils.json_to_sheet(jsonAntecedentesContent);

          xlsx.utils.book_append_sheet(newWB, newWSAntecedentes, "POLICIA NACIONAL");
        } else {
          this.antecedentes.antecedentes = "NO REGISTRA"
          delete this.antecedentes.antecedentes.inhabilidades; 

          jsonAntecedentesContent.push(this.antecedentes);

          let newWSAntecedentes = xlsx.utils.json_to_sheet(jsonAntecedentesContent, {
          header: [
              "ciudadano",
              "antecedentes",
            ],
          });

          newWSAntecedentes["A1"].v = "CIUDADANO";
          newWSAntecedentes["B1"].v = "ANTECEDENTES";

          xlsx.utils.book_append_sheet(newWB, newWSAntecedentes, "POLICIA NACIONAL");
          this.antecedentes.antecedentes = []
        }
      }

      if (this.eps) {
        jsonEPSContent.push(this.eps);
        let newWSEPS = xlsx.utils.json_to_sheet(jsonEPSContent, {
        header: [
            "eps",
            "estadoAfiliacion",
            "tipoAfiliado",
            "regimen",
            "departamentoMunicipio",
            "fechaAfiliacion",
            "fechaCorteEPS",
          ],
        });

        newWSEPS["A1"].v = "ADMINISTRADORA";
        newWSEPS["B1"].v = "ESTADO";
        newWSEPS["C1"].v = "TIPO AFILIADO";
        newWSEPS["D1"].v = "RÉGIMEN";
        newWSEPS["E1"].v = "MUNICIPIO";
        newWSEPS["F1"].v = "FECHA AFILIACIÓN";
        newWSEPS["G1"].v = "FECHA CORTE";

        xlsx.utils.book_append_sheet(newWB, newWSEPS, "EPS");
      }

      if (this.pension) {
        jsonPensionContent.push(this.pension);

        let newWSPension = xlsx.utils.json_to_sheet(jsonPensionContent, {
        header: [
            "administradoraPensional",
            "estadoPensional",
            "regimenPensional",
            "fechaAfiliacionPensional",
            "fechaCortePensiones",
          ],
        });

        newWSPension["A1"].v = "ADMINISTRADORA";
        newWSPension["B1"].v = "ESTADO";
        newWSPension["C1"].v = "RÉGIMEN";
        newWSPension["D1"].v = "FECHA AFILIACIÓN";
        newWSPension["E1"].v = "FECHA CORTE";

        xlsx.utils.book_append_sheet(newWB, newWSPension, "PENSION");
      }
      if (this.arl && this.arl.length > 0) {
        let newWSARL = xlsx.utils.json_to_sheet(this.arl, {
        header: [
            "administradora",
            "estado",
            "municipioLaboral",
            "fechaAfiliacion",
            "actividadEmpresa"
          ],
        });

        newWSARL["A1"].v = "ADMINISTRADORA";
        newWSARL["B1"].v = "ESTADO";
        newWSARL["C1"].v = "DEPARTAMENTO";
        newWSARL["D1"].v = "FECHA AFILIACIÓN";
        newWSARL["E1"].v = "ACTIVIDAD EMPRESA";

        xlsx.utils.book_append_sheet(newWB, newWSARL, "ARL");
      }

      xlsx.writeFile(
        newWB,
        ciudadano + "-" + this.cedula + ".xlsx",
        {
          type: "file",
          bookType: "xlsx",
        }
      );
    }
  },
  mounted() {
  },
  beforeDestroy() {},
};
</script>

<style>
.no-boxshadow {
  box-shadow: none !important;
}

.v-card__title {
  word-break: normal;
}

.unclickable {
  pointer-events: none;
}

.clickable {
  cursor: pointer;
}

.sample-img {
  /* height: 400px; */ 
  height: 50%; 
  /* width: 600px; */ 
  width: 100%; 
  background-color: #E5E5E5
}
</style>