<template>
  <v-container fluid style="height: 100%" v-if="company_campus">
      <v-row>
          <v-col cols="12" md="12" sm="12" class="d-flex justify-center">
              <p class="text-center text-h3 font-weight-bold grey--text">{{company_campus.Campus[0].name}}</p>
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-card elevation="24" class="full-height pointer" @click="goTo('access')" title="IR AL DASHBOARD DE ACCESOS.">
                <v-row>
                    <v-col cols="12">
                        <p class="text-h4 text-center">Módulo Peatonal</p>
                    </v-col>
                    <v-col cols="12" md="6" class="text-center">
                        <v-list two-line>
                            <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold"
                                >Ingresos Hoy
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-content>
                                <v-list-item-title>
                                <strong class="primary--text pr-0 mr-0 text-h3">{{ aforo }}</strong>
                                <span
                                    class="grey--text pl-0 ml-0 text-caption"
                                    justify="end"
                                    >Usuarios</span
                                >
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" md="6" class="text-center">
                        <v-list two-line>
                            <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold"
                                >Temps. Altas Hoy
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-content>
                                <v-list-item-title>
                                <strong class="red--text pr-0 mr-0 text-h3">2</strong>
                                <span
                                    class="grey--text pl-0 ml-0 text-caption"
                                    justify="end"
                                    >Usuarios</span
                                >
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="d-flex justify-center">
                        <dash-chart
                            class="graphic-container"
                            label="Ingresos"
                            :chartData="{
                                shortUnitMeasure: 'Pers',
                                allData: people,
                                color: colorHex,
                            }"
                        ></dash-chart>
                    </v-col>
                </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-card elevation="24" class="full-height pointer" @click="goTo('vehicle')" title="IR AL DASHBOARD DE VEHICULOS">
                <v-row>
                    <v-col cols="12">
                        <p class="text-h4 text-center">Módulo Vehícular</p>
                    </v-col>
                    <v-col cols="12" md="6" class="text-center">
                        <v-list two-line>
                            <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold"
                                >Empleados
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-content>
                                <v-list-item-title>
                                <strong class="primary--text pr-0 mr-0 text-h3">959</strong>
                                <span
                                    class="grey--text pl-0 ml-0 text-caption"
                                    justify="end"
                                    >Vehículos</span
                                >
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" md="6" class="text-center">
                        <v-list two-line>
                            <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold"
                                >visitantes
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-content>
                                <v-list-item-title>
                                <strong class="red--text pr-0 mr-0 text-h3">0</strong>
                                <span
                                    class="grey--text pl-0 ml-0 text-caption"
                                    justify="end"
                                    >Vehículos</span
                                >
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="d-flex justify-center">
                        <dash-chart
                            class="graphic-container"
                            label="Ingresos"
                            :chartData="{
                                shortUnitMeasure: 'Vehi',
                                allData: people,
                                color: 'red',
                            }"
                        ></dash-chart>
                    </v-col>
                </v-row>
            </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import DashChart from "../components/charts/dash-chart"

export default {
  name: "GeneralDashboard",
  components: {
    DashChart,
  },
  data() {
    return {
        interval: "",
        company_campus: null,
        people: [],
        enterpriseName: auth.getUserEnterpriseName(),
        vehicles: [],
        colorHex: "#4FC3F7",
        aforo: Number
    };
  },
  methods: {
    goTo(type) {
        if (type === "access") {
            this.$router.push({name: "dashboard"})
        } else if (type === "vehicle") {
            this.$router.push({name: "dashCars"})
        }
    },
    
    getSede() {
    axios
        .post(globals.APIURL + "org/fo", {
            company_id: auth.getUserEnterpriseID(),
            campus_id: auth.getSelectedSede()
        })
        .then((res) => {
            if (res.data) {
                this.company_campus = res.data
            }
        })
        .catch((err) => {
            console.log(err);
        }); 
    },

    getPeopleData() {
        //let date = globals.DDMMYYYY(new Date(), "/");
        let date = "10/06/2021";
        axios
          .post(globals.APIURL + "entry/fbd", {
            campus_id: auth.getSelectedSede(),
            start_date: date.split('/').reverse().join('-') + "T00:00:00.000Z",
            finish_date: date.split('/').reverse().join('-') + "T23:59:59.000Z",
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.people = res.data;
              this.aforo = res.data.length;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },

    getVehicleData() {

    },

    callEntriesEvery() {
      this.interval = window.setInterval(
        function () {
          this.getPeopleData();
        }.bind(this),
        20000
      );
    },

  },
  mounted() {
    this.getSede();
    this.getPeopleData();
    //this.callEntriesEvery();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
.card-org {
    width: 100%;
}

.graphic-container {
  position: relative;
  margin: auto;
  height: 35vh;
  width: 35vw;
}
</style>