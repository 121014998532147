import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './routes';
import socketio from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';

const SocketInstance = socketio.connect('https://devback2.vanguardtech.solutions', {
  cors: {
    origin: '*'
  }/* ,
  transports: ['polling'] // TODO: Posible solucion, falta evaluar la solucion de configuracion de ngnex */
}); 

Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketInstance
}));

Vue.config.productionTip = false;

export const bus = new Vue();

Vue.prototype.$bus = bus;

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
