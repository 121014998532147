var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"black--text font-weight-bold text-h5"},[_vm._v("Histórico de Accesos")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.rangeDates,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.rangeDates=$event},"update:return-value":function($event){_vm.rangeDates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Intervalo de fechas","prepend-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":"","locale":"es-MX"},model:{value:(_vm.rangeDates),callback:function ($$v) {_vm.rangeDates=$$v},expression:"rangeDates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.searchByDates}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"mdi-magnify","label":"Buscar por palabra clave...","single-line":"","hide-details":"","outlined":"","small":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"color":"success","x-large":"","block":"","title":"Exportar Historico","disabled":_vm.entryList.length == 0},on:{"click":_vm.generateReport}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"child-flex":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entryList,"search":_vm.search,"items-per-page":5,"header-props":{
                  mobile: true,
                },"footer-props":{
                  'items-per-page-options': [20],
                },"fixed-header":"","loading":_vm.loading,"loading-text":"Cargando Información...","no-results-text":("No se encontrarón ingresos por su dato de busqueda (" + _vm.search + ")"),"no-data-text":"No hay ingresos que mostrar"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-body-2"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"45"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.getEntryPic(item),"lazy-src":"https://cdn.wallpapersafari.com/38/20/PB9auk.png"}})],1)]}}],null,true)},[_c('v-img',{attrs:{"src":_vm.getEntryPic(item),"width":"100","lazy-src":"https://cdn.wallpapersafari.com/38/20/PB9auk.png"}})],1)],1),_c('td',{staticClass:"text-body-2"},[_vm._v(_vm._s(item.person.document_number)+" ")]),_c('td',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(item.person.first_name + " " + item.person.first_last_name + " " + item.person.second_last_name)+" ")]),_c('td',{staticClass:"text-body-2"},[_c('v-chip',{staticClass:"small-chip ma-0",attrs:{"color":item.mode === 'ENTRY' ? 'primary' : 'red',"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.mode === "ENTRY" ? "Acceso" : "Salida")+" ")])],1),_c('td',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(item.date.substring(0, 10))+" - "+_vm._s(item.time.substring(11, 16))+" ")])])]}}])})],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":"6000","color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }