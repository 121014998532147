import Vue from 'vue';
import Router from 'vue-router';
import auth from "./services/auth";

Vue.use(Router);

//Import components
import login from './components/login'
import dashboard from './components/dashboard';
import forms from './components/forms';
import registrations from './components/registrations';
import nominalView from './components/nominal/nominal-view';
import logs from './components/logs';
import dashIOT from './components/demo-iot/dashIOT';
import dashImgs from './components/demo-imgs/dashImgs';
import devices from './components/devices';
import dashCars from './components/dashboard-cars';
import mainModule from './components/main-module';
import generalDash from './components/general-dashboard';

import entryHistory from './components/entry-history';
import configuration from './components/configuration';

Vue.component('login', login);
Vue.component('dashboard', dashboard);
Vue.component('forms', forms);
Vue.component('registrations', registrations);
Vue.component('logs', logs);
Vue.component('dashIOT', dashIOT);
Vue.component('dashImgs', dashImgs);
Vue.component('devices', devices);
Vue.component('dashCars', dashCars);
Vue.component('mainModule', mainModule);
Vue.component('generalDash', generalDash);
Vue.component('entryHistory', generalDash);
Vue.component('configuration', configuration);

const appRoutes = [
  { 
    path: '', 
    redirect: { 
      name: "login" 
    } 
  },
  { 
    path: '/login', 
    name: "login", 
    component: login,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == true) {
        if (auth.getRole() == "IOT") {
          next("/dashIOT");
        } else if (auth.getRole() == "IMG") {
          next("/dashImgs");
        } else {
          next("/dashboard");
        }
      } else {
        next();
      }
    } 
  },
  { 
    path: '/main', 
    name: "mainModule", 
    component: mainModule,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/generalDash', 
    name: "generalDash", 
    component: generalDash,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/dashboard', 
    name: "dashboard", 
    component: dashboard,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/dashCars', 
    name: "dashCars", 
    component: dashCars,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/forms', 
    name: "forms",
    component: forms,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/registrations', 
    name: "registrations", 
    component: registrations,
    props: true,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/nominal', 
    name: "nominal", 
    component: nominalView,
    props: true,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/devices', 
    name: "devices", 
    component: devices,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/entryHistory', 
    name: "entryHistory", 
    component: entryHistory,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/configuration', 
    name: "configuration", 
    component: configuration,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/logs', 
    name: "logs", 
    component: logs,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/dashIOT', 
    name: "dashIOT", 
    component: dashIOT,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
  { 
    path: '/dashImgs', 
    name: "dashImgs", 
    component: dashImgs,
    beforeEnter: (to, from, next) => {
      if (auth.loggedIn() == false) {
        next("/login");
      } else {
        next();
      }
    } 
  },
]

const routes = [...appRoutes];

export default new Router({
  routes
});