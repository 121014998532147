<template>
  <v-container fluid>
    <div class="text-center" v-if="dialogVehicle == true">
      <v-dialog v-model="dialogVehicle" width="100%">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Administración Vehícular</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialogVehicle = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <manage-vehicles
              :registryData="itemContentVehicle"
            ></manage-vehicles>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogCSV == true">
      <v-dialog v-model="dialogCSV" width="1000">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Carga masiva de información</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small @click="generateExcelGuide">
              Descargar Guía
            </v-btn>
            <v-btn icon dark @click="closeBulkDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                  <v-file-input
                    accept=".xlsx"
                    label="Cargar archivo con información."
                    :disabled="loadingBulkUpdate"
                    @change="uploadFile"
                    dense
                    outlined
                    hide-details
                  ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 py-0 d-flex align-center">
                <label class="text-left font-weight-bold font-weight-black">
                  <v-icon>mdi-alert-circle</v-icon>
                  Tenga en cuenta que los valores seleccionados en los siguientes selectores se aplicaran a todas las personas de la lista proporcionada.
                  </label>
              </v-col>
              <v-col cols="12" md="6" v-if="organization.Campus && organization.Campus.length > 0">
                <v-select
                  v-model="bulkSuborganization"
                  :items="organization.Campus"
                  item-text="name"
                  item-value="uuid"
                  dense
                  label="Sede"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="groups && groups.length > 0">
                <v-select
                  v-model="bulkWorkingGroup"
                  :items="groups"
                  item-text="name"
                  item-value="uuid"
                  dense
                  label="Grupos nominales"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" md="4">
                <v-select
                  v-model="bulkTypePerson"
                  :items="personTypes"
                  dense
                  label="Tipo empleado"
                  outlined
                  hide-details
                ></v-select>
              </v-col> -->
              <v-col cols="12">
                <v-btn 
                  outlined 
                  block 
                  small
                  color="primary" 
                  :disabled="loadingBulkUpdate || bulkData.length == 0"
                  :loading="loadingBulkUpdate" 
                  @click="bulkUpdate(bulkSuborganization, bulkWorkingGroup, bulkTypePerson)"
                >
                  Enviar Información
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-3" v-if="bulkErrors.length > 0">
                <label class="text-h6">Advertencias</label>
                <div max-height="100px" class="bulkErrorBox">
                  <div v-for="(item, i) in bulkErrors" :key="i">
                    <label class="red--text">{{ item }}</label>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  dense
                  :headers="csvHeaders"
                  :items="bulkData"
                  class="elevation-1"
                  :footer-props="{
                    'items-per-page-options': [15],
                  }"
                >
                <template v-slot:item="{ item }">
                  <tr class="mx-0 px-0">
                    <td class="text-body-2 text-center">
                      <span v-if="item.document_number && item.document_number !== ''" :class="item.document_number && item.document_number !== '' ? 'primary--text' : ''">{{
                        item.document_number && item.document_number !== '' ? item.document_number : '---'
                      }}</span>
                      <span v-else :class="item.CEDULA && item.CEDULA !== '' ? 'primary--text' : ''">{{
                        item.CEDULA && item.CEDULA !== '' ? item.CEDULA : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.email && item.email !== ''" :class="item.email && item.email !== '' ? 'primary--text' : ''">{{
                        item.email && item.email !== '' ? item.email : '---'
                      }}</span>
                      <span v-else :class="item.EMAIL && item.EMAIL !== '' ? 'primary--text' : ''">{{
                        item.EMAIL && item.EMAIL !== '' ? item.EMAIL : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.phone && item.phone !== ''" :class="item.phone && item.phone !== '' ? 'primary--text' : ''">{{
                        item.phone && item.phone !== '' ? item.phone : '---'
                      }}</span>
                      <span v-else :class="item.CELULAR && item.CELULAR !== '' ? 'primary--text' : ''">{{
                        item.CELULAR && item.CELULAR !== '' ? item.CELULAR : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.address && item.address !== ''" :class="item.address && item.address !== '' ? 'primary--text' : ''">{{ item.address && item.address !== '' ? item.address : '---' }}</span>
                      <span v-else :class="item.DIRECCIÓN && item.DIRECCIÓN !== '' ? 'primary--text' : ''">{{ item.DIRECCIÓN && item.DIRECCIÓN !== '' ? item.DIRECCIÓN : '---' }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.position && item.position !== ''" :class="item.position && item.position !== '' ? 'primary--text' : ''">{{
                        item.position && item.position !== '' ? item.position : '---'
                      }}</span>
                      <span v-else :class="item.CARGO && item.CARGO !== '' ? 'primary--text' : ''">{{
                        item.CARGO && item.CARGO !== '' ? item.CARGO : '---'
                      }}</span>
                    </td>
                    <td class="text-body-2 text-center">
                      <span v-if="item.costCenter && item.costCenter !== ''" :class="item.costCenter && item.costCenter !== '' ? 'primary--text' : ''">{{
                        item.costCenter && item.costCenter !== '' ? item.costCenter : '---'
                      }}</span>
                      <span v-else :class="item.CENTRO_COSTOS && item.CENTRO_COSTOS !== '' ? 'primary--text' : ''">{{
                        item.CENTRO_COSTOS && item.CENTRO_COSTOS !== '' ? item.CENTRO_COSTOS : '---'
                      }}</span>
                    </td>
                    <!-- <td class="text-body-2 text-center">//TODO: Arreglar forma de subida de fecha desde excel
                      <span :class="item.disengagement_date && item.disengagement_date !== '' ? 'primary--text' : ''">{{
                        item.disengagement_date && item.disengagement_date !== '' ? item.disengagement_date : '---'
                      }}</span>
                    </td> -->
                  </tr>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogQR == true">
      <v-dialog v-model="dialogQR" width="1000">
        <user-registry></user-registry>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogDelete == true">
      <v-dialog v-model="dialogDelete" persistent width="500">
        <v-card>
          <v-card-title class="d-flex justify-center">
            Eliminar Registro
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12">
                <p class="my-0 py-0">
                  <small
                    >Está seguro que desea eliminar el registro con N° de
                    documento: </small
                  ><strong>{{ itemContent.document_number }}</strong>
                </p>
                <p class="my-0 py-0">
                  <small
                    >Por favor ingrese el número para validar la
                    eliminación.</small
                  >
                </p>
              </v-col>
              <v-col cols="12" class="my0 py-0">
                <v-text-field
                  v-model="cc_validation"
                  type="number"
                  label="Escriba el documento"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my0 py-0">
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  color="error"
                  block
                  :loading="loadingReg"
                  :disabled="
                    cc_validation && cc_validation.length > 3 ? false : true
                  "
                  @click="deleteRegistry(itemContent, cc_validation)"
                >
                  ELIMINAR REGISTRO
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  block
                  :disabled="loadingReg"
                  @click="
                    dialogDelete = false;
                    cc_validation = null;
                  "
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row class="text-center">
      <v-col cols="12">
        <p class="text-h5">Gestión de Registros</p>
      </v-col>
    </v-row>
    <v-row v-if="roleChecker.iHaveGrants(userRole, 'REG-CREATE')" class="text-center">
      <v-col cols="12" md="6">
        <v-btn color="primary" block @click="dialogQR = true">
          GENERAR REGISTRO <v-icon class="pl-2" dark> mdi-account-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn color="success" block @click="dialogCSV = true" :disabled="!itemsList || itemsList.length == 0">
          CARGA MASIVA DE INFORMACIÓN <v-icon class="pl-2"> mdi-pencil-box-multiple-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card elevation="0">
          <v-row class="text-center">
            <v-col
              cols="12"
              :md="
                roleChecker.iHaveGrants(userRole, 'REGISTRY-BACKGROUND') ===
                true
                  ? 8
                  : 12
              "
              :sm="
                roleChecker.iHaveGrants(userRole, 'REGISTRY-BACKGROUND') ===
                true
                  ? 8
                  : 12
              "
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filtrar registros"
                single-line
                hide-details
                class="mb-5"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="roleChecker.iHaveGrants(userRole, 'REGISTRY-BACKGROUND')"
              cols="12"
              md="4"
              sm="4"
            >
              <v-btn
                color="success"
                block
                large
                :loading="loadingReport"
                :disabled="
                  itemsList.length > 0 || loadingReport === false ? false : true
                "
                @click="generateReport(itemsList)"
              >
                EXPORTAR REGISTROS <v-icon class="pl-2" dark> mdi-microsoft-excel </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="itemsList"
                  :search="search"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  class="elevation-1"
                  :loading="loading"
                  loading-text="Cargando Información..."
                  :no-results-text="`No se encontrarón registros por su dato de busqueda (${search})`"
                  no-data-text="No hay registros que mostrar"
                  dense
                  fixed-header
                >
                  <template v-slot:item="{ item }">
                    <tr :class="markedRow(item)" class="mx-0 px-0">
                      <td>{{ item.document_number }}</td>
                      <td>{{ item.first_name }}</td>
                      <td>{{ item.first_last_name }}</td>
                      <td>
                        {{ item.company_person.created_at.substring(0, 10) }}
                      </td>
                      <td>
                        {{ item.company_person.created_at.substring(11, 16) }}
                      </td>
                      <td>
                        <v-btn
                          v-if="modules == 2 || modules == 3"
                          icon
                          @click="adminVehicles(item)"
                        >
                          <v-icon
                            class="text-center"
                            :color="item.vehicles.length > 0 ? 'success' : ''"
                          >
                            mdi-car-cog
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="
                            getOneRegistry(item, 'search');
                            editMode = false;
                          "
                        >
                          <v-icon class="text-center"> mdi-eye </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-center align-center">
        <v-card elevation="0" v-if="loadingProfile === false && !itemContent">
          <v-row>
            <v-col cols="12" class="text-center">
              <p class="black--text font-weight-bold text-h5">
                Seleccione un usuario para consultar su información
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" v-if="loadingProfile === true && !itemContent">
          <v-row>
            <v-col cols="12" class="text-center">
              <p class="black--text font-weight-bold text-h6">
                Cargando Perfil
                <v-progress-linear
                  color="primary"
                  buffer-value="0"
                  stream
                ></v-progress-linear>
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" v-if="itemContent">
          <v-row class="mt-15">
            <v-col
              cols="12"
              class="d-flex justify-center align-center ma-0 pa-0"
            >
              <v-img
                :src="PSRegistryImg"
                class="white--text rounded-edges"
                max-height="300px"
                max-width="400px"
                contain
                @error="imgErrorHandler"
              ></v-img>
            </v-col>

            <v-row class="d-flex justify-center align-center mt-10 ml-10 mr-10">
              <v-col cols="12" md="4" sm="6" class="grey--text text-center">
                 <small v-if="!editMode">
                  Sede:
                  <strong class="blue--text">{{
                    itemContent.company_person.Campus_company_person.length > 0 && itemContent.company_person.Campus_company_person[0].campus.name
                      ? itemContent.company_person.Campus_company_person[0].campus.name
                      : "NO REGISTRA"
                  }}</strong>
                </small>
                <v-select
                  v-model="itemContent.company_person.Campus_company_person[0].campus"
                  v-if="editMode"
                  :items="organization.Campus"
                  item-text="name"
                  return-object
                  dense
                  label="Sede"
                ></v-select> <!-- -->
              </v-col>
              <!-- // TODO: TERMINAR LOGICA NUEVA DE ACTUALIZACION DE CAMPUS -->
              <!-- <v-col cols="12" md="3" sm="6" class="grey--text text-center">
                <small>
                  Sub Org:
                  <strong class="blue--text">NO REGISTRA</strong>
                </small>
                <small
                  v-if="
                    !editMode
                  "
                >
                  Sub Org:
                  <strong class="blue--text">{{
                    itemContent.company_person.suborgs_person.length > 0 && itemContent.company_person.suborgs_person[0].sub_org.name
                      ? itemContent.company_person.suborgs_person[0].sub_org.name
                      : "NO REGISTRA"
                  }}</strong>
                </small>
                <v-select
                  v-if="
                    editMode
                  "
                  v-model="itemContent.company_person.suborgs_person[0].sub_org"
                  :items="
                    itemContent.company_person.Campus_company_person[0].campus.subOrganization
                  "
                  item-text="name"
                  return-object
                  dense
                  label="Sub Organizacion"
                ></v-select>
              </v-col> -->
              <!-- ---------------------------- -->
              <v-col cols="12" md="4" sm="6" class="grey--text text-center">
                <small v-if="!editMode">
                  T. Usuario:
                  <strong class="blue--text">{{ itemContent.company_person.type_person }}</strong>
                </small>
                <v-select
                  v-if="editMode"
                  v-model="itemContent.company_person.type_person"
                  :items="personTypes"
                  dense
                  label="T. Usuario"
                ></v-select>
              </v-col>
              <!-- ---------------------------- -->
              <v-col cols="12" md="4" sm="6" class="grey--text text-center">
                <small v-if="!editMode">
                  Nvl. Acceso:
                  <v-chip
                    class="ma-2"
                    color="primary"
                    text-color="white"
                    v-if="itemContent.IdAcceso && itemContent.IdAcceso != ''"
                  >
                    <v-avatar left class="primary darken-4">
                      {{ itemContent.IdAcceso }}
                    </v-avatar>
                    Nivel
                  </v-chip>
                  <strong
                    class="blue--text"
                    v-if="!itemContent.IdAcceso || itemContent.IdAcceso == ''"
                    >NO REGISTRA</strong
                  >
                </small>
                <v-select
                  v-if="editMode"
                  v-model="itemContent.IdAcceso"
                  :items="accessLevels"
                  disabled
                  dense
                  label="Nvl. Acceso"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-center ml-10 mr-10">
              <v-col cols="12" md="3" class="grey--text text-center"
                ><small>
                  1er Nombre:
                  <strong class="blue--text">{{
                    itemContent.first_name
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="3" class="grey--text text-center"
                ><small>
                  2do Nombre:
                  <strong class="blue--text">{{
                    !itemContent.second_name ? "- - -" : itemContent.second_name
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="3" class="grey--text text-center"
                ><small>
                  1r Apellido:
                  <strong class="blue--text">{{
                    !itemContent.first_last_name ? "- - -" : itemContent.first_last_name
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="3" class="grey--text text-center"
                ><small>
                  2do Apellido:
                  <strong class="blue--text">{{
                    !itemContent.second_last_name
                      ? "- - -"
                      : itemContent.second_last_name
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small>
                  CC:
                  <strong class="blue--text">{{
                    itemContent.document_number
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small>
                  Edad:
                  <strong class="blue--text" v-if="itemContent.birthdate">{{
                    gbls.getAge(itemContent.birthdate.substring(0, 10).split('-').reverse().join('-')) > 5 ? gbls.getAge(itemContent.birthdate.substring(0, 10).split('-').reverse().join('-')) : '---'
                  }}</strong>
                  <strong class="blue--text" v-else>- - -</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small>
                  Fecha Nacimiento:
                  <strong class="blue--text" v-if="itemContent.birthdate">{{
                    gbls.getAge(itemContent.birthdate.substring(0, 10).split('-').reverse().join('-')) > 5 ? itemContent.birthdate.substring(0, 10) : '---'
                  }}</strong>
                  <strong class="blue--text" v-else>N/R</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small>
                  RH:
                  <strong class="blue--text">{{ !itemContent.rh ? "N/R" : itemContent.rh }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center"
                ><small>
                  Genero:
                  <strong class="blue--text" v-if="itemContent.gender">{{
                    itemContent.gender == "M" ? "MASCULINO" : "FEMENINO"
                  }}</strong>
                  <strong class="blue--text" v-else>N/R</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <small>
                  Fecha Registro:
                  <strong class="blue--text">{{
                    itemContent.company_person.created_at.substring(0, 10)
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="Email"
                  v-model="itemContent.emails[itemContent.emails.length - 1].email"
                ></v-text-field>
                <small v-if="!editMode">
                  Email:
                  <strong class="blue--text">{{
                    !itemContent.emails || itemContent.emails.length == 0 || (itemContent.emails.length == 1 && itemContent.emails[0].email == '')
                      ? "NO REGISTRA"
                      : itemContent.emails[itemContent.emails.length - 1].email
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="Celular"
                  v-model="itemContent.phones[itemContent.phones.length - 1].phone"
                ></v-text-field>
                <small v-if="!editMode">
                  Celular:
                  <strong class="blue--text">{{
                    !itemContent.phones || itemContent.phones.length == 0 || (itemContent.phones.length == 1 && itemContent.phones[0].phone == '')
                      ? "NO REGISTRA"
                      : itemContent.phones[itemContent.phones.length - 1].phone
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="Dirección"
                  v-model="itemContent.addresses[itemContent.addresses.length - 1].Address"
                ></v-text-field>
                <small v-if="!editMode" :title="itemContent.addresses[itemContent.addresses.length - 1].created_at">
                  Dirección:
                  <strong class="blue--text">{{
                    !itemContent.addresses || itemContent.addresses.length == 0 || (itemContent.addresses.length == 1 && itemContent.addresses[0].Address == '')
                      ? "NO REGISTRA"
                      : itemContent.addresses[itemContent.addresses.length - 1].Address
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="3" class="grey--text text-center" v-if="modules == 2 || modules == 3">
                <v-chip
                  class="ma-2"
                  :color="itemContent.vehicles.length == 0 ? 'primary' : 'success'"
                  text-color="white"
                  @click="adminVehicles(itemContent)"
                >
                  Vehículos
                  <v-avatar right :class="itemContent.vehicles.length == 0 ? 'primary darken-4' : 'success darken-4'">
                    {{ itemContent.vehicles.length }}
                  </v-avatar>
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <!-- --------------------------- CAMPOS EXTERNOS -------------------------------- -->
              <v-col cols="12" md="4" class="grey--text text-center">
                <small>
                  Estado Empleado:
                  <v-chip
                    v-if="
                      statusEmployee(
                        itemContent.company_person.created_at.substring(0, 10),
                        employeeData.retiroDate
                      )
                    "
                    small
                    color="green"
                    text-color="white"
                    >ACTIVO</v-chip
                  >
                  <v-chip
                    v-if="
                      !statusEmployee(
                        itemContent.company_person.created_at.substring(0, 10),
                        employeeData.retiroDate
                      )
                    "
                    small
                    color="red"
                    text-color="white"
                    >INACTIVO</v-chip
                  >
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <small v-if="!editMode">
                  Fecha Ingreso:
                  <strong class="blue--text">{{
                    employeeData.ingresoDate
                      ? employeeData.ingresoDate
                      : "NO ASIGNADA"
                  }}</strong>
                </small>
                <v-menu
                  v-model="datepickerIngreso"
                  v-if="editMode"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="employeeData.ingresoDate"
                      label="Fecha Ingreso"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="employeeData.ingresoDate"
                    @input="datepickerIngreso = false"
                    :min="
                      itemContent.company_person.created_at.substring(0, 10)
                    "
                    locale="es-MX"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="grey--text text-center">
                <small v-if="!editMode">
                  Fecha Retiro:
                  <strong class="blue--text">{{
                    itemContent.company_person.disengagement_date
                      ? itemContent.company_person.disengagement_date.substring(0, 10)
                      : "NO ASIGNADA"
                  }}</strong>
                </small>
                <v-menu
                  v-model="datepickerRetiro"
                  v-if="editMode"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="itemContent.company_person.disengagement_date"
                      label="Fecha Retiro"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="itemContent.company_person.disengagement_date"
                    @input="datepickerRetiro = false"
                    :min="itemContent.company_person.created_at.substring(0, 10)"
                    locale="es-MX"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="Cargo"
                  v-model="itemContent.company_person.Positions[itemContent.company_person.Positions.length - 1].position"
                ></v-text-field>
                <small v-if="!editMode">
                  Cargo:
                  <strong class="blue--text">{{
                    !itemContent.company_person.Positions || itemContent.company_person.Positions.length == 0 || (itemContent.company_person.Positions.length == 1 && itemContent.company_person.Positions[0].position == '')
                      ? "NO ASIGNADO"
                      : itemContent.company_person.Positions[itemContent.company_person.Positions.length - 1].position
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="C. Operaciones"
                  v-model="employeeData.operationCenter"
                ></v-text-field>
                <small v-if="!editMode">
                  C. Operaciones:
                  <strong class="blue--text">{{
                    !employeeData.operationCenter ||
                    employeeData.operationCenter == ""
                      ? "NO ASIGNADO"
                      : employeeData.operationCenter
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-text-field
                  v-if="editMode"
                  label="C. Operaciones Desc."
                  v-model="employeeData.operationCenterDesc"
                ></v-text-field>
                <small v-if="!editMode">
                  C. Operaciones Desc:
                  <strong class="blue--text">{{
                    !employeeData.operationCenterDesc ||
                    employeeData.operationCenterDesc == ""
                      ? "NO ASIGNADO"
                      : employeeData.operationCenterDesc
                  }}</strong>
                </small></v-col
              >
              <v-col cols="12" md="6" class="grey--text text-center">
                <v-autocomplete
                  v-if="editMode"
                  v-model="itemContent.company_person.working_group"
                  :items="groups"
                  return-object
                  label="Grupo Nominal"
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span>{{
                      item.name
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{
                      item.name
                    }}</span>
                  </template>
                </v-autocomplete>
                <small v-if="!editMode">
                  Grupo Nominal:
                  <strong class="blue--text" v-if="!itemContent.company_person.working_group_id">NO ASIGNADO</strong>
                  <strong class="blue--text" v-if="itemContent.company_person.working_group_id">{{itemContent.company_person.working_group.name}}</strong>
                </small></v-col
              >
              <v-col cols="12" md="4" class="grey--text text-center">
                <v-autocomplete
                  v-if="editMode"
                  v-model="itemContent.company_person.cost_center"
                  :items="costCenters"
                  return-object
                  label="Centro de Costos"
                  :filter="customFilterCostCenter"
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span>{{
                      item.code + " - " + item.name
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{
                      item.code + " - " + item.name
                    }}</span>
                  </template>
                </v-autocomplete>
                <small v-if="!editMode">
                  C. Costos:
                  <strong class="blue--text" v-if="!itemContent.company_person.cost_center_id">NO ASIGNADO</strong>
                  <strong class="blue--text" v-if="itemContent.company_person.cost_center_id">{{itemContent.company_person.cost_center.code +" - "+ itemContent.company_person.cost_center.name}}</strong>
                </small></v-col
              >
              <v-col
                v-if="roleChecker.iHaveGrants(userRole, 'REGISTRY-BACKGROUND')"
                cols="12"
                md="4"
                class="grey--text text-center"
              >
                <small>
                  Antecedentes:
                  <strong class="blue--text">{{
                    antecedentesUsuario.antecedentes === 0
                      ? "NO REGISTRA"
                      : antecedentesUsuario.antecedentes
                  }}</strong>
                </small>
              </v-col>
              <v-col
                v-if="roleChecker.iHaveGrants(userRole, 'REGISTRY-BACKGROUND')"
                cols="12"
                md="4"
                class="grey--text text-center"
              >
                <small>
                  Inhabilidades:
                  <strong class="blue--text">{{
                    antecedentesUsuario.inhabilidades === 0
                      ? "NO REGISTRA"
                      : antecedentesUsuario.inhabilidades
                  }}</strong>
                </small>
              </v-col>
            </v-row>
          </v-row>
          <v-row
            v-if="roleChecker.iHaveGrants(userRole, 'REG-UPDATE')"
            class="px-10"
          >
            <v-col cols="12" md="6" v-if="!editMode">
              <v-btn color="primary" block @click="activateEditMode(itemContent)">
                <v-icon dark class="mr-2"> mdi-account-edit-outline </v-icon>
                EDITAR REGISTRO
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              <v-btn color="error" block @click="dialogDelete = true">
                <v-icon dark class="mr-2">
                  mdi-account-multiple-remove-outline
                </v-icon>
                ELIMINAR REGISTRO
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-btn color="success" block :loading="loadingReg" @click="updateRegistry(itemContent)">
                <v-icon dark> mdi-content-save-edit-outline </v-icon> GUARDAR
                CAMBIOS
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-btn
                color="error"
                block
                :disabled="loadingReg"
                @click="
                  cancelEditMode(itemContent)
                "
              >
                <v-icon dark> mdi-cancel </v-icon> CANCELAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import roles from "../services/role";
import backlog from "../services/logs";
import xlsx from "xlsx";
import UserRegistry from "./user-registry.vue";
import ManageVehicles from "./manage-vehicles.vue";

export default {
  name: "Registrations",
  components: {
    UserRegistry,
    ManageVehicles,
  },
  props: ['cedula_registro'],
  data: () => ({
    loadingReport: false,
    loadingBulkUpdate: false,
    subOrg: "",
    roleChecker: roles,
    userRole: auth.getRoleName(),
    modules: auth.getModules(),
    accessLevels: ["0", "1", "2", "3"],
    employeeStatus: [
      { status: true, name: "ACTIVO" },
      { status: false, name: "INACTIVO" },
    ],
    editMode: false,
    userOrgName: auth.getUserEnterpriseName(),
    datepickerIngreso: false,
    datepickerRetiro: false,
    todayDate: new Date().toISOString().substr(0, 10),
    organization: {},
    org: null,
    itemsList: [],
    loading: false,
    loadingReg: false,
    itemContent: null,
    itemContentVehicle: null,
    gbls: globals,
    search: "",
    dialogQR: false,
    dialogCSV: false,
    dialogVehicle: false,
    dialogDelete: false,
    headers: [
      {
        text: "CC",
        align: "center",
        value: "document_number",
        sortable: false,
      },
      { text: "Nombre", align: "center", value: "first_name", sortable: false },
      {
        text: "Apellido",
        align: "center",
        value: "first_last_name",
        sortable: false,
      },
      {
        text: "Fecha",
        align: "center",
        value: "company_person.created_at",
        sortable: false,
      },
      { text: "Hora", align: "center", value: "HoraIngreso", sortable: false },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    csvHeaders: [
      { text: "CC", align: "center", value: "document_number", sortable: false },
      { text: "Email", align: "center", value: "email", sortable: false },
      { text: "Celular", align: "center", value: "phone", sortable: false },
      { text: "Dirección", align: "center", value: "address", sortable: false, },
      //{ text: "Suborganización", align: "center", value: "suborganization", sortable: false },
      { text: "Cargo", align: "center", value: "position", sortable: false },
      { text: "Centro Costos", align: "center", value: "costCenter", sortable: false },
      // TODO: Arreglar forma de subida de fecha desde excel { text: "Fecha Retiro", align: "center", value: "disengagement_date", sortable: false },
    ],
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    loadingProfile: false,
    antecedentesUsuario: {
      antecedentes: 0,
      inhabilidades: 0,
    },
    counter: 0,
    cc_validation: "",
    employeeData: {
      position: "",
      operationCenter: "",
      operationCenterDesc: "",
      costCenter: "",
      costCenterDesc: "",
      ingresoDate: null,
      retiroDate: null,
      status: true,
    },
    personTypes: ['EMPLEADO',
    'VISITANTE',
    'CONTRATISTA',
    'PROVEEDOR',
    'RESIDENTE'],
    bulkSuborganization: null, 
    bulkWorkingGroup: null,
    bulkTypePerson: null,
    groups: [],
    bulkData: [],
    bulkErrors: [],
    costCenters: [],
    PSRegistryImg: "",
    registerImgBackup: "",
    EMAIL_PATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }),
  methods: {
    imgErrorHandler(url) {
      console.log("Handler de imagen de registro", url)
      this.PSRegistryImg = this.registerImgBackup//"https://847395.smushcdn.com/1790738/wp-content/uploads/2015/09/imagen-no-encontrada.jpg?lossy=0&strip=1&webp=1"
      this.$forceUpdate()
    },

    markedRow(item) {
      if (this.itemContent) {
        if (item.uuid === this.itemContent.uuid) {
          return "selected-row";
        }
      }
    },

    customFilterCostCenter(item, queryText) {
      const name = (item.name) ? item.name.toLowerCase() : '';
      const code = (item.code) ? item.code.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return (
        name.indexOf(searchText) > -1 ||
        code.indexOf(searchText) > -1
      );
    },

    uploadFile(file) {
      let reader = new FileReader();
      var self = this
      reader.onload = function(e) {
        let data = new Uint8Array(e.target.result);
        let workbook = xlsx.read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0]
        let worksheet = workbook.Sheets[sheetName];
        self.bulkData = xlsx.utils.sheet_to_json(worksheet)
      };
      reader.readAsArrayBuffer(file);
    },

    asignCP(cedula) {
      for (let i = 0; i < this.itemsList.length; i++) {
        const element = this.itemsList[i];
        if (element.document_number === cedula) {
          return [element.uuid, element.company_person.uuid]
        }
      }

    },

    asignCostCenterID(costCenter) {
      for (let i = 0; i < this.costCenters.length; i++) {
        const element = this.costCenters[i];
        if (element.code === costCenter) {
          return element.uuid
        }
      }

    },

    bulkUpdate(bulkSuborganization, bulkWorkingGroup, bulkTypePerson) {
      this.bulkErrors = []
      this.loadingBulkUpdate = true;
      for (let i = 0; i < this.bulkData.length; i++) {
        const element = this.bulkData[i];
        element.CEDULA = element.CEDULA.toString();
        if (element.CEDULA.length == 7) {
          element.CEDULA = "000" + element.CEDULA;
        } else if (element.CEDULA.length == 8) {
          element.CEDULA = "00" + element.CEDULA;
        } else if (element.CEDULA.length == 9) {
          element.CEDULA = "0" + element.CEDULA;
        }
        let IDs = this.asignCP(element.CEDULA);
        let CostID = this.asignCostCenterID(element.CENTRO_COSTOS);
        if (bulkSuborganization && bulkSuborganization != null) element.campus_id = bulkSuborganization;
        if (bulkWorkingGroup && bulkWorkingGroup != null) element.working_group_id = bulkWorkingGroup;
        if (bulkTypePerson && bulkTypePerson != null) element.typePerson = bulkTypePerson;
        (IDs) ? (element.person_id = IDs[0], element.company_person_id = IDs[1]) : (element.person_id = null, element.company_person_id = null);
        (element.CEDULA) ? element.document_number = element.CEDULA : element.document_number = null;
        (element.EMAIL) ? element.email = element.EMAIL.toString().trim() : element.email = null;
        (element.CELULAR) ? element.phone = element.CELULAR.toString() : element.phone = null;
        (element.DIRECCIÓN) ? (element.DIRECCIÓN = element.DIRECCIÓN.toString(), element.address = element.DIRECCIÓN.toUpperCase()) : element.address = null;
        (element.CARGO) ? element.position = element.CARGO.toUpperCase() : element.position = null;
        (CostID) ? (element.costCenter = CostID) : (element.costCenter = null);
        // TODO: Arreglar forma de subida de fecha desde excel(element.FECHA_RETIRO) ? (element.FECHA_RETIRO = element.FECHA_RETIRO.toString(), element.disengagement_date = element.FECHA_RETIRO) : element.disengagement_date = null;

        delete element.CEDULA
        delete element.EMAIL
        delete element.CELULAR
        delete element.DIRECCIÓN
        delete element.CARGO
        delete element.CENTRO_COSTOS
        //delete element.FECHA_RETIRO

        if (element.person_id === null || element.company_person_id === null) {
          this.bulkErrors.push("La persona con cédula: " + element.document_number + " no se encuentra registrado en la plataforma, Fila: " + (i + 2))
        }

        if (element.email && this.EMAIL_PATTERN.test(element.email) == false) {
          this.bulkErrors.push("El correo: '" + element.email + "' no está formado correctamente, Fila: " + (i + 2))
        }

        if (element.phone && (element.phone.length < 10 || element.phone.length > 10)) {
          this.bulkErrors.push("El celular: '" + element.phone + "' no tiene la cantidad suficiente de dígitos, Fila: " + (i + 2))
        }

      }

      if (this.bulkErrors.length == 0) {
        axios
          .post(globals.APIURL + "registry/bulk-update", {
            campus_id: auth.getSelectedSede(),
            data: this.bulkData
          })
          .then((res) => {
            if (res.data && res.data == 'Bulk update successful') {
              this.getRegistry()
              this.bulkData = []
              this.bulkErrors = []
              this.itemsList = []
              this.dialogCSV = false
              this.bulkSuborganization = null
              this.bulkWorkingGroup = null
              this.bulkTypePerson = null
              this.itemContent = null;
              this.itemContentVehicle = null;
              this.org = null;
              this.subOrg = null;
              this.loadingBulkUpdate = false;
              this.snackbarText = "Se realizó la carga masiva de información.";
              this.snackbarColor = "success";
              this.snackbar = true;
            }
            this.loadingBulkUpdate = false;
          })
          .catch((err) => {
            this.loadingBulkUpdate = false;
            console.log(err.response);
          });
      } else {
        this.snackbarText = "Por favor revise las advertencias e intente nuevamente.";
        this.snackbarColor = "error";
        this.snackbar = true;
        this.loadingBulkUpdate = false
      }
    },

    closeBulkDialog() {
      this.bulkData = []
      this.bulkErrors = []
      this.dialogCSV = false
      this.bulkSuborganization = null
      this.bulkWorkingGroup = null
      this.bulkTypePerson = null
    },

    activateEditMode(item) {
      this.editMode = true
      this._beforeEditingCachePerson = Object.assign({}, item);
      this._beforeEditingCacheEmails = Object.assign({}, item.emails[item.emails.length - 1]);
      this._beforeEditingCachePhones = Object.assign({}, item.phones[item.phones.length - 1]);
      this._beforeEditingCacheAddresses = Object.assign({}, item.addresses[item.addresses.length - 1]);
      this._beforeEditingCachePosition = Object.assign({}, item.company_person.Positions[item.company_person.Positions.length - 1]);
      this._beforeEditingCacheCampusCompanyPerson = Object.assign({}, item.company_person.Campus_company_person[0]);
      this._beforeEditingCacheCompanyPerson = Object.assign({}, item.company_person);
      this._beforeEditingCacheExtraFields = Object.assign({}, this.employeeData);
    },

    cancelEditMode(item) {
      this.editMode = false
      Object.assign(item, this._beforeEditingCachePerson);
      Object.assign(item.emails[item.emails.length - 1], this._beforeEditingCacheEmails);
      Object.assign(item.phones[item.phones.length - 1], this._beforeEditingCachePhones);
      Object.assign(item.addresses[item.addresses.length - 1], this._beforeEditingCacheAddresses);
      Object.assign(item.company_person.Positions[item.company_person.Positions.length - 1], this._beforeEditingCachePosition);
      Object.assign(item.company_person.Campus_company_person[0], this._beforeEditingCacheCampusCompanyPerson);
      Object.assign(item.company_person, this._beforeEditingCacheCompanyPerson);
      Object.assign(this.employeeData, this._beforeEditingCacheExtraFields);
      this.asignItem(item, 'registry')
    },

    adminVehicles(item) {
      this.dialogVehicle = true;
      this.itemContentVehicle = item;
    },

    getWorkingGroups() {
      axios
        .post(globals.APIURL + "working-group/list-wg", {
          campus_id: auth.getSelectedSede(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.groups = res.data
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getCostCenters() {
      axios
        .post(globals.APIURL + "working-group/all-cost-center", {
          campus_id: auth.getSelectedSede()
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.costCenters = res.data
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getOrganizations() {
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            this.organization = res.data;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getRegistry() {
      this.loading = true;
      if (this.cedula_registro) {
        this.cedula_registro.company_person = this.cedula_registro.company_person[0]
        this.search = this.cedula_registro.document_number
        this.asignItem(this.cedula_registro, 'registry')
      }
      axios
        .post(globals.APIURL + "registry/fa", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.itemsList = res.data;
            this.loading = false;
            console.log(this.itemsList);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.getRegistry()
          console.log(err.response);
        });
    },

    asignItem(item, type) {
      this.itemContent = null;
      this.itemContentVehicle = null;
      this.org = null;
      this.subOrg = null;
      if (item) {
        if (type == "registry") {
          this.getImg(item)
          this.itemContent = item;
          if (!item.emails || item.emails.length == 0) this.itemContent.emails.push({email: ''}) ; this.itemContent.emails = item.emails;
          if (!item.phones || item.phones.length == 0) this.itemContent.phones.push({phone: ''}) ; this.itemContent.phones = item.phones;
          if (!item.addresses || item.addresses.length == 0) this.itemContent.addresses.push({Address: ''}) ; this.itemContent.addresses = item.addresses;
          if (!item.company_person.Positions || item.company_person.Positions.length == 0) this.itemContent.company_person.Positions.push({position: ''}) ; this.itemContent.company_person.Positions = item.company_person.Positions;
          if (!item.company_person.Campus_company_person || item.company_person.Campus_company_person.length === 0) this.itemContent.company_person.Campus_company_person.push({campus: {}}) ; this.itemContent.company_person.Campus_company_person = item.company_person.Campus_company_person;
          if (!item.company_person.suborgs_person || item.company_person.suborgs_person.length === 0) this.itemContent.company_person.suborgs_person.push({sub_org: {}}) ; this.itemContent.company_person.suborgs_person = item.company_person.suborgs_person;
          // TODO: Validar esta funcion cuando se termine todo ---> this.organizeOrg();
          this.getFields(item.uuid);
          this.loadingProfile = false
          //if (this.roleChecker.iHaveGrants(this.userRole, "REGISTRY-BACKGROUND")) this.validateRecords(item.DocumentoUsuario);
        }
      }
    },

    getFields(personID) {
      axios
        .post(globals.APIURL + "registry/getFields", {
          filter: {
            person_id: personID,
          },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            console.log(res.data.length)
            this.employeeData = res.data[0];
          } else {
            axios
              .post(globals.APIURL + "registry/createFields", {
                person_id: personID,
                position: "",
                operationCenter: "",
                operationCenterDesc: "",
                costCenter: "",
                costCenterDesc: "",
                ingresoDate: null,
                retiroDate: null,
                status: false,
              })
              .then((res) => {
                if (res.data && res.data === "Fields were created successful") {
                  console.log(res.data);
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    statusEmployee(ingresoDate, retiroDate) {
      if (ingresoDate) {
        if (this.todayDate >= ingresoDate && !retiroDate) {
          return true;
        } else if (
          this.todayDate >= ingresoDate &&
          this.todayDate <= retiroDate
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    updateRegistry(item) {
      this.loadingReg = true
      if (!this.employeeData.retiroDate || (this.employeeData.retiroDate >= item.company_person.created_at.substring(0, 10))) {
        if (item.company_person.Positions[item.company_person.Positions.length - 1].position != this._beforeEditingCachePosition.position) {
          item.company_person.Positions.push({position: item.company_person.Positions[item.company_person.Positions.length - 1].position})
        }

        if (item.emails[item.emails.length - 1].email != this._beforeEditingCacheEmails.email) {
          item.emails.push({email: item.emails[item.emails.length - 1].email})
        }

        if (item.phones[item.phones.length - 1].phone != this._beforeEditingCachePhones.phone) {
          item.phones.push({phone: item.phones[item.phones.length - 1].phone})
        }

        if (item.addresses[item.addresses.length - 1].Address != this._beforeEditingCacheAddresses.Address) {
          item.addresses.push({Address: item.addresses[item.addresses.length - 1].Address})
        }

        axios
            .put(globals.APIURL + "registry/ur", {
              campus_id: auth.getSelectedSede(),
              person_id: item.uuid,
              update_person_obj: {
                email: item.emails[item.emails.length - 1],
                phone: item.phones[item.phones.length - 1],
                address: item.addresses[item.addresses.length - 1],
              },
              company_person_id: item.company_person.uuid,
              update_company_obj: {
                campus: (item.company_person.Campus_company_person[0].campus.name != this._beforeEditingCacheCampusCompanyPerson.campus.name) ? item.company_person.Campus_company_person[0].campus : null,
                position: item.company_person.Positions[item.company_person.Positions.length - 1],
                type_person: item.company_person.type_person,
                cost_center_id: item.company_person.cost_center != null && item.company_person.cost_center.uuid ? item.company_person.cost_center.uuid : null,
                working_group_id: item.company_person.working_group != null && item.company_person.working_group.uuid ? item.company_person.working_group.uuid : null
              },
            })
            .then((res) => {
              if (res && res.status == 200) {
                if (res.data == "Documents were updated successful") {
                  this.getOneRegistry(item, 'update')
                  this.employeeData.status = this.statusEmployee(
                    item.company_person.created_at.substring(0, 10),
                    this.employeeData.retiroDate
                  );
                  axios
                    .post(globals.APIURL + "registry/updateFields", {
                      filter: {
                        person_id: item.uuid,
                      },
                      updateObj: this.employeeData,
                    })
                    .then((res) => {
                      if (res.data == "Fields were updated successful") {
                        // TODO: Agregar logica para guardar logs.
                        this.getFields(item.uuid)
                      }
                    })
                    .catch((err) => {
                      console.log(err.response);
                    });
                }
              }
            })
            .catch((err) => {
              this.loadingReg = false
              console.log(err.response);
            });
      } else {
        this.employeeData.status = this.statusEmployee(
          item.company_person.created_at.substring(0, 10),
          this.employeeData.retiroDate
        );
        this.snackbarText =
          "La fecha de retiro no puede ser menor que la fecha de ingreso.";
        this.snackbarColor = "red";
        this.snackbar = true;
        this.loadingReg = false
      }
    },

    deleteRegistry(itemContent, documentText) {
      this.loadingReg = true
      itemContent.campus_id = auth.getSelectedSede()
      if (itemContent.document_number === documentText) {
        axios
          .post(globals.APIURL + "registry/dr", itemContent)
          .then((res) => {
            if (res && res.status == 200) {
              if (res.data == "Company person deleted successfuly") {
                for (let i = 0; i < this.itemsList.length; i++) {
                  const el = this.itemsList[i];
                  if (el.document_number === itemContent.document_number) {
                    this.itemsList.splice(i, 1);
                  }
                }
                backlog.generateLog({
                  enterprise: this.organization.name,
                  username: auth.getUsername(),
                  role: auth.getRoleName(),
                  action: "delete/registry",
                });
                this.snackbarText = "Se eliminó la persona de forma correcta";
                this.snackbarColor = "green";
                this.snackbar = true;
                this.dialogDelete = false;
                this.itemContent = null;
                this.itemContentVehicle = null;
                this.org = null;
                this.subOrg = null;
                this.search = "";
                this.cc_validation = ""
                this.loadingReg = false
              }
            }
          })
          .catch((err) => {
            this.loadingReg = false
            console.log(err.response);
          });
      }
    },

    getOneRegistry(item, type) {
      this.itemContent = null;
      this.itemContentVehicle = null;
      this.org = null;
      this.subOrg = null;
      this.loadingProfile = true;
      axios
        .post(globals.APIURL + "registry/fo", {
          person_id: item.uuid,
          company_id: item.company_person.company_id,
        })
        .then((res) => {
          if (res.data.uuid) {
            backlog.generateLog({
              enterprise: this.organization.name,
              username: auth.getUsername(),
              role: auth.getRoleName(),
              action: "update/registry",
            });

            if (type === 'update') {
              this.snackbarText = "Se actualizaron los datos.";
              this.snackbarColor = "green";
              this.snackbar = true;
            }
            this.editMode = false;
            this.loadingReg = false
            this.asignItem(res.data, "registry");
          }
        })
        .catch((err) => {
          this.loadingReg = false
          this.loadingProfile = false
          console.log(err.response);
        });
    },

    getImg(item) {
      this.PSRegistryImg = "";
      this.registerImgBackup = ""
      if (item) {
        if (item.register_image) {
          this.PSRegistryImg = globals.APIURL + "entry/registry-pic/" + item.register_image;
        } else {
          this.PSRegistryImg = globals.APIURL + "entry/entry-picture/" + this.organization.name + "/" + item.first_name + "/" + item.first_last_name + "/" + item.document_number;
        }

        if (item.entries.length > 0) {
          this.registerImgBackup = globals.APIURL + "entry/daily-pic/" + item.entries[0].image[0];
        }
      }
    },

    validateRecords(document) {
      axios
        .post(globals.APIURL + "identity/gant", {
          Document: document,
        })
        .then((res) => {
          if (res.data.antecedentes) {
            this.antecedentesUsuario.antecedentes =
              res.data.antecedentes.antecedentes.length;
            this.antecedentesUsuario.inhabilidades =
              res.data.antecedentes.inhabilidades.length;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    // TODO: Reposte de antecedentes no está actualizado.
    generateReport(jsonContent) {
      if (jsonContent.length > 0) {
        this.loadingReport = true;

        for (let i = 0; i < jsonContent.length; i++) {
          const element = jsonContent[i];
          element.Antecedentes = 0;
          element.Inhabilidades = 0;
          delete element._id;
          delete element.IdUnico;
          delete element.RekognitionId;
          delete element.FechaIngreso;
          delete element.IdAcceso;
          delete element.Organizacion;
          delete element.SubOrganizacion;
          delete element.tipoDocumento;
          if (
            element.DocumentoUsuario.charAt(0) == "0" &&
            element.DocumentoUsuario.charAt(1) == "0" &&
            element.DocumentoUsuario.charAt(2) == "0"
          ) {
            element.DocumentoUsuario = element.DocumentoUsuario.substring(3);
          } else if (
            element.DocumentoUsuario.charAt(0) == "0" &&
            element.DocumentoUsuario.charAt(1) == "0" &&
            element.DocumentoUsuario.charAt(2) != "0"
          ) {
            element.DocumentoUsuario = element.DocumentoUsuario.substring(2);
          } else if (
            element.DocumentoUsuario.charAt(0) == "0" &&
            element.DocumentoUsuario.charAt(1) != "0" &&
            element.DocumentoUsuario.charAt(2) != "0"
          ) {
            element.DocumentoUsuario = element.DocumentoUsuario.substring(1);
          }
          axios
            .post(globals.APIURL + "identity/gant", {
              Document: element.DocumentoUsuario,
            })
            .then((res) => {
              if (res.data.antecedentes) {
                this.counter++;
                if (res.data.antecedentes.antecedentes.length === 0) {
                  element.Antecedentes = "NO REGISTRA";
                } else {
                  element.Antecedentes =
                    res.data.antecedentes.antecedentes.length;
                }
                if (res.data.antecedentes.inhabilidades.length === 0) {
                  element.Inhabilidades = "NO REGISTRA";
                } else {
                  element.Inhabilidades =
                    res.data.antecedentes.inhabilidades.length;
                }

                if (this.counter === jsonContent.length) {
                  this.loadingReport = false;
                  this.generateExcel(jsonContent);
                }
              }
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
      }
    },

    generateExcel(jsonContent) {
      let newWB = xlsx.utils.book_new();
      let newWS = xlsx.utils.json_to_sheet(jsonContent, {
        header: [
          "DocumentoUsuario",
          "Nombre1",
          "Nombre2",
          "Apellido1",
          "Apellido2",
          "Birthdate",
          "Genero",
          "RH",
          "Antecedentes",
          "Inhabilidades",
        ],
      });

      newWS["A1"].v = "CC";
      newWS["B1"].v = "PRIMER NOMBRE";
      newWS["C1"].v = "SEGUNDO NOMBRE";
      newWS["D1"].v = "PRIMER APELLIDO";
      newWS["E1"].v = "SEGUNDO APELLIDO";
      newWS["F1"].v = "FECHA NACIMIENTO";
      newWS["G1"].v = "GENERO";
      newWS["H1"].v = "RH";
      newWS["I1"].v = "ANTECEDENTES";
      newWS["J1"].v = "INHABILIDADES";

      xlsx.utils.book_append_sheet(newWB, newWS, "Registros");

      xlsx.writeFile(
        newWB,
        "Registros-" +
          backlog.formatDate().substring(0, 10).replace(/\//gi, "-") +
          ".xlsx",
        {
          type: "file",
          bookType: "xlsx",
        }
      );
      this.snackbarText = "Se descargó su reporte de registros.";
      this.snackbarColor = "green";
      this.snackbar = true;
    },

    generateExcelGuide() {
        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet([{}], {
          header: [
            "CEDULA",
            "EMAIL",
            "CELULAR",
            "DIRECCIÓN",
            "CARGO",
            "CENTRO_COSTOS"
            //"FECHA_RETIRO" //TODO: Arreglar forma de subida de fecha desde excel
          ],
        });

        newWS["A1"].v = "CEDULA";
        newWS["B1"].v = "EMAIL";
        newWS["C1"].v = "CELULAR";
        newWS["D1"].v = "DIRECCIÓN";
        newWS["E1"].v = "CARGO";
        newWS["F1"].v = "CENTRO_COSTOS";
        //newWS["G1"].v = "FECHA_RETIRO"; //TODO: Arreglar forma de subida de fecha desde excel

        xlsx.utils.book_append_sheet(newWB, newWS, "Información");

        xlsx.writeFile(
          newWB,
          "Archivo Carga Masiva VS.xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );
        this.snackbarText = "Se descargó el archivo.";
        this.snackbarColor = "green";
        this.snackbar = true;
    },
  },
  mounted() {
    this.getOrganizations();
    this.getWorkingGroups();
    this.getCostCenters();
    this.getRegistry();
  },
};
</script>
<style>
.bulkErrorBox {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100px;
}

.rounded-edges {
  border-radius: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

table {
  border-collapse: collapse;
}

.selected-row {
  background-color: lightgray;
}

tr:hover {
  background-color: lightgray !important;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>