<template>
  <v-container fluid>
    <div v-if="userRole === 'SUPER'" class="ma-0 pa-0">
      <div class="text-center" v-if="dialog == true">
        <v-overlay :opacity="1" :value="dialog">
          <v-dialog v-model="dialog" persistent width="900">
            <v-card>
              <v-card-title
                class="
                  black--text
                  headline
                  grey
                  lighten-2
                  d-flex
                  justify-center
                "
              >
                DISPOSITIVO {{ dvcSelected.mac }}
              </v-card-title>
              <v-card-text>
                <v-row class="text-center">
                  <v-col cols="12" md="4">
                    <p>
                      IP: <strong>{{ dvcSelected.ip }}</strong>
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Org:
                      <strong>{{
                        dvcSelected.campus_id
                          ? dvcSelected.campus.name
                          : "Sin asignar"
                      }}</strong>
                    </p>
                    <v-select
                      v-if="modifyDvc"
                      v-model="dvcSelected.campus_id"
                      :items="campus"
                      item-text="name"
                      item-value="uuid"
                      dense
                      label="Organización"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Tipo:
                      <strong>{{
                        dvcSelected.type_device.toUpperCase()
                      }}</strong>
                    </p>
                    <v-select
                      v-if="modifyDvc"
                      v-model="dvcSelected.type_device"
                      :items="deviceTypes"
                      dense
                      label="Tipo Dispositivo"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Modo:
                      <strong>{{
                        dvcSelected.mode === "ENTRY" ? "ENTRADA" : "SALIDA"
                      }}</strong>
                    </p>
                    <v-select
                      v-if="modifyDvc"
                      v-model="dvcSelected.mode"
                      :items="modes"
                      item-text="Name"
                      item-value="ID"
                      dense
                      label="Modo"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p>
                      Ultima Conexión:
                      <strong>{{
                        dvcSelected.last_connection
                          ? dvcSelected.last_connection.slice(0, 10) +
                            " " +
                            dvcSelected.last_connection.slice(
                              11,
                              dvcSelected.last_connection.length - 8
                            )
                          : "---"
                      }}</strong>
                    </p>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p>
                      Estado:
                      <v-chip
                        :color="dvcSelected.status === true ? 'green' : 'error'"
                        text-color="white"
                        class="darken-3"
                        small
                      >
                        <small>{{
                          dvcSelected.status === true ? "ON" : "OFF"
                        }}</small>
                      </v-chip>
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Nombre:
                      <strong>{{
                        dvcSelected.name != null
                          ? dvcSelected.name
                          : "Sin Nombrar"
                      }}</strong>
                    </p>
                    <v-text-field
                      v-if="modifyDvc"
                      label="Nombre"
                      v-model="dvcSelected.name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex justify-center align-center"
                  >
                    <v-btn
                      v-if="!modifyDvc"
                      block
                      color="primary"
                      class="mr-4"
                      @click="modifyDvc = true"
                    >
                      MODIFICAR CAMPOS
                    </v-btn>
                    <v-btn
                      v-if="modifyDvc"
                      block
                      color="success"
                      class="mr-4"
                      @click="updateDevice(dvcSelected)"
                    >
                      ENVIAR CAMBIOS
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex justify-center align-center"
                  >
                    <v-btn
                      block
                      color="error"
                      class="mr-4"
                      @click="closeEditModel()"
                    >
                      CERRAR
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-overlay>
      </div>
      <div class="text-center" v-if="deleteDialog == true">
        <v-dialog v-model="deleteDialog" persistent width="700">
          <v-card>
            <v-card-title
              class="black--text headline grey lighten-2 d-flex justify-center"
            >
              ELIMINAR DISPOSITIVO
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12">
                  <p class="text-subtitle-1">
                    Esta seguro que desea eliminar el dispositivo con MAC:
                    <strong>{{ dvcToDelete.mac }}</strong
                    >?
                  </p>
                  <p class="text-subtitle-1">
                    Si es así por favor confirme la MAC del dispositivo.
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="MAC"
                    v-model="MACVerification"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="primary"
                    class="mr-4"
                    @click="deleteDevice(dvcToDelete, MACVerification)"
                  >
                    ELIMINAR DISPOSITIVO
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="error"
                    class="mr-4"
                    @click="closeDeleteModel()"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center" v-if="upgradeDialog == true">
        <v-dialog v-model="upgradeDialog" persistent width="700">
          <v-card>
            <v-card-title
              class="black--text headline grey lighten-2 d-flex justify-center"
            >
              ACTUALIZAR FW DISPOSITIVO
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12">
                  <p class="text-subtitle-1 mb-1 pb-1">
                    Esta seguro que desea actualizar el firmware del dispositivo
                    con MAC:
                    <strong>{{ dvcToUpgrade.mac }}</strong
                    >?
                  </p>
                  <p class="text-subtitle-1 mb-1 pb-1">
                    *Una vez se actualice no podra hacer downgrade de versión
                    desde aquí*
                  </p>
                  <p class="text-subtitle-1 mb-1 pb-1">
                    Si desea continuar con la actualización confirme la MAC del
                    equipo, por favor.
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="MAC"
                    v-model="MACVerification"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="primary"
                    class="mr-4"
                    @click="upgradeFW(dvcToUpgrade, MACVerification)"
                  >
                    ACTUALIZAR DISPOSITIVO
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="error"
                    class="mr-4"
                    @click="closeDeleteModel()"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <v-row class="text-center">
        <v-col cols="12">
          <p class="grey--text font-weight-bold text-h3">Dispositivos</p>
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col cols="12" md="11">
          <v-select
            v-model="org"
            :items="campus"
            item-text="name"
            item-value="uuid"
            label="Organizacion"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-btn
            class="ma-2"
            outlined
            fab
            small
            color="indigo"
            @click="getDevices(org, subOrg)"
          >
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col cols="12">
          <v-card elevation="5">
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12" v-if="!devices"
                  ><p>Filtre sus dispositivos.</p></v-col
                >
                <v-col cols="12" v-if="devices && devices.length == 0">
                  <p v-if="loading == false">
                    No hay dispositivos que mostrar.
                  </p>
                  <span v-if="loading == true">
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      :indeterminate="loading"
                    >
                    </v-progress-circular>
                      <p class="pt-2 text-subtitle-2">Cargando dispositivos...</p>
                  </span>
                </v-col>
                <v-col cols="12" v-if="devices && devices.length > 0">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Filtrar dispositivos"
                    single-line
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-layout child-flex>
                    <v-data-table
                      :headers="headers"
                      :items="devices"
                      :search="search"
                      :items-per-page="5"
                      :header-props="{
                        mobile: true,
                      }"
                      :footer-props="{
                        'items-per-page-options': [20],
                      }"
                      class="elevation-1"
                      fixed-header
                      no-results-text="No hay dispositivos con el dato ingresado. "
                    >
                      <template v-slot:[`item.Location`]="{ item }">
                        <div v-if="item.campus_id">
                          <span>{{ item.campus.name }}</span>
                        </div>
                        <span v-if="!item.campus_id">Sin asignar</span>
                      </template>
                      <template v-slot:[`item.Name`]="{ item }">
                        <span>{{
                          item.name !== null ? item.name : "Sin nombrar"
                        }}</span>
                      </template>
                      <template v-slot:[`item.last_connection`]="{ item }">
                        <span>{{
                          item.last_connection
                            ? item.last_connection.slice(0, 10) +
                              " " +
                              item.last_connection.slice(
                                11,
                                item.last_connection.length - 8
                              )
                            : "---"
                        }}</span>
                      </template>
                      <template v-slot:[`item.Status`]="{ item }">
                        <v-chip
                          :color="
                            validateDvcConnection(
                              item.last_connection.slice(0, 10) +
                                ' ' +
                                item.last_connection.slice(
                                  11,
                                  item.last_connection.length - 8
                                )
                            ) === true
                              ? 'green'
                              : 'error'
                          "
                          text-color="white"
                          class="darken-1"
                        >
                          <v-icon
                            v-if="
                              validateDvcConnection(
                                item.last_connection.slice(0, 10) +
                                  ' ' +
                                  item.last_connection.slice(
                                    11,
                                    item.last_connection.length - 8
                                  )
                              )
                            "
                            class="text-center"
                            color="white"
                          >
                            mdi-power-plug-outline
                          </v-icon>
                          <v-icon
                            v-if="
                              !validateDvcConnection(
                                item.last_connection.slice(0, 10) +
                                  ' ' +
                                  item.last_connection.slice(
                                    11,
                                    item.last_connection.length - 8
                                  )
                              )
                            "
                            class="text-center"
                            color="white"
                          >
                            mdi-power-plug-off-outline
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:[`item.options`]="{ item }">
                        <v-icon
                          class="text-center"
                          color="primary"
                          title="Editar"
                          @click="openEditDialog(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          class="text-center"
                          color="success"
                          title="Actualizar"
                          @click="openUpgradeDialog(item)"
                        >
                          mdi-update
                        </v-icon>
                        <v-icon
                          class="text-center"
                          color="error"
                          title="eliminar"
                          @click="openDeleteDialog(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <div v-if="userRole === 'ADMIN'" class="ma-0 pa-0">
      <admin-devices></admin-devices>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import AdminDevices from "./admin-devices.vue";

export default {
  name: "Devices",
  components: {
    AdminDevices,
  },
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    dialog: false,
    deleteDialog: false,
    upgradeDialog: false,
    dvcSelected: null,
    dvcToDelete: null,
    dvcToUpgrade: null,
    MACVerification: "",
    userRole: auth.getRoleName(),
    campus: [],
    editOrganizations: [],
    org: null,
    subOrg: null,
    devices: null,
    search: "",
    loading: false,
    modifyDvc: false,
    headers: [
      { text: "MAC", align: "center", value: "mac", sortable: false },
      { text: "IP", align: "center", value: "ip", sortable: false },
      {
        text: "Ubicación",
        align: "center",
        value: "Location",
        sortable: false,
      },
      { text: "Nombre", align: "center", value: "Name", sortable: false },
      {
        text: "Ultima Conexión",
        align: "center",
        value: "last_connection",
        sortable: false,
      },
      { text: "Estado", align: "center", value: "Status", sortable: false },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    modes: [
      { ID: "ENTRY", Name: "ENTRADA" },
      { ID: "EXIT", Name: "SALIDA" },
    ],
    deviceTypes: ['vsgate', 'vspark', 'vision'],
    testMode: null,
  }),
  methods: {
    validateDvcConnection(lastConnectionTime) {
      const todaysTime = new Date(
        globals.COdate().slice(0, 10) +
          " " +
          globals.COdate().slice(11, globals.COdate().length - 8)
      );
      lastConnectionTime = new Date(lastConnectionTime);
      let difference = Math.round(
        (todaysTime.getTime() - lastConnectionTime.getTime()) / 60000
      ); // Diferencia entre fechas en minutos

      if (difference > 3) {
        return false;
      } else {
        return true;
      }
    },

    upgradeFW(item, macToVerify) {
      if (item.mac == macToVerify) {
        axios
          .post(globals.APIURL + "vehicle/bcs", {
            topic: item.type_device + "/" + item.mac + "/update",
            message: "0",
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        this.upgradeDialog = false;
        this.dvcToUpgrade = null;
        this.MACVerification = "";
        this.getDevices(this.org);
        this.snackbar = true;
        this.snackbarColor = "success";
        this.snackbarText =
          "Se envío señal de actualización de FW al dispositivo de forma exitosa.";
      } else {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText =
          "La MAC ingresada no coincide con la del dispositivo que desea actualizar, ingresela nuevamente.";
        this.MACVerification = "";
      }
    },

    getOrganizations() {
      axios
        .get(globals.APIURL + "campus/fac")
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.campus.push({ name: "Sin Asignar", uuid: "0000" });
            res.data.forEach((element) => {
              this.campus.push(element);
              this.editOrganizations.push(element);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDevices(campus) {
      let filter = {};
      this.loading = true;
      this.devices = [];
      campus.uuid == "0000"
        ? (filter = { campus_id: null })
        : (filter = { campus_id: campus.uuid });
      if (campus.uuid) {
        axios
          .post(globals.APIURL + "device/fb", {
            filter,
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.devices = res.data;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },

    updateDevice(device) {
      const dvcUpdateObj = {
        name: device.name,
        mac: device.mac,
        campus_id: device.campus_id,
        mode: device.mode,
        type_device: device.type_device
      };
      axios
        .put(globals.APIURL + "device/ud", {
          device_id: device.uuid,
          update_obj: dvcUpdateObj,
        })
        .then((res) => {
          if (res.data == "Device updated successfuly") {
            this.dialog = false;
            this.dvcSelected = null;
            this.modifyDvc = false;
            this.testMode = null;
            this.getDevices(this.org);
            this.snackbar = true;
            this.snackbarColor = "success";
            this.snackbarText = "Se modificó el dispositivo con exito.";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteDevice(dvcToDelete, MACVerification) {
      if (dvcToDelete.mac == MACVerification) {
        axios
          .delete(globals.APIURL + "device/dd/" + dvcToDelete.uuid)
          .then((res) => {
            if (res.data == "Device deleted successfuly") {
              this.deleteDialog = false;
              this.dvcToDelete = null;
              this.getDevices(this.org);
              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText = "Se eliminó el dispositivo de forma exitosa.";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText =
          "La MAC ingresada no coincide con la del dispositivo a borrar, ingresela nuevamente.";
      }
    },

    openEditDialog(item) {
      this.dialog = true;
      this.dvcSelected = item;
      this.testMode = item.mode;
    },

    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.dvcToDelete = item;
    },

    openUpgradeDialog(item) {
      this.upgradeDialog = true;
      this.dvcToUpgrade = item;
    },

    closeEditModel() {
      this.dvcSelected = null;
      this.dialog = false;
      this.modifyDvc = false;
      this.getDevices(this.org);
    },

    closeDeleteModel() {
      this.dvcToDelete = null;
      this.dvcToUpgrade = null;
      this.deleteDialog = false;
      this.upgradeDialog = false;
      this.MACVerification = "";
      this.getDevices(this.org);
    },
  },
  mounted() {
    this.getOrganizations();
  },
};
</script>
<style>
tr:hover {
  background-color: transparent !important;
}
</style>
