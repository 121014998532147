<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  data() {
    return {
      intervalChart: "",
      dataLabels: [],
      workedHoursDataValues: [],
      overtimeDataValues: [],
      sundayDataValues: [],
      nightDataValues: [],
      absencesDataValues: [],
      maxNumber: null
    };
  },
  props: {
    label: {
      type: String,
    },
    chartData: {
      type: Object,
    },
  },
  methods: {
    makeChart() {
        let maxOne, maxTwo, maxThree, maxFour
        let allMaxed = []
        this.chartData.arrayData.forEach((element) => {
          if (Math.sign(element.entradaSalida.AllWorkedHoursDay) === -1) element.entradaSalida.AllWorkedHoursDay = 0; else element.entradaSalida.AllWorkedHoursDay;
          if (Math.sign(element.entradaSalida.AllWorkedOvertimeDay) === -1) element.entradaSalida.AllWorkedOvertimeDay = 0; else element.entradaSalida.AllWorkedOvertimeDay;
          if (Math.sign(element.entradaSalida.AllSundayHoursDay) === -1) element.entradaSalida.AllSundayHoursDay = 0; else element.entradaSalida.AllSundayHoursDay;
          if (Math.sign(element.entradaSalida.AllNightHoursDay) === -1) element.entradaSalida.AllNightHoursDay = 0; else element.entradaSalida.AllNightHoursDay;
          this.dataLabels.push(element.entradaSalida.fecha.substring(8, 10))
          this.workedHoursDataValues.push(element.entradaSalida.AllWorkedHoursDay)
          this.overtimeDataValues.push(element.entradaSalida.AllWorkedOvertimeDay)
          this.sundayDataValues.push(element.entradaSalida.AllSundayHoursDay)
          this.nightDataValues.push(element.entradaSalida.AllNightHoursDay)
          //this.absencesDataValues.push(element.entradaSalida.AllAbsencesDay)
        })

        maxOne = Math.max.apply(null, this.workedHoursDataValues)
        maxTwo = Math.max.apply(null, this.overtimeDataValues)
        maxThree = Math.max.apply(null, this.sundayDataValues)
        maxFour = Math.max.apply(null, this.nightDataValues)

        if (maxOne) allMaxed.push(maxOne);
        if (maxTwo) allMaxed.push(maxTwo);
        if (maxThree) allMaxed.push(maxThree);
        if (maxFour) allMaxed.push(maxFour);
        
        this.maxNumber = Math.max.apply(null, allMaxed) + (40 / 100 * Math.max.apply(null, allMaxed))

        this.renderChart(
        {
          labels: this.dataLabels,
          datasets: [
            {
              label: "Laboradas",
              data: this.workedHoursDataValues,
              backgroundColor: '#039BE5',
            },
            {
              label: "Extras",
              data: this.overtimeDataValues,
              backgroundColor: '#F4511E',
            },
            {
              label: "Dominicales",
              data: this.sundayDataValues,
              backgroundColor: '#7CB342',
            },
            {
              label: "Nocturnas",
              data: this.nightDataValues,
              backgroundColor: '#6A1B9A',
            },
            /* {
                label: "Ausencias",
                data: this.absencesDataValues,
                backgroundColor: '#FDD835',
            }, */
          ],
        },
        {
          maintainAspectRatio: false,
          animations: {
            duration: 0, // general animation time
          },
          hover: {
            animationDuration: 0, // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0,
          responsive: true,
          elements: {
            /* line: {
              tension: 0.4,
            }, */
          },
          legend: {
            labels: {
              fontColor: "#363636",
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor:  "#363636",
                },
              },
            ],
            yAxes: [
              {
                gridLines: { 
                  tickMarkLength: 0,
                  drawBorder: false,
                  zeroLineColor: "transparent",
                },
                ticks: {
                  suggestedMax: Math.round(this.maxNumber),
                  padding: 5,
                }
              },
            ],
          },
          title: {
            display: false
          },
          tooltips: {
            enable: true,
            mode: "single",
            /* callbacks: {
              title: function (tooltipItems) {
                return "Hora: " + tooltipItems[0].xLabel;
              },
            }, */
          },
        }
      );
    },

    callDataChartEvery() {
      this.intervalChart = window.setInterval(
        function () {
          this.makeChart();
          this.dataLabels = []
          this.workedHoursDataValues = []
          this.overtimeDataValues = []
          this.sundayDataValues = []
          this.nightDataValues = []
          this.absencesDataValues = []
        }.bind(this),
        3000000
      );
    },
  },
  mounted() {
    //this.callDataChartEvery();
    this.makeChart();
  },
  beforeDestroy() {
    clearInterval(this.intervalChart);
  },
};
</script>
