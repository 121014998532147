let roles = [
  {
    "Name": "SUPER",
    "Value": "Super Admin.",
    "Grants": [
      "MENU-REG",
      "MENU-FORM",
      "MENU-LOGS",
      "MENU-DEVICES",
      "MENU-CARS",
      "MENU-PAYROLL",
      "REG-UPDATE",
      "REG-DELETE",
      "REG-CREATE",
      "CAR-ASIGN",
      "REGISTRY-BACKGROUND"
    ]
  },
  {
    "Name": "ADMIN",
    "Value": "Administrador",
    "Grants": [
      "MENU-REG",
      "MENU-FORM",
      "MENU-CARS",
      "MENU-DEVICES",
      "MENU-PAYROLL",
      "REG-UPDATE",
      "REG-DELETE",
      "REG-CREATE",
      "CAR-ASIGN",
    ]
  },
  {
    "Name": "ADMIN2",
    "Value": "Administrador",
    "Grants": [
      "MENU-REG",
      "MENU-FORM",
      //"MENU-LOGS",
      //"MENU-DEVICES",
      //"MENU-CARS",
      //"MENU-PAYROLL",
      "REG-UPDATE",
      //"REG-DELETE",
      "REG-CREATE",
      //"CAR-ASIGN",
      "REGISTRY-BACKGROUND"
    ]
  },
  {
    "Name": "IOT",
    "Value": "Administrador.",
    "Grants": [
      /* "MENU-REG",
      "MENU-FORM", */
    ]
  },
  {
    "Name": "IMG",
    "Value": "Administrador.",
    "Grants": [
      /* "MENU-REG",
      "MENU-FORM", */
    ]
  },
  {
    "Name": "VISUALIZATION",
    "Value": "Supervisor",
    "Grants": [
      "MENU-REG",
      "MENU-FORM"
    ]
  }
];

let containsRole = (grant, grants) => {
  return (grants.indexOf(grant) > -1);
}

let getRoleName = (role) => {
  if (role == roles[0].Name) {
    return roles[0].Value;
  } else if (role == roles[1].Name) {
    return roles[1].Value;
  } else if (role == roles[2].Name) {
    return roles[2].Value
  } else if (role == roles[3].Name) {
    return roles[3].Value
  } else {
    return 'No Rol.'
  }
}

const iHaveGrants = (role, grant) => {
  if (role == roles[0].Name) {
    return true;
  } else if (role == roles[1].Name) {
    if (containsRole(grant, roles[1].Grants)) {
      return true;
    } else {
      return false;
    }
  } else if (role == roles[2].Name) {
    if (containsRole(grant, roles[2].Grants)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export default { iHaveGrants, getRoleName }
