<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-row >
          <!-- -------------------- VISUALIZACION DEL PERFIL DEL USUARIO -------------------- -->
          <v-col cols="12">
            <v-card elevation="24">
              <v-row v-if="lastItems && lastItems.length > 0 && personSelected" class="full-height d-flex justify-center align-center px-3">
                <v-col cols="12" md="12" sm="12" class="d-flex justify-center">
                  <v-card elevation="0" width="90%">                    
                    <v-card-text class="pa-0 ma-0">
                      <v-row>
                        <v-col cols="12" md="6" class="text-center">
                          <p class="font-weight-light" style="font-size: 1.15rem">IMG. REGISTRO</p>
                          <v-avatar
                            size="160"
                          >
                            <v-img
                              :src="PSRegistryImg"
                              lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                              @error="imgErrorHandler"
                            >
                            </v-img>
                          </v-avatar>
                        </v-col>
                        <v-col cols="12" md="6" class="text-center">
                          <p class="font-weight-light" style="font-size: 1.15rem">IMG. ÚLTIMO ACCESO</p>
                          <v-avatar
                            size="160"
                          >
                            <v-img
                              :src="PSDailyImg"
                              lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                            >
                            </v-img>
                          </v-avatar>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="text-center py-2"
                          title="DOCUMENTO"
                        >
                          <span class="text-subtitle-1">
                            <strong>N° Documento: </strong>
                            {{ personSelected.person.document_number }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="text-center py-2"
                          title="NOMBRE"
                        >
                          <span class="text-subtitle-1">
                            <strong>Nombre: </strong>
                            {{
                              personSelected.person.first_name +
                              " " +
                              personSelected.person.first_last_name +
                              " " +
                              personSelected.person.second_last_name
                            }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="text-center py-2"
                          title="SUB ORGANIZACIÓN"
                        >
                          <span class="text-subtitle-1">
                            <strong>Sub Organización: </strong>
                            {{
                              personSelected.person.company_person[0].suborgs_person.length > 0 ? personSelected.person.company_person[0].suborgs_person[0].sub_org.name : 'SIN ASIGNAR'
                            }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="text-center py-2"
                          title="TIPO DE USUARIO"
                        >
                          <span class="text-subtitle-1">
                            <strong>T. Usuario: </strong>
                            {{
                              personSelected.person.company_person[0].type_person
                            }}
                          </span>
                        </v-col>
                        <v-col cols="12" class="pa-0 ma-0 text-center">
                          <v-btn
                            color="primary"
                            x-small
                            outlined
                            @click="goToDetailedProfile(personSelected)"
                          >
                            Ver Perfil Detallado
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-if="lastItems && lastItems.length > 0 && !personSelected" class="full-height">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-row>
                    <v-col class="text-center">
                      <v-icon x-large color="grey" style="font-size: 70px">
                        mdi-account-circle-outline
                      </v-icon>
                      <p class="text-subtitle-1 mt-5" style="color: #9e9e9e">
                        SELECCIONE LA PERSONA A VISUALIZAR
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="!lastItems || lastItems.length === 0" class="full-height">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-row>
                    <v-col class="text-center">
                      <v-icon x-large color="grey" style="font-size: 70px">
                        mdi-account-circle-outline
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- -------------------- NUMERO DE ENTRADAS, SALIDAS Y GRAFICO DE DENCIDAD -------------------- -->
          <v-col cols="12">
            <v-card elevation="24" class="full-height">
              <v-row class="ml-10 mr-10">
                <v-col cols="12" md="6" class="text-center pa-0">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold"
                          >Accesos
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="primary--text pr-0 mr-0 text-h4">{{
                            dailyEntries.length
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-0">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold"
                          >Salidas
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="red--text pr-0 mr-0 text-h4">{{
                            dailyExits.length
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row class="text-center mx-3">
                <v-col
                  cols="12"
                  class="py-0 px-2 mb-0"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="rangeDates"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        label="Intervalo de fechas"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="rangeDates"
                      no-title
                      scrollable
                      range
                      locale="es-MX"
                      :max="todayDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="datePickerAction"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>

              <v-row v-if="entries && entries.length > 0">
                <v-col cols="12" class="d-flex justify-center align-center chart-area">
                  <div style="width: 100%" class="mx-4">
                    <dash-chart
                      style="height: 250px !important ; width: 100%  !important ; position: 'relative' !important"
                      label="Ingresos"
                      :rangeDataTime="rangeDataType"
                      :chartData="{
                          shortUnitMeasure: 'Pers',
                          entries: dailyEntries,
                          exits: dailyExits,
                          color: colorHex,
                      }"
                    ></dash-chart>
                  </div>
                </v-col>
                <v-col cols="12" class="px-10">
                  <v-btn
                    color="success"
                    block
                    :disabled="rangeDates[0] !== rangeDates[1]"
                    @click="generateReport(itemsList)"
                  >
                    <v-icon dark> mdi-microsoft-excel </v-icon>
                    Generar reporte
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!entries || entries.length === 0">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-row>
                    <v-col class="text-center">
                      <v-icon
                        x-large
                        color="grey"
                        style="font-size: 70px"
                      >
                        mdi-chart-box-outline
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- <v-row class="ml-10 mr-10 text-center">
                <v-col cols="12" class="pt-0 mt-0">
                  <v-layout child-flex>
                    <v-data-table
                      :headers="headers"
                      :items="itemsList"
                      :items-per-page="5"
                      :header-props="{
                        mobile: true,
                      }"
                      :footer-props="{
                        'items-per-page-options': [5],
                      }"
                      class="elevation-1"
                      :loading="loadingReport"
                      loading-text="Cargando Información..."
                      no-data-text="No hay datos que mostrar"
                      dense
                      fixed-header
                    >
                      <template #[`item.DocumentoUsuario`]="{ item }">
                        {{ item.person.document_number }}
                      </template>
                      <template #[`item.Nombre`]="{ item }">
                        {{ item.person.first_name + " " + item.person.first_last_name + " " + item.person.second_last_name}}
                      </template>
                      <template #[`item.Evento`]="{ item }">
                        {{ item.mode === 'ENTRY' ? 'ACCESO' : 'SALIDA' }}
                      </template>
                      <template v-slot:[`item.FechaIngreso`]="{ item }">
                        {{ item.date.substring(0, 10) }}
                      </template>
                      <template #[`item.HoraIngreso`]="{ item }">
                        {{ item.time.substring(11, 16) }}
                      </template>
                    </v-data-table>
                  </v-layout>
                  <v-btn
                    color="success"
                    block
                    disabled
                    @click="generateReport(itemsList)"
                  >
                    <v-icon dark> mdi-microsoft-excel </v-icon> EXPORTAR
                    DATOS
                  </v-btn>
                </v-col>
              </v-row> -->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- -------------------- LISTADO DE ENDRADAS Y SALIDAS -------------------- -->
      <v-col cols="12" md="5">
        <v-card elevation="24" class="full-height px-4">
          <v-card-title
            v-if="lastItems && lastItems.length > 0"
            class="d-flex justify-center font-weight-light"
            >ACCESOS</v-card-title
          >
          <v-row v-if="!lastItems || lastItems.length === 0 && loadingEntries === true" class="mx-3">
              <v-col v-for="(item, i) in 12" :key="i" cols="12" md="6" class="pa-1 d-flex justify-center">
                <v-skeleton-loader
                    class="pr-5"
                    transition="scale-transition"
                    width="400"
                    height="100"
                    type="list-item-avatar-three-line"
                ></v-skeleton-loader>
              </v-col>
          </v-row>
          <v-row v-if="!lastItems || lastItems.length === 0 && loadingEntries === false" class="full-height">
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-row>
                <v-col class="text-center">
                  <v-icon
                    x-large
                    color="grey"
                    style="font-size: 70px"
                  >
                    mdi-account-multiple-outline
                  </v-icon>
                  <p class="text-h6 mt-5" style="color: #9e9e9e">AÚN NO HAY EVENTOS EL DÍA DE HOY</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row :class="lastItems.length > 15 ? 'cards-box' : ''" v-if="lastItems && lastItems.length > 0">
            <v-col cols="12" class="text-right py-0">
              <span class="font-weight-light">{{lastItems.length}}/<strong class="primary--text">{{dailyEntries.length + dailyExits.length}}</strong> </span>
            </v-col>
            <v-col
              v-for="(item, i) in lastItems"
              :key="i"
              cols="12"
              md="6"
              sm="6"
              class="pa-1"
            >
              <v-card outlined elevation="20" class="full-height d-flex align-center pointer" @click="selectPerson(item)">
                <v-card-text
                  class="pa-0 ma-0"
                >
                  <v-row>
                    <v-col cols="5" class="d-flex justify-center align-center ma-0 pa-0">
                      <v-avatar size="90" class="ml-3">
                        <v-img
                          class="brightness"
                          :src="getDailyImg(item)"
                          lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                        >
                        </v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="7" class="ma-0 pa-0">
                      <v-row>
                        <v-col cols="10" class="pa-0 ma-0 text-center">
                          <span class="text-body-2 mb-0 text-wrap">
                            {{
                              capitalizeName(item.person.first_name) +
                              " " +
                              capitalizeName(item.person.first_last_name)
                            }}
                          </span>
                        </v-col>
                        <v-col cols="10" class="pa-1 ma-0 text-center">
                          <span class="text-body-2 mb-0 text-wrap">
                            {{ item.person.document_number }}
                          </span>
                        </v-col>
                        <v-col cols="10" class="pa-1 ma-0 d-flex justify-center align-center">
                          <span class="text-body-2 mb-0 text-wrap text-center">
                            <v-icon small color="grey">
                            mdi-television
                          </v-icon>
                            {{ item.device && item.device.name ? item.device.name.toUpperCase() : 'S/N' }}
                          </span>
                        </v-col>
                        <v-col cols="10" class="pa-1 ma-0 d-flex justify-center align-center">
                          <v-chip small class="ma-0" :color="item.mode === 'ENTRY' ? 'primary' : 'red'" text-color="white">
                            <small>
                              {{ item.mode === "ENTRY" ? "INGRESO - " + item.date.slice(0, 10) + " / " + item.time.slice(11, item.time.length - 8) : "SALIDA - " + item.date.slice(0, 10) + " / " + item.time.slice(11, item.time.length - 8) }}
                            </small>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- -------------------------- -->
            <v-col cols="12" class="text-center pa-0 ma-0" v-if="initialGetEntryAmount < entries.length">
              <v-btn
                color="primary"
                small
                outlined
                @click="loadingBtn = true ; initialGetEntryAmount = initialGetEntryAmount + 20 ; getDailyEntries()"
                :loading="loadingBtn"
                class="ma-1"
              >
                Cargar Más
              </v-btn>
            </v-col>
          </v-row>
          <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import DashChart from "../components/charts/dash-chart"
import auth from "../services/auth";
import xlsx from "xlsx";
import backlog from "../services/logs";

export default {
  name: "Dashboard",
  components: {
    DashChart,
  },
  data() {
    return {
      dialog: false,
      modal: false,
      personSelected: null,
      PSDailyImg: "",
      PSRegistryImg: "",
      registerImgBackup: "",
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      company_campus: null,
      registries: [],
      lastItems: [],
      itemsList: [],
      intervall: "",
      rangeDates: [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)],
      todayDate: globals.COdate().substr(0, 10),
      colorHex: "#4FC3F7",
      entries: [],
      dailyEntries: [],
      dailyExits: [],
      loadingEntries: false,
      loadingReport: false,
      loadingBtn: false,
      loadingSearch: false,
      initialGetEntryAmount: 10,
      rangeDataType: "hour",
      headers: [
        { text: "CC", value: "DocumentoUsuario", sortable: false },
        { text: "Nombre", value: "Nombre", sortable: false },
        { text: "Evento", value: "Evento", sortable: false },
        { text: "Fecha", value: "FechaIngreso", sortable: false },
        { text: "Hora", value: "HoraIngreso", sortable: false
         },
      ],
    };
  },
  computed: {
    dateRangeText () {
      return this.rangeDates.join(' ~ ')
    },
  },
  methods: {
    imgErrorHandler(url) {
      console.log("Handler de imagen de registro", url)
      this.PSRegistryImg = this.registerImgBackup//"https://847395.smushcdn.com/1790738/wp-content/uploads/2015/09/imagen-no-encontrada.jpg?lossy=0&strip=1&webp=1"
      this.$forceUpdate()
    },

    datePickerAction() {
      this.$refs.dialog.save(this.rangeDates)
      this.modal = false
      this.getEntriesToExport()
    },

    getDailyEntries() {
      axios
        .post(globals.APIURL + "entry/fbd", {
          campus_id: auth.getSelectedSede(),
          company_id: auth.getUserEnterpriseID(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          amount: this.initialGetEntryAmount
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.lastItems = res.data
            this.loadingEntries = false
            this.loadingBtn = false
            this.loadingSearch = false
          }
          this.loadingEntries = false
          this.loadingBtn = false
          this.loadingSearch = false
        })
        .catch((err) => {
          this.loadingEntries = false
          this.loadingBtn = false
          this.loadingSearch = false
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        });
    },

    getByDates() {
      axios
        .post(globals.APIURL + "entry/fabd", {
          campus_id: auth.getSelectedSede(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z"
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            var entryCounter = [];
            var exitCounter = [];
            res.data.forEach((element) => {
              if (element.mode === 'ENTRY') {
                entryCounter.push(element);
              } else {
                exitCounter.push(element);
              }
            });
            this.entries = res.data;
            this.itemsList = res.data;
            (this.rangeDates[0] === this.rangeDates[1]) ? this.rangeDataType = "hour" : this.rangeDataType = "day"
            this.dailyEntries = entryCounter;
            this.dailyExits = exitCounter;
          }
        })
        .catch((err) => {
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        });
    },

    getEntriesToExport() {
      if (this.rangeDates[1] >= this.rangeDates[0]) {
        this.loadingSearch = true
        this.loadingEntries = true
        this.lastItems = []
        this.entries = [];
        this.dailyEntries = [];
        this.dailyExits = [];
        this.itemsList = [];
        axios
          .post(globals.APIURL + "entry/fbd", {
            campus_id: auth.getSelectedSede(),
            start_date: this.rangeDates[0] + "T00:00:00.000Z",
            finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.itemsList = res.data;
              this.getByDates()
              this.getDailyEntries()
            }
          })
          .catch((err) => {
            this.loadingSearch = false
            console.log(err);
          });
      } else {
        this.snackbarText = "La fecha final no puede ser menor a la inicial.";
        this.snackbarColor = "red";
        this.snackbar = true;
        this.rangeDates[1] = "";
      }
    },

    getRegistry() {
      axios
        .post(globals.APIURL + "registry/fal", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.registries = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    generateReport(jsonContent) {
      // TODO: ORGANIZAR LOGICA PARA REPORTE DE AUSENCIAS.
      // ** Logica para organizar datos de reporte de accesos y salidas
      let inAndOutpersons = []

      jsonContent.forEach((element) => {
          let inAndOutPersonIndex = inAndOutpersons.findIndex(item => item.person_id === element.person_id)

          if (inAndOutPersonIndex === -1) {
            inAndOutpersons.push(element)
          } else if (inAndOutPersonIndex >= 0 && element.mode === "EXIT") {
            inAndOutpersons[inAndOutPersonIndex].time_exit = element.time
          } 
      });
      
      // ** Logica para generar las aucensias
      inAndOutpersons.forEach((el) => {
          let registryIndex = this.registries.findIndex(item => item.uuid === el.person_id)
          if (registryIndex >= 1) {
            this.registries.splice(registryIndex, 1 );
          }
      });

      inAndOutpersons.forEach(element => {
        element.DocumentoUsuario = element.person.document_number;
        element.Nombre = element.person.first_last_name + " " + element.person.second_last_name + " " + element.person.first_name
        element.Fecha = element.date.substring(0, 10)
        element.HoraEntrada = (element.time) ? element.time.substring(11, 16) : "NO REGISTRA"
        element.HoraSalida = (element.time_exit) ? element.time_exit.substring(11, 16) : "NO REGISTRA"
        element.HorasTrabajadas = (element.time && element.time_exit) ? parseInt(element.time_exit.substring(11, 13)) - parseInt(element.time.substring(11, 13)) : "NO SE PUEDE CALCULAR"
        delete element.uuid;
        delete element.campus_id;
        delete element.date;
        delete element.person_id;
        delete element.time;
        delete element.time_exit;
        delete element.person;
        delete element.campus;
        delete element.temp;
        delete element.mode;
        delete element.image;
        delete element.created_at;
        delete element.device_id;
        delete element.device;
        delete element.vehicles;
      });

      this.registries.forEach(element => {
        element.regDocumentoUsuario = element.document_number;
        element.regNombre = element.first_last_name + " " + element.second_last_name + " " + element.first_name
        delete element.uuid
        delete element.email
        delete element.first_name
        delete element.second_name
        delete element.first_last_name
        delete element.second_last_name
        delete element.phone
        delete element.birthdate
        delete element.rh
        delete element.gender
        delete element.RekognitionId
        delete element.document_type
        delete element.document_number
        delete element.updated_at
        delete element.created_at
        delete element.company_person
        delete element.vehicles
      });


      // ** Formato para generacion de excel
      let newWB = xlsx.utils.book_new();
      if (inAndOutpersons.length > 0) {
        let newWS = xlsx.utils.json_to_sheet(inAndOutpersons, {
          header: [
            "DocumentoUsuario",
            "Nombre",
            "Fecha",
            "HoraEntrada",
            "HoraSalida",
            "HorasTrabajadas",
          ],
        });

        newWS["A1"].v = "CC";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "FECHA";
        newWS["D1"].v = "HORA ACCESO";
        newWS["E1"].v = "HORA SALIDA";
        newWS["F1"].v = "HORAS LABORADAS";

        xlsx.utils.book_append_sheet(newWB, newWS, "Ingresos & Salidas");
      }
      
      if (this.registries.length > 0) {
        let newWS2 = xlsx.utils.json_to_sheet(this.registries, {
          header: [
            "regDocumentoUsuario",
            "regNombre"
          ],
        });

        newWS2["A1"].v = "CC";
        newWS2["B1"].v = "NOMBRE";

        xlsx.utils.book_append_sheet(newWB, newWS2, "Ausencias");
      }

      xlsx.writeFile(
        newWB,
        "Reporte VS-" + this.rangeDates[0] + ".xlsx",
        {
          type: "file",
          bookType: "xlsx",
        }
      );

      this.snackbarText = "Se descargó su reporte de ingresos.";
      this.snackbarColor = "success";
      this.snackbar = true;
      backlog.generateLog({
        enterprise: auth.getUserEnterpriseName(),
        username: auth.getUsername(),
        role: auth.getRoleName(),
        action: "export/entries",
      });
    },

    selectPerson(item) {
      this.personSelected = null
      if (item) {
        this.personSelected = item
        this.PSRegistryImg = ""
        this.registerImgBackup = ""
        console.log(item)
        if (item.person.register_image) {
          this.PSRegistryImg = globals.APIURL + "entry/registry-pic/" + item.person.register_image;
        } else {
          this.PSRegistryImg = globals.APIURL + "entry/entry-picture/" + auth.getUserEnterpriseName() + "/" + item.person.first_name + "/" + item.person.first_last_name + "/" + item.person.document_number;
        }
        
        this.PSDailyImg = globals.APIURL + "entry/daily-pic/" + item.image[0];
        if (item.person.entries.length > 0) {
          this.registerImgBackup = globals.APIURL + "entry/daily-pic/" + item.person.entries[0].image[0];
        }
      }
    },

    getDailyImg(item) {
      var formatURL = "";
      if (item) {
        formatURL = globals.APIURL + "entry/daily-pic/" + item.image[0];
      }
      return formatURL;
    },

    getRegistryImg(item) {
      let formatURL = "";
      if (item) {
        formatURL =
          globals.APIURL +
          "entry/entry-picture/" +
          auth.getUserEnterpriseName() +
          "/" +
          item.person.first_name +
          "/" +
          item.person.first_last_name +
          "/" +
          item.person.document_number;
      }
      return formatURL;
    },


    goToDetailedProfile(personSelected) {
      this.$router.replace({ name: 'registrations', params: { cedula_registro: personSelected.person } });
    },

    callEntriesEvery() {
      this.intervall = window.setInterval(
        function () {
          this.getDailyEntries();
          this.getByDates()
        }.bind(this),
        20000
      );
    },

    capitalizeName(name) {
      return name.charAt(0) + name.slice(1).toLowerCase();
    },
  },
  mounted() {
    this.loadingEntries = true
    this.getDailyEntries();
    this.getByDates()
    this.callEntriesEvery()
    this.getRegistry()
  },
  beforeDestroy() {
    clearInterval(this.intervall);
  },
};
</script>

<style>
.chart-area {
  height: 100%;
  width: 100%;
}

.full-height {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.card-body-success {
  border-style: groove;
  border-width: 0px 3px 3px 3px;
  border-color: #8bc34a;
}

.card-body-danger {
  border-style: groove;
  border-width: 5px 5px 5px 5px;
  border-color: #f44336;
}

.brightness {
  filter: brightness(1.2);
}
.cards-box {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1b7; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>