const logout = () => {
    localStorage.removeItem('access-token');
    localStorage.removeItem('id');
    localStorage.removeItem('enterpriseID');
    localStorage.removeItem('enterpriseName');
    localStorage.removeItem('username');
    localStorage.removeItem('name');
    localStorage.removeItem('lastname');
    localStorage.removeItem('role');
    localStorage.removeItem('role-name');
    localStorage.removeItem('selected-sede');
    localStorage.removeItem('modules');
    localStorage.removeItem('sede-name');
};

const loggedIn = () => {
    return localStorage.getItem('access-token') !== null;
};

const getUserID = () => {
    return localStorage.getItem('id');
};

const getUserEnterpriseID = () => {
    return localStorage.getItem('enterpriseID');
};

const getUserEnterpriseName = () => {
    return localStorage.getItem('enterpriseName');
};

const getUsername = () => {
    return localStorage.getItem('username');
};

const getName = () => {
    return localStorage.getItem('name');
};

const getLastname = () => {
    return localStorage.getItem('lastname');
};

const getRole = () => {
    return localStorage.getItem('role');
};

const getRoleName = () => {
    return localStorage.getItem('role-name');
};

const getSelectedSede = () => {
    return localStorage.getItem('selected-sede');
};

const getModules = () => {
    return localStorage.getItem('modules');
};

const getSedeName = () => {
    return localStorage.getItem('sede-name');
};

export default { logout, loggedIn, getUserID, getUserEnterpriseID, getUserEnterpriseName, getUsername, getName, getLastname, getRole, getRoleName, getSelectedSede, getModules, getSedeName }