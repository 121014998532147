<template>
  <v-container fluid>
    <v-card elevation="24" class="full-height">
      <v-row>
        <v-col cols="6">
          <div class="d-flex align-center justify-center pt-0 text-subtitle-2">
            Temperatura Actual
          </div>
          <div class="d-flex align-center justify-center">
            <v-card elevation="5" class="px-4 py-2">
              <div
                class="d-flex align-center justify-center text-h6 blue--text"
              >
                {{ lastestData.temp }} °C
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-center justify-center pt-0 text-subtitle-2">
            Humedad Actual
          </div>
          <div class="d-flex align-center justify-center">
            <v-card elevation="5" class="px-4 py-2">
              <div
                class="d-flex align-center justify-center text-h6 blue--text"
              >
                {{ lastestData.humedad }}%
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="d-flex align-center justify-center pt-0">
          <line-chart
            class="graphic-container"
            label="Temperatura"
            :chartData="{
              shortUnitMeasure: '°C',
              allData: allData,
              colorHex: colorTempHex,
              colorRGB: colorTempRGB
            }"
          ></line-chart>
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-center pt-0">
          <line-chart
            class="graphic-container"
            label="Humedad"
            :chartData="{
              shortUnitMeasure: '%',
              allData: allData,
              colorHex: colorHumHex,
              colorRGB: colorHumRGB,
            }"
          ></line-chart>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <div class="d-flex align-center justify-center pt-0 text-subtitle-2">
            PM 1
          </div>
          <div class="d-flex align-center justify-center">
            <v-card elevation="5" class="px-4 py-2">
              <div
                class="d-flex align-center justify-center text-h6 blue--text"
              >
                {{ lastestData.pm1 }}
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="d-flex align-center justify-center pt-0 text-subtitle-2">
            PM 2.5
          </div>
          <div class="d-flex align-center justify-center">
            <v-card elevation="5" class="px-4 py-2">
              <div
                class="d-flex align-center justify-center text-h6 blue--text"
              >
                {{ lastestData.pm25 }}
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="d-flex align-center justify-center pt-0 text-subtitle-2">
            PM 10
          </div>
          <div class="d-flex align-center justify-center">
            <v-card elevation="5" class="px-4 py-2">
              <div
                class="d-flex align-center justify-center text-h6 blue--text"
              >
                {{ lastestData.pm10 }}
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" align-self="center">
          <div class="d-flex align-center justify-center pt-0">
            ICA (Indice Calida del Aire)
          </div>
          <div class="d-flex align-center justify-center text-h4 pt-4">
            {{largerPm}}
          </div>
          <div class="d-flex align-center justify-center pt-4">
            <v-card
              elevation="5"
              class="px-4 py-2 mr-2"
              :class="ICA1 == true ? 'green white--text' : ''"
            >
              <div class="d-flex align-center justify-center text-h5">BIEN</div>
            </v-card>
            <v-card
              elevation="5"
              class="px-4 py-2 mr-2"
              :class="ICA2 == true ? 'amber lighten-2 white--text' : ''"
            >
              <div class="d-flex align-center justify-center text-h5">
                MODERADO
              </div>
            </v-card>
            <v-card
              elevation="5"
              class="px-4 py-2"
              :class="ICA3 == true ? 'red white--text' : ''"
            >
              <div class="d-flex align-center justify-center text-h5">
                GRAVE
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-center pt-0">
          <multi-line-chart
            class="graphic-container"
            label="Materia Particulada"
            :chartData="{
              datasetLabels: ['PM 1', 'PM 2.5', 'PM 10'],
              allData: allData
            }"
          ></multi-line-chart>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../../globals";
import LineChart from "../../components/charts/line-chart";
import MultiLineChart from "../../components/charts/multi-line-chart";
import auth from "../../services/auth";

export default {
  name: "DashIOT",
  components: {
    LineChart,
    MultiLineChart,
  },
  data() {
    return {
      intervall: "",
      organizationName: auth.getUserEnterpriseName(),
      ICA1: false,
      ICA2: false,
      ICA3: false,
      lastestData: Object,
      largerPm: 0,
      allData: [],
      colorTempHex: "#4FC3F7",
      colorTempRGB: "rgb(79, 195, 247, 0.2)",
      colorHumHex: "#e7717d",
      colorHumRGB: "rgb(231, 113, 125, 0.2)"
    }; 
  },
  methods: {
    doICA() {
      const lastestData = this.lastestData;
      this.largerPm = Math.max(lastestData.pm1, lastestData.pm25, lastestData.pm10);
      if (lastestData.pm25 >= 1 && lastestData.pm25 <= 12) {
        this.ICA1 = true;
        this.ICA2 = false;
        this.ICA3 = false;
      } else if (lastestData.pm25 >= 12.1 && lastestData.pm25 <= 55.5) {
        this.ICA1 = false;
        this.ICA2 = true;
        this.ICA3 = false;
      } else if (lastestData.pm25 > 55.5) {
        this.ICA1 = false;
        this.ICA2 = false;
        this.ICA3 = true;
      }
    },

    getData() {
      axios
        .post(globals.APIURL + "entry/iot", {
          enterpriseName: this.organizationName,
          StartDate: globals.DDMMYYYY(new Date(), "/"),
          FinishDate: globals.DDMMYYYY(new Date(), "/"),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.lastestData = res.data[res.data.length - 1];
            this.allData = res.data;
            this.doICA();
          }
        })
        .catch((err) => {
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        });
    },

    callDataEvery() {
      this.intervall = window.setInterval(
        function () {
          this.getData();
        }.bind(this),
        15000
      );
    },
  },
  mounted() {
    this.getData();
    this.callDataEvery();
  },
  beforeDestroy() {
    clearInterval(this.intervall);
  },
};
</script>

<style>
.graphic-container {
  position: relative;
  margin: auto;
  height: 35vh;
  width: 35vw;
}

.full-height {
  height: 100%;
}
</style>