<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <p class="black--text font-weight-bold text-h5">Histórico de Accesos</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="rangeDates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Intervalo de fechas"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="rangeDates"
                  no-title
                  scrollable
                  range
                  locale="es-MX"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="searchByDates"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar por palabra clave..."
                single-line
                hide-details
                outlined
                small
                class="mb-5"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1" class="text-center">
              <v-btn color="success" x-large block title="Exportar Historico" @click="generateReport" :disabled="entryList.length == 0">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="entryList"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  class="elevation-1"
                  fixed-header
                  :loading="loading"
                  loading-text="Cargando Información..."
                  :no-results-text="`No se encontrarón ingresos por su dato de busqueda (${search})`"
                  no-data-text="No hay ingresos que mostrar"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-body-2">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                              size="45"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-img
                                :src="getEntryPic(item)"
                                lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                              >
                              </v-img>
                            </v-avatar>
                          </template>
                          <v-img
                            :src="getEntryPic(item)"
                            width="100"
                            lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                          >
                          </v-img>
                        </v-tooltip>
                      </td>
                      <td class="text-body-2">{{
                          item.person.document_number
                        }}
                      </td>
                      <td class="text-body-2">
                        {{
                          item.person.first_name +
                          " " +
                          item.person.first_last_name +
                          " " +
                          item.person.second_last_name
                        }}
                      </td>
                      <td class="text-body-2">
                        <v-chip
                          class="small-chip ma-0"
                          :color="item.mode === 'ENTRY' ? 'primary' : 'red'"
                          text-color="white"
                          small
                        >
                          {{ item.mode === "ENTRY" ? "Acceso" : "Salida" }}
                        </v-chip>
                      </td>
                      <td class="text-body-2">
                        {{
                          item.date.substring(0, 10)
                        }} - {{
                          item.time.substring(11, 16)
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import roles from "../services/role";
import xlsx from "xlsx";
//import backlog from "../services/logs";

export default {
  name: "EntryHistory",
  // TODO: RekID Vacio '9e5bdaba-a9f6-4f26-b986-e3b06c1b409b' 
  data: () => ({
    loading: false,
    modal: false,
    roleChecker: roles,
    userRole: auth.getRole(),
    organizations: [],
    org: null,
    entryList: [],
    entryListReport: [],
    reportAlreadyCreated: 0,
    rangeDates: [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)],
    search: "",
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    headers: [
      { text: "Img. Acceso", sortable: false, align: "center", value: "ImgAccess" },
      { text: "CC", sortable: false, align: "center", value: "person.document_number" },
      { text: "Nombre", sortable: false, align: "center", value: "person.first_name" },
      { text: "Evento", sortable: false, align: "center", value: "mode" },
      { text: "Fecha - Hora", sortable: false, align: "center", value: "date" },
    ]
  }),
  computed: {
    dateRangeText () {
      return this.rangeDates.join(' ~ ')
    },
  },
  methods: {
    searchByDates() {
      this.$refs.dialog.save(this.rangeDates)
      this.modal = false
      this.getEntries()
    },
    getEntries() {
      this.loading = true
      this.entryList = []
      this.entryListReport = []
      this.reportAlreadyCreated = 0
      axios
        .post(globals.APIURL + "entry/fabd", {
          campus_id: auth.getSelectedSede(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z"
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.entryList = res.data;
            this.entryListReport = res.data
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        });
    },

    restartEntries() {
      axios
        .post(globals.APIURL + "entry/fabd", {
          campus_id: auth.getSelectedSede(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z"
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.entryList = res.data;
            this.entryListReport = res.data
            console.log(res.data)
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        });
    },

    getEntryPic(item) {
      var formatURL = "";
      if (item.person.document_number !== "") {
        formatURL = globals.APIURL + "entry/daily-pic/" + item.image[0];
      }
      return formatURL;
    },

    generateReport() {
      if (this.entryListReport.length > 0) {
        if (this.reportAlreadyCreated === 0) {
          this.entryListReport = this.entryListReport.filter(value => value.person.document_number !== "")

          this.entryListReport.forEach(element => {
            element.DocumentNumber = element.person.document_number
            element.FullName = element.person.first_last_name + " " + element.person.second_last_name + " " + element.person.first_name
            element.EntryDate = element.date.substring(0, 10)
            element.EntryTime = element.time.substring(11, 19)
            delete element.uuid
            delete element.person_id
            delete element.campus_id
            delete element.temp
            delete element.mode
            delete element.date
            delete element.time
            delete element.image
            delete element.created_at
            delete element.device_id
            delete element.person
            delete element.campus
            delete element.device
          });
          this.reportAlreadyCreated = this.reportAlreadyCreated + 1
        }

        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet(this.entryListReport, {
          header: [
            "DocumentNumber",
            "FullName",
            "EntryDate",
            "EntryTime"
          ],
        });

        newWS["A1"].v = "CC";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "FECHA";
        newWS["D1"].v = "HORA ACCESO";

        xlsx.utils.book_append_sheet(newWB, newWS, "Ingresos");

        xlsx.writeFile(
          newWB,
          "Reporte Vanguard Security Access de " + this.rangeDates[0] + " a " + this.rangeDates[1] + ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );
        
        this.snackbarText = "Se descargó su reporte de ingresos.";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.restartEntries()
        //backlog.generateLog({"enterprise": auth.getUserEnterprise(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export/entries"})
      }
    },

  },
  mounted() {
    this.getEntries()
  },
};
</script>
