<template>
  <v-app>
    <v-app-bar v-if="isAuth" app color="black">
      <v-app-bar-nav-icon class="hidden-md-and-up icons-color"
       @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!-- // TODO <div v-if="showMenu" class="ma-0 pa-0" style="visibility: hidden">
        <v-menu
        bottom
        origin="center center"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#BDBDBD"
            dark
            v-bind="attrs"
            v-on="on"
            outlined
            small
          >
            <div v-for="(item, i) in sedes" :key="i">
              {{ item.uuid == selectedSede ? item.name : '' }}
            </div>
            <v-icon class="ml-1">mdi-menu-down</v-icon>
          </v-btn>
        </template>
      </v-menu>
      </div> -->
      <v-toolbar-title class="pl-0" v-if="organizationName == 'conconcreto' || organizationName == 'demo'"
        >
        <v-img
          src="./assets/vt-nav-img.svg"
          max-height="100px"
          max-width="140px"
        ></v-img>
      </v-toolbar-title>

      <v-toolbar-title class="pl-0" v-if="organizationName != 'conconcreto' && organizationName != 'demo'"
        >
        <v-img
          v-if="vanguardAI"
          src="./assets/VanguardVisionAI.png" 
          max-height="100px"
          max-width="140px"
        ></v-img>
        <v-img
          v-if="vanguardPark"
          src="./assets/vvp.png" 
          max-height="150px"
          max-width="250px"
        ></v-img>
        <v-img
          v-if="vanguardAccess"
          src="./assets/vva.png" 
          max-height="150px"
          max-width="250px"
        ></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- // TODO <v-menu
        v-if="showMenu"
        bottom
        origin="center center"
        transition="slide-x-transition"
        width="250px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#BDBDBD"
            dark
            v-bind="attrs"
            v-on="on"
            outlined
            small
          >
            <div v-for="(item, i) in sedes" :key="i">
              {{ item.uuid == selectedSede ? item.name : '' }}
            </div>
            <v-icon class="ml-1">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in sedes"
            :key="i"
            style="cursor: pointer;"
            class="sede-menu"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-icon @click="doLogout()" title="Cerrar Sesion" class="pl-4 icons-color" large>mdi-logout</v-icon>
    </v-app-bar>

    <v-navigation-drawer class="navbar-bg" v-if="isAuth" app v-model="drawer" :permanent="$vuetify.breakpoint.mdAndUp" expand-on-hover color="black">
      <v-list-item class="px-2">
        <v-list-item-avatar class="d-flex justify-center" color="white">
            <span class="black--text text-h6">{{ getName('initials') }}</span>
          </v-list-item-avatar>

        <v-list-item-title>
          <div class="py-2 text-wrap icons-color">{{getName('normal')}}</div>
          <div class="grey--text">{{roleChecker.getRoleName(userRole)}}</div>
        </v-list-item-title>
      </v-list-item>
      <v-list nav dense v-if="showMenu">
        <v-list-item-group
          v-model="group"
          active-class="deep-blue--text text--accent-4"
        >
          <v-list-item class="pb-3" title="Sedes" v-if="organizationName !== 'conconcreto' && organizationName != 'demo'" @click="goTo('mainModule')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Sedes</v-list-item-title>
          </v-list-item>
          <v-list-item class="pb-3" title="Access" v-if="(modules == 1 || modules == 2 || modules == 3) && organizationName !== 'conconcreto' && organizationName != 'demo'" @click="goTo('dashboard')"><!-- organizationName !== 'conconcreto' && organizationName != 'demo' -->
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-face-recognition</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Access</v-list-item-title>
          </v-list-item>
          <v-list-item class="pb-3" title="Access" v-if="(modules == 1 || modules == 2 || modules == 3) && organizationName !== 'conconcreto' && organizationName != 'demo'" @click="goTo('entryHistory')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-text-box-search-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Histórico Access</v-list-item-title>
          </v-list-item>
          <v-list-item class="pb-3" title="Park" v-if="(modules == 2 || modules == 3) && roleChecker.iHaveGrants(userRole, 'MENU-CARS')" @click="goTo('dashCars')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-car-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Park</v-list-item-title>
          </v-list-item>
          <v-list-item class="pb-3" title="Registros" v-if="roleChecker.iHaveGrants(userRole, 'MENU-REG')" @click="goTo('registrations')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-badge-account-horizontal-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Registros</v-list-item-title>
          </v-list-item>
          <v-list-item class="pb-3" title="Control Nominal" v-if="roleChecker.iHaveGrants(userRole, 'MENU-PAYROLL')" @click="goTo('nominal')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-store-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Control Nominal</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item class="pb-5" v-if="roleChecker.iHaveGrants(userRole, 'MENU-FORM')" @click="goTo('forms')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-shield-bug</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Formularios</v-list-item-title>
          </v-list-item> -->
          <v-list-item class="pb-3" title="Dispositivos" v-if="roleChecker.iHaveGrants(userRole, 'MENU-DEVICES')" @click="goTo('devices')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-devices</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Dispositivos</v-list-item-title>
          </v-list-item>
          <v-list-item class="pb-3" title="Logs" v-if="roleChecker.iHaveGrants(userRole, 'MENU-LOGS')" @click="goTo('logs')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-format-list-checks</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Logs</v-list-item-title>
          </v-list-item>
          <v-list-item class="pb-3" title="Configuración" @click="goTo('configuration')">
            <v-list-item-icon class="d-flex justify-center">
              <v-icon class="icons-color" large>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icons-color">Configuración</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
/* // TODO import axios from "axios";
import globals from "./globals"; */
import auth from "./services/auth";
import roles from "./services/role";
import backlog from './services/logs'

export default {
  name: "App",
  components: {},
  data: () => ({
    drawer: false,
    group: null,
    isAuth: auth.loggedIn(),
    roleChecker: roles,
    userRole: auth.getRoleName(),
    username: auth.getUsername(),
    organizationName: auth.getUserEnterpriseName(),
    /* // TODO selectedSede: null,
    sedes: [], */
    showMenu: false,
    modules: null,
    vanguardAI: true, 
    vanguardPark: false, 
    vanguardAccess: false, 
  }),
  methods: {
    doLogout() {
      backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "logout"})
      auth.logout();
      this.isAuth = false;
      this.$router.push("/");
    },

    getName(value) {
      if (auth.getName() && auth.getLastname()) {
        if (value == 'initials') {
          return auth.getName().substr(0, 1) + auth.getLastname().substr(0, 1);
        } else {
          return auth.getName() + " " + auth.getLastname();
        }
      }
    },

    goTo(route) {
      if (route !== this.$route.name) {
        this.$router.replace({ name: route });
      }
    },

    /* // TODO getSedes() {
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID()
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            this.selectedSede = auth.getSelectedSede()
            if (auth.getRoleName() !== 'SUPER') {
              for (let i = 0; i < res.data.Campus.length; i++) {
                const element = res.data.Campus[i];
                if (element.devices.length > 0) {
                  this.sedes.push(element)
                }
              }
            } else {
              this.sedes = res.data.Campus
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, */
  },
  created() {
    /* // TODO this.selectedSede = null
    this.sedes = []
    this.getSedes(); */
    this.$vuetify.theme.dark = false;
    
    this.$bus.$on("logged", () => {
      this.isAuth = auth.loggedIn();
      this.userRole =  auth.getRoleName()
      this.username = auth.getUsername();
      this.organizationName = auth.getUserEnterpriseName();
    });
  },
  updated() {
    this.modules = auth.getModules()
  },
  beforeUpdate() {
    this.showMenu = false;
    if (this.$route.name === "dashCars") {
      this.vanguardAI = false
      this.vanguardPark = true
      this.vanguardAccess = false
    } else if (this.$route.name === "dashboard") {
      this.vanguardAI = false
      this.vanguardPark = false
      this.vanguardAccess = true
    } else {
      this.vanguardAI = true
      this.vanguardPark = false
      this.vanguardAccess = false
    }
    if (this.$route.name !== 'mainModule' && this.$route.name !== 'generalDash') {
      this.showMenu = true;
    }
  }
};
</script>
<style>
.sede-menu:hover {
  background-color: #E8E8E8;
}
.avatar-text {
  color: #b5720b !important
}

.icons-color {
  color: #BDBDBD !important;
}

.navbar-bg {
  background-color: #191919;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before, .theme--dark.v-list-item::before {
  opacity: 0 !important;
}
</style>
