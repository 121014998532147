<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  data() {
    return {
      intervalChart: "",
      dataLabels: Array,
      dataValues: Array
    };
  },
  props: {
    label: {
      type: String,
    },
    chartData: {
      type: Object,
    },
  },
  methods: {
    makeChart() {
        const unitMeasure = this.chartData.shortUnitMeasure;
        this.dataLabels = this.chartData.allData.map(t => t.time);
        if (unitMeasure == "%") {
            this.dataValues = this.chartData.allData.map(t => t.humedad);
        } else if (unitMeasure == "°C") {
            this.dataValues = this.chartData.allData.map(t => t.temp);
        }

        this.renderChart(
        {
          labels: this.dataLabels, 
          datasets: [
            {
              label: this.label,
              data: this.dataValues,
              borderColor: this.chartData.colorHex,
              backgroundColor: this.chartData.colorRGB,
              fill: true,
              pointStyle: 'line'
            },
          ],
        },
        {
          maintainAspectRatio: false,
          animation: {
            duration: 0, // general animation time
          },
          hover: {
            animationDuration: 0, // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0,
          responsive: true,
          elements: {
            line: {
              tension: 0.4,
            },
          },
          legend: {
            labels: {
              fontColor: "#363636",
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor:  "#363636",
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  fontColor:  "#363636",
                  labelString:
                    this.label +
                    " (" +
                    this.chartData.shortUnitMeasure +
                    ")",
                },
                ticks: {
                  fontColor:  "#363636",
                  beginAtZero: false,
                  callback: function (value) {
                    return value + " " + unitMeasure ;
                  },
                },
              },
            ],
          },
          title: {
            display: false
          },
          tooltips: {
            enable: true,
            mode: "single",
            callbacks: {
              title: function (tooltipItems) {
                return "Hora: " + tooltipItems[0].xLabel;
              },
            },
          },
        }
      );
    },

    callDataChartEvery() {
      this.intervalChart = window.setInterval(
        function () {
          this.makeChart();
        }.bind(this),
        3000
      );
    },
  },
  mounted() {
    this.callDataChartEvery();
    this.makeChart();
  },
  beforeDestroy() {
    clearInterval(this.intervalChart);
  },
};
</script>
