<template>
  <v-container class="all-screen">
    <v-row class="all-screen d-flex align-center justify-center">
      <v-col cols="12" md="6">
        <v-card elevation="24">
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <v-img class="login-img"
                  max-height="200"
                  src="../assets/vvai.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="USUARIO"
                  v-model="userCredentials.username"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :append-icon="showPasswordIcon ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPasswordIcon ? 'text' : 'password'"
                  name="input-10-2"
                  label="CONTRASEÑA"
                  class="input-group--focused"
                  @click:append="showPasswordIcon = !showPasswordIcon"
                  v-model="userCredentials.password"
                  v-on:keyup.enter="logIn(userCredentials)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  outlined
                  class="text-uppercase"
                  color="primary darken-3"
                  @click="logIn(userCredentials)"
                  >Iniciar Sesión</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <small>© v1.22.0 by Vanguard Technologies</small>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-snackbar v-model="snackbar" timeout="4000" :color="snackbarColor">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import auth from "../services/auth";
import globals from '../globals';
import backlog from '../services/logs'

export default {
  name: "LogInComponent",
  data: () => ({
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    showPasswordIcon: false,
    isLogged: auth.loggedIn(),
    userCredentials: {
      username: "",
      password: "",
    },
  }),
  methods: {
    logIn(userCredentials) {
      if (userCredentials.username != "" && userCredentials.password != "") {
        var filter = {
          username: userCredentials.username,
          password: userCredentials.password,
          filter: {
            username: userCredentials.username
          }
        }

        axios
          .post(globals.APIURL + "auth/signin", filter)
          .then((res) => {
            if (res.data.token && res.data.user) {
              backlog.generateLog({"enterprise": res.data.user.enterprise.name, "username": res.data.user.username, "role": res.data.user.role.name, "action": "login"})
              localStorage.setItem("access-token", res.data.token);
              localStorage.setItem("id", res.data.user.id);
              localStorage.setItem("enterpriseID", res.data.user.enterprise.uuid);
              localStorage.setItem("enterpriseName", res.data.user.enterprise.name);
              localStorage.setItem("username", res.data.user.username);
              localStorage.setItem("name", res.data.user.name);
              localStorage.setItem("lastname", res.data.user.lastname);
              localStorage.setItem("role", res.data.user.role.uuid);
              localStorage.setItem("role-name", res.data.user.role.name);
              userCredentials.username = "";
              userCredentials.password = "";
              this.$bus.$emit('logged', 'User Logged');
              if (res.data.user.role.name == "IOT") {
                this.$router.replace({name: "dashIOT"})
              } else if (res.data.user.role.name == "IMG") { 
                this.$router.replace({name: "dashImgs"})
              } else {
                this.$router.replace({name: "mainModule"})
                //this.$router.replace({name: "dashboard"})
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.userCredentials.username = "";
            this.userCredentials.password = "";
            this.snackbarText = "Las credenciales son incorrectas, intentelo nuevamente.";
            this.snackbarColor = "red";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Los campos son obligatorios.";
        this.snackbarColor = "red";
        this.snackbar = true;
      }
    },
  },
};
</script>

<style>
.all-screen {
  height: 100%;
}

.login-img {
  object-fit: cover;
}
</style>