<template>
  <v-container fluid>
    <div v-if="dialogDash">
      <v-dialog v-model="dialogDash" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
              <v-toolbar color="primary">
                <v-toolbar-title class="white--text">Visualización Detallada</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="dialogDash = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>
                <v-card-text>
                    <dash-report :groupsList="groups"></dash-report>
                </v-card-text>
          </v-card>
      </v-dialog>
    </div>

    <div v-if="dialog">
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="text-h5"> Creación de Grupo</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="newWG.name"
                  label="Nombre del Grupo"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="newWG.cost_center"
                  :items="costCenters"
                  return-object
                  label="Centro de Costos"
                  :filter="customFilterCostCenter"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span>{{
                      item.code + " - " + item.name
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{
                      item.code + " - " + item.name
                    }}</span>
                  </template>
                </v-autocomplete>
              </v-col> -->
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newWG.start_hour"
                      label="Hora desde"
                      prepend-inner-icon="mdi-clock-check-outline"
                      readonly
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="newWG.start_hour"
                    full-width
                    @click:minute="$refs.menu2.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time2"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newWG.finish_hour"
                      label="Hora hasta"
                      prepend-inner-icon="mdi-clock-remove-outline"
                      readonly
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="newWG.finish_hour"
                    full-width
                    @click:minute="$refs.menu3.save(time2)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex align-center justify-center">
                  <label class="text-subtitle-1">Horas Extra</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="newWG.extra_hours" class="ml-4" color="primary" inset dense></v-switch>
                </div>
                <div>
                    <v-text-field
                        v-if="newWG.extra_hours === true"
                        v-model="newWG.extra_hours_allowed"
                        label="H. extra permitidas"
                        type="number"
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex align-center justify-center">
                  <label class="text-subtitle-1">Festivos</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="newWG.festive_hours" class="ml-4" color="primary" ripple inset dense></v-switch>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex align-center justify-center">
                  <label class="text-subtitle-1">Dominicales</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="newWG.sunday_hours" class="ml-4" color="primary" ripple inset dense></v-switch>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex align-center justify-center">
                  <label class="text-subtitle-1">Nocturnas</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="newWG.night_hours" class="ml-4" color="primary" ripple inset dense></v-switch>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="ctWeekOpc">
                  <div>
                    <input v-model="newWG.workDays[0]" type="checkbox" id="lun" />
                    <label for="lun">L</label>
                  </div>
                  <div>
                    <input v-model="newWG.workDays[1]" type="checkbox" id="mar" />
                    <label for="mar">M</label>
                  </div>
                  <div>
                    <input v-model="newWG.workDays[2]" type="checkbox" id="mir" />
                    <label for="mir">M</label>
                  </div>
                  <div>
                    <input v-model="newWG.workDays[3]" type="checkbox" id="jue" />
                    <label for="jue">J</label>
                  </div>
                  <div>
                    <input v-model="newWG.workDays[4]" type="checkbox" id="vie" />
                    <label for="vie">V</label>
                  </div>
                  <div>
                    <input v-model="newWG.workDays[5]" type="checkbox" id="sab" />
                    <label for="sab">S</label>
                  </div>
                  <div>
                    <input v-model="newWG.workDays[6]" type="checkbox" id="dom" />
                    <label for="dom">D</label>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn color="primary" text @click="createGroup(newWG)">
              Crear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="dialogEdit">
      <v-dialog v-model="dialogEdit" persistent max-width="600">
        <v-card>
          <v-card-title class="text-h5"> Creación de Grupo</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="groupSelected.name"
                  label="Nombre del Grupo"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="groupSelected.cost_center"
                  :items="costCenters"
                  return-object
                  label="Centro de Costos"
                  :filter="customFilterCostCenter"
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <span>{{
                      item.code + " - " + item.name
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{
                      item.code + " - " + item.name
                    }}</span>
                  </template>
                </v-autocomplete>
              </v-col> -->
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="groupSelected.start_hour"
                      label="Hora desde"
                      prepend-inner-icon="mdi-clock-check-outline"
                      readonly
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="groupSelected.start_hour"
                    full-width
                    @click:minute="$refs.menu2.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time2"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="groupSelected.finish_hour"
                      label="Hora hasta"
                      prepend-inner-icon="mdi-clock-remove-outline"
                      readonly
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="groupSelected.finish_hour"
                    full-width
                    @click:minute="$refs.menu3.save(time2)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex align-center justify-center">
                  <label class="text-subtitle-1">Horas Extra</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="groupSelected.overtime" class="ml-4" color="primary" inset dense></v-switch>
                </div>
                <div>
                    <v-text-field
                        v-if="groupSelected.overtime === true"
                        v-model="groupSelected.max_overtime"
                        label="H. extra permitidas"
                        type="number"
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex align-center justify-center">
                  <label class="text-subtitle-1">Festivos</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="groupSelected.festive_overtime" class="ml-4" color="primary" ripple inset dense></v-switch>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex align-center justify-center">
                  <label class="text-subtitle-1">Dominicales</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="groupSelected.sunday_overtime" class="ml-4" color="primary" ripple inset dense></v-switch>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex align-center justify-center">
                  <label class="text-subtitle-1">Nocturnas</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="groupSelected.night_overtime" class="ml-4" color="primary" ripple inset dense></v-switch>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="ctWeekOpc">
                  <div>
                    <input v-model="groupSelected.work_days[0]" type="checkbox" id="lun" />
                    <label for="lun">L</label>
                  </div>
                  <div>
                    <input v-model="groupSelected.work_days[1]" type="checkbox" id="mar" />
                    <label for="mar">M</label>
                  </div>
                  <div>
                    <input v-model="groupSelected.work_days[2]" type="checkbox" id="mir" />
                    <label for="mir">M</label>
                  </div>
                  <div>
                    <input v-model="groupSelected.work_days[3]" type="checkbox" id="jue" />
                    <label for="jue">J</label>
                  </div>
                  <div>
                    <input v-model="groupSelected.work_days[4]" type="checkbox" id="vie" />
                    <label for="vie">V</label>
                  </div>
                  <div>
                    <input v-model="groupSelected.work_days[5]" type="checkbox" id="sab" />
                    <label for="sab">S</label>
                  </div>
                  <div>
                    <input v-model="groupSelected.work_days[6]" type="checkbox" id="dom" />
                    <label for="dom">D</label>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancelEditMode(groupSelected)">
              Cancelar
            </v-btn>
            <v-btn color="success" text @click="editWG(groupSelected)">
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="dialog2">
      <v-dialog v-model="dialog2" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Agregar Empleados</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="peopleToAsign"
                    :items="registry"
                    return-object
                    label="Empleados"
                    :filter="customFilter"
                    dense
                    chips
                    small-chips
                    multiple
                    solo
                    outlined
                  >
                  <template v-slot:selection="item">
                    <v-chip v-bind="item.attrs" close class="ma-1" :input-value="item.selected" @click="item.select" @click:close="remove(item.item)">
                      {{
                        item.item.first_name +
                        " " +
                        (item.item.second_name ? item.item.second_name + " " : '')
                        + item.item.first_last_name +
                        " " +
                        item.item.second_last_name
                      }}
                    </v-chip>
                  </template>
                  <template v-slot:item="item">
                      <v-list-item-content>
                        <v-list-item-title>{{
                        item.item.first_name +
                        " " +
                        (item.item.second_name ? item.item.second_name + " " : '')
                        + item.item.first_last_name +
                        " " +
                        item.item.second_last_name
                      }}</v-list-item-title>
                      </v-list-item-content>
                </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog2 = false"> Cancelar </v-btn>
            <v-btn color="blue darken-1" text @click="asignPeople(peopleToAsign)"> Agregar Personas </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="dialogDelete">
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>
                <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                        <label class="text-h6 text-center">Está seguro que desea remover esta persona del grupo?</label>
                    </v-col>
                </v-row>
            </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialogDelete = false ; personToRemove = null"
              >Cancelar</v-btn
            >
            <v-btn color="success darken-1" text @click="removeEmployee(personToRemove)"
              >Si, confirmar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="dialogEventuality">
      <v-dialog v-model="dialogEventuality" max-width="500">
        <v-card>
          <v-card-title class="text-h5"
            >Generar eventualidad</v-card-title
          >
          <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="datepicker1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="newEventuality.date"
                                label="Fecha eventualidad"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="newEventuality.date"
                            @input="datepicker1 = false"
                            :max="nowDate"
                            locale="es-MX"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            v-model="newEventuality.description"
                            name="input-7-1"
                            filled
                            label="Descripción del permiso o eventualidad."
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialogEventuality = false"
                >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="createEventuality(personSelected, newEventuality)"
                >Crear</v-btn
                >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="deleteGroupModal">
      <v-dialog v-model="deleteGroupModal" max-width="500px">
        <v-card>
          <v-card-title>
                <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                        <label class="text-h6 text-center">Está seguro que desea eliminar este grupo de trabajo?</label>
                    </v-col>
                </v-row>
            </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="deleteGroupModal = false"
              >Cancelar</v-btn
            >
            <v-btn color="red darken-1" text @click="deleteWG()"
              >Si, confirmar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="dialogReport">
      <v-dialog v-model="dialogReport" max-width="50%">
        <export-report :allRegistry="allRegistry" :groups="groups" :costCenters="costCenters"></export-report>
      </v-dialog>
    </div>

    <v-row>
      <v-col cols="12">
        <p class="text-center text-h6">Registro Nominal</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="11" md="5" class="text-left my-0 pa-0">
        <v-text-field
            v-model="searchWG"
          :append-icon="searchWG ? 'mdi-close' : ''"
          label="Filtrar grupos por cédula trabajador"
          single-line
          hide-details
          class="ml-12 mr-1"
          outlined
          rounded
          dense
          @click:append="searchWG = '' ; getWorkingGroups()"
        ></v-text-field>
      </v-col>
      <v-col cols="11" md="1" class="text-left my-0 pa-0">
        <v-btn class="mr-12" rounded dark color="primary" @click="searchWGByPerson(searchWG)">
          <v-icon dark> mdi-magnify </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" class="text-right my-0 pa-0">
        <v-btn class="mr-2" rounded dark color="primary" @click="groupSelected = null ; campusData()">
          <v-icon dark> mdi-reload </v-icon>
        </v-btn>
          <v-btn class="mr-2" rounded color="success" @click="dialogReport = true" :disabled="registry.length == 0" :loading="loadingReportBtn">
            <v-icon dark> mdi-microsoft-excel </v-icon>
          </v-btn>
          <v-btn
                color="primary"
                rounded
                @click="dialogDash = true"
                :disabled="groups.length == 0"
            >
                <v-icon dark class="mx-1"> mdi-eye </v-icon> Visualización Detallada
            </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-right my-0 pa-0">
        <v-btn class="ml-6" rounded dark color="primary" @click="clearGroup() ; dialog = true">
          <v-icon dark> mdi-plus </v-icon> Crear Grupo
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="py-0 my-0">
      <v-col cols="12" class="my-0 py-0">
        <div class="ctctCardGroup py-2 pl-5" :class="!groups || groups.length === 0 ? 'd-flex justify-center' : ''">
            <p v-if="loading == false && (!groups || groups.length === 0)">No se encontrarón grupos.</p>
            <span v-if="loading == true && (!groups || groups.length === 0)" class="text-center">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    :indeterminate="loading"
                >
                </v-progress-circular>
                <p class="pt-2 text-subtitle-2">Cargando Grupos...</p>
            </span>
          <v-card class="card-hover mr-3" v-for="(item, i) in groups" :key="i" max-width="344" outlined elevation="3" @click="selectGroup(item)">
            <v-list-item three-line>
              <v-list-item-content>
                <b class="text-h6 font-weight-bold primary--text"
                  >{{ item.name }}</b
                >
                <!-- <label>{{ item.cost_center_id ? item.cost_center.code : "---" }}</label> -->
                <p class="text-h6">{{ item.start_hour }} - {{ item.finish_hour }}</p>
                <div class="ctDayW">
                  <span :class="item.work_days[0] === true ? 'primary white--text' : ''" title="Lunes">L</span>
                  <span :class="item.work_days[1] === true ? 'primary white--text' : ''" title="Martes">M</span>
                  <span :class="item.work_days[2] === true ? 'primary white--text' : ''" title="Miercoles">M</span>
                  <span :class="item.work_days[3] === true ? 'primary white--text' : ''" title="Jueves">J</span>
                  <span :class="item.work_days[4] === true ? 'primary white--text' : ''" title="Viernes">V</span>
                  <span :class="item.work_days[5] === true ? 'primary white--text' : ''" title="Sabado">S</span>
                  <span :class="item.work_days[6] === true ? 'primary white--text' : ''" title="Domingo">D</span>
                </div>
                <div class="mt-1">
                  <v-chip
                    :color="!item.overtime ? 'red' : 'green'"
                    text-color="white"
                    x-small
                    label
                  >
                    <v-icon left x-small>{{!item.overtime ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline' }}</v-icon>
                    Horas Extras
                  </v-chip>
                  <v-chip
                    :color="!item.festive_overtime ? 'red' : 'green'"
                    text-color="white"
                    class="ml-3"
                    x-small
                    label
                  >
                    <v-icon left x-small>{{!item.festive_overtime ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline' }}</v-icon>
                    Festivos
                  </v-chip>
                </div>
                <div>
                  <v-chip
                    :color="!item.sunday_overtime ? 'red' : 'green'"
                    text-color="white"
                    x-small
                    label
                  >
                    <v-icon left x-small>{{!item.sunday_overtime ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline' }}</v-icon>
                    Dominicales
                  </v-chip>
                  <v-chip
                    :color="!item.night_overtime ? 'red' : 'green'"
                    text-color="white"
                    class="ml-3"
                    x-small
                    label
                  >
                    <v-icon left x-small>{{!item.night_overtime ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline' }}</v-icon>
                    Nocturnas
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-1 mx-5"></v-divider>

    <v-row v-if="groupSelected">
      <v-col cols="12">
        <div class="ctDataTable">
          <v-data-table
            :headers="headers"
            :items="peopleList"
            :search="search"
            class="elevation-1"
            :header-props="{
                mobile: true,
            }"
            :footer-props="{
                'items-per-page-options': [20],
            }"
            :loading="loadingTable"
            loading-text="Cargando Información..."
            :no-results-text="`No se encontrarón personas por la cedula ingresada (${search})`"
            no-data-text="No hay registros que mostrar"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                    <div class="text-h5 text-center font-weight-bold primary--text">{{ groupSelected.name }}</div>
                    <div class="text-center"><small>{{ actualFortnight(nowDate) }}</small></div>
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Filtrar empleados por cedula"
                  single-line
                  hide-details
                  class="mx-1"
                  outlined
                  rounded
                  dense
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  rounded
                  dark
                  class="mb-2"
                  @click="dialog2 = true"
                >
                  Agregar personas
                </v-btn>
                <v-btn
                  color="primary"
                  fab
                  small
                  dark
                  class="mx-1 mb-2"
                  @click="activateEditMode(groupSelected)"
                >
                  <v-icon dark class="mx-1">mdi-cog</v-icon>
                </v-btn>
                <v-btn
                  color="red"
                  fab
                  small
                  dark
                  class="mx-1 mb-2"
                  @click="deleteGroupModal = true"
                >
                  <v-icon class="mx-1">mdi-delete-empty</v-icon>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.nombre`]="{ item }">
              <label>{{ item.first_name + " " + (item.second_name ? item.second_name + " " : '') + item.first_last_name + " " + item.second_last_name }}</label>
            </template>
            <template v-slot:[`item.totalWorkedTime`]="{ item }">
              <label >{{ item.totalWorkedTime > 0 ? item.totalWorkedTime : '0'}}</label>
            </template>
            <template v-slot:[`item.totalAbsences`]="{ item }">
              <div>
                <label> {{ item.totalAbsences > 0 ? item.totalAbsences : '---' }}</label>
              </div>
            </template>
            <template v-slot:[`item.totalExtraHours`]="{ item }">
              <label :class="item.totalExtraHours > groupSelected.max_overtime && !groupSelected.overtime ? 'red--text' : 'success--text'">{{ item.totalExtraHours > groupSelected.max_overtime ? item.totalExtraHours : '---' }}</label>
            </template>
            <template v-slot:[`item.totalNightHours`]="{ item }">
              <div>
                <label :title="item.totalNightHours && item.totalNightHours > 0 && !groupSelected.night_overtime ? 'Tiene horas extra nocturnas pero el grupo no las tiene habilitadas' : 'Las horas son validas'" :class="item.totalNightHours && item.totalNightHours > 0 && !groupSelected.night_overtime ? 'red--text' : 'primary--text'"> {{ item.totalNightHours ? item.totalNightHours : '---' }}</label>
              </div>
            </template>
            <template v-slot:[`item.totalSundayHours`]="{ item }">
              <label :title="item.totalSundayHours && item.totalSundayHours > 0 && !groupSelected.sunday_overtime ? 'Tiene horas extra dominicales pero el grupo no las tiene habilitadas' : 'Las horas son validas'" :class="item.totalSundayHours > 0 && !groupSelected.sunday_overtime ? 'red--text' : 'success--text'">{{ item.totalSundayHours ? item.totalSundayHours : '---' }}</label>
            </template>
            <template v-slot:[`item.totalFestiveHours`]="{ item }">
              <div>
                <label :title="item.totalFestiveHours && item.totalFestiveHours > 0 && !groupSelected.festive_overtime ? 'Tiene horas extra festivas pero el grupo no las tiene habilitadas' : 'Las horas son validas'" :class="item.totalFestiveHours && item.totalFestiveHours > 0 && !groupSelected.festive_overtime ? 'red--text' : 'primary--text'"> {{ item.totalFestiveHours ? item.totalFestiveHours : '---' }}</label>
              </div>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon
                color="primary"
                class="text-center pl-1"
                title="Añadir permiso"
                @click="dialogEventuality = true;personSelected=item"
              >
                mdi-notebook-plus-outline
              </v-icon>
              <v-icon
                color="red"
                class="text-center pl-1"
                title="Remover empleado"
                @click="dialogDelete = true ; personToRemove = item"
              >
                mdi-close-box-outline
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
      
    <!-- --------------------- MUESTRA DATOS GENERALES ----------------------- -->

    <v-row v-if="!groupSelected">
      <v-col cols="12" class="my-0 py-0">
        <p class="text-center text-h6 py-0 my-0">Información general de la sede</p>
      </v-col>
      <v-col cols="12">
        <div v-if="loadingChartGeneralInfo == true && (!chartDataArray || chartDataArray.length === 0)" class="text-center">
          <span>
              <v-progress-circular
                  :size="50"
                  color="primary"
                  :indeterminate="loadingChartGeneralInfo"
              >
              </v-progress-circular>
              <p class="pt-2 text-subtitle-2">Cargando Grafico...</p>
          </span>
        </div>
        <bar-chart
          v-if="chartDataArray.length > 0"
          style="height: 400px !important ; width: 100%  !important ; position: 'relative' !important"
          label="Resumen"
          :chartData="{
              month: [],
              arrayData: chartDataArray
          }"
        ></bar-chart>
      </v-col>
      <v-col cols="12">
        <div v-if="loadingTableGeneralInfo == true && (!generalData || generalData.length === 0)" class="text-center">
          <span>
              <v-progress-circular
                  :size="50"
                  color="primary"
                  :indeterminate="loadingTableGeneralInfo"
              >
              </v-progress-circular>
              <p class="pt-2 text-subtitle-2">Cargando Lista...</p>
          </span>
        </div>
        <div class="ctDataTable" v-if="generalData.length > 0">
          <v-data-table
            :headers="headers"
            :items="generalData"
            class="elevation-1"
            :search="searchGeneral"
            :header-props="{
                mobile: true,
            }"
            :footer-props="{
                'items-per-page-options': [20],
            }"
            :loading="loadingTable"
            loading-text="Cargando Información..."
            :no-results-text="`No se encontrarón personas por la cedula ingresada (${searchGeneral})`"
            no-data-text="No hay registros que mostrar"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                    <div class="text-h5 text-center font-weight-bold primary--text">{{ generalCampus.name }}</div>
                    <div class="text-center"><small>{{ actualFortnight(nowDate) }}</small></div>
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchGeneral"
                  append-icon="mdi-magnify"
                  label="Filtrar empleados por cedula"
                  single-line
                  hide-details
                  class="mx-1"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:[`item.nombre`]="{ item }">
              <label>{{ item.first_name + " " + (item.second_name ? item.second_name + " " : '') + item.first_last_name + " " + item.second_last_name }}</label>
            </template>
            <template v-slot:[`item.totalWorkedTime`]="{ item }">
              <label >{{ item.totalWorkedTime > 0 ? item.totalWorkedTime : '0'}}</label>
            </template>
            <template v-slot:[`item.totalAbsences`]="{ item }">
              <div>
                <label> {{ item.totalAbsences > 0 ? item.totalAbsences : '---' }}</label>
              </div>
            </template>
            <template v-slot:[`item.totalExtraHours`]="{ item }">
              <label :class="item.totalExtraHours > 35 && !temporalVar ? 'red--text' : 'success--text'">{{ item.totalExtraHours > 35 ? item.totalExtraHours : '---' }}</label>
            </template>
            <template v-slot:[`item.totalNightHours`]="{ item }">
              <div>
                <label :title="item.totalNightHours && item.totalNightHours > 0 && !temporalVar ? 'Tiene horas extra nocturnas pero el grupo no las tiene habilitadas' : 'Las horas son validas'" :class="item.totalNightHours && item.totalNightHours > 0 && !temporalVar ? 'red--text' : 'primary--text'"> {{ item.totalNightHours ? item.totalNightHours : '---' }}</label>
              </div>
            </template>
            <template v-slot:[`item.totalSundayHours`]="{ item }">
              <label :title="item.totalSundayHours && item.totalSundayHours > 0 && !temporalVar ? 'Tiene horas extra dominicales pero el grupo no las tiene habilitadas' : 'Las horas son validas'" :class="item.totalSundayHours > 0 && !temporalVar ? 'red--text' : 'success--text'">{{ item.totalSundayHours ? item.totalSundayHours : '---' }}</label>
            </template>
            <template v-slot:[`item.totalFestiveHours`]="{ item }">
              <div>
                <label :title="item.totalFestiveHours && item.totalFestiveHours > 0 && !temporalVar ? 'Tiene horas extra festivas pero el grupo no las tiene habilitadas' : 'Las horas son validas'" :class="item.totalFestiveHours && item.totalFestiveHours > 0 && !temporalVar ? 'red--text' : 'primary--text'"> {{ item.totalFestiveHours ? item.totalFestiveHours : '---' }}</label>
              </div>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon
                color="primary"
                class="text-center pl-1"
                title="Añadir permiso"
                @click="dialogEventuality = true;personSelected=item"
              >
                mdi-notebook-plus-outline
              </v-icon>
              <v-icon
                color="red"
                class="text-center pl-1"
                title="Remover empleado"
                @click="dialogDelete = true ; personToRemove = item"
              >
                mdi-close-box-outline
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../../globals";
import auth from "../../services/auth";
//import roles from "../services/role";
import dashReport from "./dash-report.vue"
import BarChart from "./charts/bar.vue"
import ExportReport from "./export-report.vue"

export default {
  name: "NominalView",
    components: {
        dashReport,
        BarChart,
        ExportReport
    },
  data: () => ({
    //modal
    dialog: false,
    dialog2: false,
    dialogDash: false,
    dialogEdit: false,
    dialogReport: false,
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    loading: false,
    loadingReportBtn: true,
    loadingTable: false,
    loadingTableGeneralInfo: false,
    loadingChartGeneralInfo: false,
    permissionDate: "",
    searchGeneral: "",
    allRegistry: [],

    temporalVar: true,

    //form data time 01
    time: null,
    menu2: false,

    //form data time 02
    time2: null,
    menu3: false,

    datepicker1: false,
    nowDate: globals.COdate().substring(0, 10),

    //table
    deleteGroupModal: false,
    dialogDelete: false,
    dialogEventuality: false,
    newEventuality: {
        date: "",
        description:""
    },
    personSelected: {},
    search: "",
    searchWG: "",
    personToRemove: null,
    headers: [
      {
        text: "Nombre",
        align: "center",
        sortable: false,
        value: "nombre",
      },
      { text: "Cédula", align: "center", value: "document_number", sortable: false },
      //{ text: "Cargo", align: "center", value: "cargo", sortable: false },
      { text: "Horas trabajadas", align: "center", value: "totalWorkedTime", sortable: false },
      { text: "Total ausentismos", align: "center", value: "totalAbsences", sortable: false },
      { text: "Total horas extra", align: "center", value: "totalExtraHours", sortable: false },
      { text: "Total horas nocturnas", align: "center", value: "totalNightHours", sortable: false },
      { text: "Total horas dominicales", align: "center", value: "totalSundayHours", sortable: false }, 
      { text: "Total horas Festivas", align: "center", value: "totalFestiveHours", sortable: false },
      { text: "Acciones", align: "center", value: "acciones", sortable: false }
    ],
    desserts: [],
    groups: [],
    newWG: {
        name: "",
        cost_center: "",
        start_hour: "",
        finish_hour: "",
        extra_hours: false,
        extra_hours_allowed: "",
        festive_hours: false,
        sunday_hours: false,
        night_hours: false,
        hours_x_day: "",
        workDays: [
            false,
            false,
            false,
            false,
            false,
            false,
            false
        ], 
    },
    groupSelected: null,
    registry: [],
    peopleToAsign: null,
    peopleList: [],
    months: [
      { id: "01", name: "Enero" },
      { id: "02", name: "Febrero" },
      { id: "03", name: "Marzo" },
      { id: "04", name: "Abril" },
      { id: "05", name: "Mayo" },
      { id: "06", name: "Junio" },
      { id: "07", name: "Julio" },
      { id: "08", name: "Agosto" },
      { id: "09", name: "Septiembre" },
      { id: "10", name: "Octubre" },
      { id: "11", name: "Noviembre" },
      { id: "12", name: "Diciembre" },
    ],
    generalData: [],
    chartDataArray: [],
    costCenters: [],
    generalCampus: null,
  }), // TODO: 1007782177, 0014250011 2021-11-25
  methods: {
    remove(item) {
      const index = this.peopleToAsign.indexOf(item)
      if (index >= 0) this.peopleToAsign.splice(index, 1)
    },

    customFilter(item, queryText) {
      const textAllName = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.second_name) ? item.second_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '') + " " + ((item.second_last_name) ? item.second_last_name.toLowerCase() : '');
      const textNameAndFirstLastname = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '') + " " + ((item.second_last_name) ? item.second_last_name.toLowerCase() : '');
      const textNameAndAllLastname = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '');
      const cedula = (item.document_number) ? item.document_number : '';
      const searchText = queryText.toLowerCase();

      return (
        textAllName.indexOf(searchText) > -1 ||
        textNameAndFirstLastname.indexOf(searchText) > -1 ||
        textNameAndAllLastname.indexOf(searchText) > -1 || 
        cedula.indexOf(searchText) > -1
      );
    },

    customFilterCostCenter(item, queryText) {
      const name = (item.name) ? item.name.toLowerCase() : '';
      const code = (item.code) ? item.code.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return (
        name.indexOf(searchText) > -1 ||
        code.indexOf(searchText) > -1
      );
    },

    actualFortnight(nowDate) {
      let monthIndex = this.months.findIndex(item => item.id === nowDate.substring(5, 7))

      if (nowDate > nowDate.substring(0, 8)+"15") {
        return this.months[monthIndex].name + "-02"
      } else {
        return this.months[monthIndex].name + "-01"
      }
    },

    checkTypeOfShift(start_hour) {
      if (start_hour >= "06:00" && start_hour < "12:00") {
        return "day"
      } else if ((start_hour >= "18:00" && start_hour < "23:59") || (start_hour >= "00:00" && start_hour < "06:00")) {
        return "night"
      } else {
        return "day"
      }
    },

    asignPeople(people) {
        if (people.length > 0) {
            let CompanyPersonIDs = []
            let CompanyPersonNew = []
            people.forEach(element => {
                CompanyPersonIDs.push({uuid: element.company_person.uuid})
                CompanyPersonNew.push(element.company_person)
            });
            this.groupSelected.company_person = [...this.groupSelected.company_person, ...CompanyPersonNew]
            axios
                .post(globals.APIURL + "working-group/asign-people", {
                    people: CompanyPersonIDs,
                    working_group_id: this.groupSelected.uuid
                })
                .then((res) => {
                    if (res.data === 'People asigned to WG successfuly') {
                        console.log(res.data)
                        this.dialog2 = false
                        this.peopleToAsign = []
                        this.selectGroup(this.groupSelected)
                        this.snackbarText = "Se asignarón " + people.length + " personas correctamente.";
                        this.snackbarColor = "success";
                        this.snackbar = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },

    async getRegistry() {
      await axios
        .post(globals.APIURL + "working-group/list-unassigned-people", {
          company_id: auth.getUserEnterpriseID()
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.registry = res.data;
            this.loadingReportBtn = false
          }
          this.loadingReportBtn = false
        })
        .catch((err) => {
          this.loadingReportBtn = false
          console.log(err);
        });
    },

    async campusData() {
      this.loadingTableGeneralInfo = true;
      this.loadingChartGeneralInfo = true;
      await axios
        .post(globals.APIURL + "registry/fa-by-sede", {
          //company_id: auth.getUserEnterpriseID(),
          campus_id: auth.getSelectedSede()
        })
        .then(async (res) => {
          if (res.data && res.data.length > 0) {
            console.log("REGISTROS: ", res.data)
            this.allRegistry = res.data
            let listPeople = []
            let dateNow = globals.COdate()
            let dateToFilter
            
            if (dateNow.substring(8, 10) <= 3 || dateNow.substring(8, 10) <= 15) {
                dateToFilter = globals.COdate().substring(0, 8) + "09";
            } else if (dateNow.substring(8, 10) >= 16 ) {
                dateToFilter = globals.COdate().substring(0, 8) + "17";
            }
            res.data.forEach(element => {
                listPeople.push({uuid: element.uuid})
            });

            let fecha = "2022-" + dateToFilter.substring(5, 7) + "-01";
            /* console.log(dateToFilter)
            console.log(listPeople) */
            await this.getRegistry()

            await axios
              .post(globals.APIURL + "working-group/list-assigned-people-by-date", {
                  campus_id: auth.getSelectedSede(),
                  company_id: auth.getUserEnterpriseID(),
                  people: listPeople,
                  fecha: dateToFilter,
                  groupSelected: {
                    fortnight_working_time: "96",
                    finish_hour: "17:00",
                    shift: "day",
                    eachDayHours: 8
                  }
              })
              .then((res) => {
                  if (res.data && res.data.length > 0) {
                      this.generalData = res.data
                      this.loadingTableGeneralInfo = false;
                      console.log("DATA PARA TABLA: ", res.data)
                  }
                  this.loadingTableGeneralInfo = false;
              })
              .catch((err) => {
                  this.loadingTableGeneralInfo = false;
                  console.log(err);
              });
            await axios
              .post(globals.APIURL + "working-group/chart-data", {
                campus_id: auth.getSelectedSede(),
                company_id: auth.getUserEnterpriseID(),
                people: listPeople,
                fecha: fecha,
                groupSelected: {
                  fortnight_working_time: "96",
                  finish_hour: "17:00",
                  shift: "day",
                  eachDayHours: 8
                }
              })
              .then((res) => {
                console.log(res.data)
                this.chartDataArray = res.data.esto;
                this.loadingChartGeneralInfo = false;
              })
              .catch((err) => {
                this.loadingChartGeneralInfo = false;
                console.log(err);
              });
          }
        })
        .catch((err) => {
          this.loadingTableGeneralInfo = false;
          this.loadingChartGeneralInfo = false;
          console.log(err);
        });
    },

    searchWGByPerson(cedula) {
        this.loading = true
        this.groupSelected = null
        this.peopleList = []
        this.groups = []

        if (cedula.length == 7) {
          cedula = "000" + cedula;
        } else if (cedula.length == 8) {
          cedula = "00" + cedula;
        } else if (cedula.length == 9) {
          cedula = "0" + cedula;
        }

        axios
            .post(globals.APIURL + "working-group/list-wg-by-person", {
                company_id: auth.getUserEnterpriseID(),
                campus_id: auth.getSelectedSede(),
                document_number: cedula
            })
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    this.groups = res.data
                    this.loading = false
                }
                this.loading = false
            })
            .catch((err) => {
                this.loading = false
                console.log(err);
            });
    },

    getWorkingGroups() {
        this.loading = true
        this.groupSelected = null
        this.peopleList = []
        this.groups = []
      axios
        .post(globals.APIURL + "working-group/list-wg", {
          campus_id: auth.getSelectedSede(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.groups = res.data
            this.loading = false
          }
          this.loading = false
        })
        .catch((err) => {
            this.loading = false
          console.log(err);
        });
    },

    getCostCenters() {
      axios
        .post(globals.APIURL + "working-group/all-cost-center", {
          campus_id: auth.getSelectedSede()
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.costCenters = res.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createEventuality(person, eventuality) {
        if (eventuality.date && eventuality.description) {
            axios
                .post(globals.APIURL + "working-group/create-permission", {
                    company_person_id: person.company_person[0].uuid,
                    event_date: eventuality.date + "T00:00:00.000Z",
                    description: eventuality.description,
                })
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.personSelected = {}
                        this.dialogEventuality = false
                        this.loading = false
                        this.dialogEdit = false
                        this.snackbarText = "Se creo la eventualidad";
                        this.snackbarColor = "success";
                        this.snackbar = true;
                        this.newEventuality.date = ""
                        this.newEventuality.description = ""
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    console.log(err);
                });
        } else {
            this.snackbarText = "Es necesario que llene todos los campos";
            this.snackbarColor = "red";
            this.snackbar = true;
        }
    },

    activateEditMode(workingGroup) {
        this._beforeEditingWGCache = Object.assign({}, workingGroup);
        this._beforeEditingWGCacheWorkDays = Object.assign({}, workingGroup.work_days);
        this.dialogEdit = true
    },

    cancelEditMode(workingGroup) {
        Object.assign(workingGroup, this._beforeEditingWGCache);
        Object.assign(workingGroup.work_days, this._beforeEditingWGCacheWorkDays);
        this.dialogEdit = false
    },

    editWG(WG) {
        this.groupShift = this.checkTypeOfShift(WG.start_hour)
        let workDaysLength= WG.work_days.filter(x => x === true).length
        let startHour
        let finishHour
        if (WG.overtime == false) WG.max_overtime = ""

        if (WG.name /* && WG.cost_center */ && WG.start_hour && WG.finish_hour /* && (WG.finish_hour > WG.start_hour) */ && workDaysLength > 1) {
          if (WG.overtime && (!WG.max_overtime || WG.max_overtime === "")) {
            this.snackbarText = "Si se habilitan la horas extra se debe ingresar un máximo de horas permitidas.";
            this.snackbarColor = "error";
            this.snackbar = true;
          } else {
            if (this.groupShift == "day") {
              startHour = new Date("1990-01-01T" + WG.start_hour + ":00")
              finishHour = new Date("1990-01-01T" + WG.finish_hour + ":00")
            } else if (this.groupShift == "night") {
              startHour = new Date("1990-01-01T" + WG.start_hour + ":00")
              finishHour = new Date("1990-01-02T" + WG.finish_hour + ":00")
            }
            let datesToMilliseconds = (finishHour - startHour)
            let hoursInDay = Math.floor((datesToMilliseconds % 86400000) / 3600000); // Horas
            let minutesInDay = Math.round(((datesToMilliseconds % 86400000) % 3600000) / 60000); // Minutos


            axios
                .put(globals.APIURL + "working-group/update", {
                    working_group_id: WG.uuid,
                    update_data: {
                        name: WG.name,
                        cost_center_id: WG.cost_center.uuid ? WG.cost_center.uuid : "",
                        start_hour: WG.start_hour,
                        finish_hour: WG.finish_hour,
                        fortnight_working_time: (((hoursInDay * workDaysLength) * 2 ) + (((minutesInDay * workDaysLength) * 2) / 60)).toString(),
                        max_overtime: WG.max_overtime,
                        work_days: WG.work_days,
                        overtime: WG.overtime,
                        festive_overtime: WG.festive_overtime,
                        sunday_overtime: WG.sunday_overtime,
                        night_overtime: WG.night_overtime
                    }
                })
                .then((res) => {
                    if (res.data && res.data === "Working Group updated successfuly") {
                        this.selectGroup(this.groupSelected)
                        this.dialogEdit = false
                        this.snackbarText = "Se actualizó el grupo de trabajo";
                        this.snackbarColor = "success";
                        this.snackbar = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
          }
        } else if (!WG.name || !WG.cost_center) {
            this.snackbarText = "Los campos de texto son obligatorios";
            this.snackbarColor = "error";
            this.snackbar = true;
        } else if (!WG.finish_hour || !WG.start_hour || (WG.finish_hour <= WG.start_hour)) {
            this.snackbarText = "Por favor verifique el intervalo de horas.";
            this.snackbarColor = "error";
            this.snackbar = true;
        } else if (workDaysLength <= 1) {
            this.snackbarText = "Por favor verifique la cantidad de dias laborales.";
            this.snackbarColor = "error";
            this.snackbar = true;
        }
    },

    selectGroup(item) {
        this.loadingTableGeneralInfo = false
        this.loadingChartGeneralInfo = false
        this.generalData = []
        this.chartDataArray = []
        console.log(item)
        this.loadingTable = true
        this.groupSelected = item
        this.peopleList = []
        let listPeople = []
        let dateNow = globals.COdate()
        let dateToFilter
        if (dateNow.substring(8, 10) <= 3 || dateNow.substring(8, 10) <= 15) {
            dateToFilter = globals.COdate().substring(0, 8) + "09";
        } else if (dateNow.substring(8, 10) >= 16 ) {
            dateToFilter = globals.COdate().substring(0, 8) + "17";
        }
        item.company_person.forEach(element => {
            listPeople.push({uuid: element.person_id})
        });
        axios
            .post(globals.APIURL + "working-group/list-assigned-people-by-date", {
                campus_id: auth.getSelectedSede(),
                company_id: auth.getUserEnterpriseID(),
                //working_group_id: item.uuid,
                people: listPeople,
                fecha: dateToFilter,
                groupSelected: item
            })
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    console.log(res.data)
                    this.peopleList = res.data
                    this.loadingTable = false
                }
                this.loadingTable = false
            })
            .catch((err) => {
                this.loadingTable = false
                console.log(err);
            });
    },

    deleteWG() {
      let listPeople = []
      this.groupSelected.company_person.forEach(element => {
        listPeople.push({uuid: element.person_id})
      });
      axios
          .post(globals.APIURL + "working-group/remove-wg", {
              campus_id: auth.getSelectedSede(),
              company_id: auth.getUserEnterpriseID(),
              working_group_id: this.groupSelected.uuid,
              people: listPeople
          })
          .then((res) => {
              if (res.data && res.data == "Working group deleted successfuly") {
                  console.log(res.data)
                  this.snackbar = true;
                  this.snackbarText = "El grupo fue eliminado correctamente."
                  this.snackbarColor = "success"
                  this.deleteGroupModal = false
                  this.groupSelected = null
                  this.getWorkingGroups()
                  this.campusData()
              }
          })
          .catch((err) => {
              console.log(err);
          });
    },

    createGroup(obj) {
        let startHour
        let finishHour
        let workDaysLength= obj.workDays.filter(x => x === true).length

        if (this.groupShift == "day") {
          startHour = new Date("1990-01-01T" + obj.start_hour + ":00")
          finishHour = new Date("1990-01-01T" + obj.finish_hour + ":00")
        } else if (this.groupShift == "night") {
          startHour = new Date("1990-01-01T" + obj.start_hour + ":00")
          finishHour = new Date("1990-01-02T" + obj.finish_hour + ":00")
        }
        let datesToMilliseconds = (finishHour - startHour)
        let hoursInDay = Math.floor((datesToMilliseconds % 86400000) / 3600000); // Horas
        let minutesInDay = Math.round(((datesToMilliseconds % 86400000) % 3600000) / 60000); // Minutos
        if (obj.extra_hours == false) obj.extra_hours_allowed = ""

        if (obj.name /* && obj.cost_center */ && obj.start_hour && obj.finish_hour /* && (obj.finish_hour > obj.start_hour) */ && workDaysLength > 1) {
          if (obj.extra_hours && (!obj.extra_hours_allowed || obj.extra_hours_allowed === "")) {
            this.snackbarText = "Si se habilitan la horas extra se debe ingresar un máximo de horas permitidas.";
            this.snackbarColor = "error";
            this.snackbar = true;
          } else {
            axios
                .post(globals.APIURL + "working-group/create", {
                    name: obj.name,
                    cost_center_id: obj.cost_center.uuid ? obj.cost_center.uuid : "",
                    start_hour: obj.start_hour,
                    finish_hour: obj.finish_hour,
                    fortnight_working_time: (((hoursInDay * workDaysLength) * 2 ) + (((minutesInDay * workDaysLength) * 2) / 60)).toString(),
                    max_overtime: obj.extra_hours_allowed,
                    work_days: obj.workDays,
                    overtime: obj.extra_hours,
                    festive_overtime: obj.festive_hours,
                    sunday_overtime: obj.sunday_hours,
                    night_overtime: obj.night_hours,
                    campus_id: auth.getSelectedSede(),

                })
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.dialog = false
                        this.clearGroup()
                        this.getWorkingGroups()
                        this.snackbarText = "El grupo fue creado.";
                        this.snackbarColor = "success";
                        this.snackbar = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                }); 
          }
        } else if (!obj.name || !obj.cost_center) {
            this.snackbarText = "Los campos de texto son obligatorios";
            this.snackbarColor = "error";
            this.snackbar = true;
        } /* else if (!obj.finish_hour || !obj.start_hour || (obj.finish_hour <= obj.start_hour)) {
            this.snackbarText = "Por favor verifique el intervalo de horas.";
            this.snackbarColor = "error";
            this.snackbar = true;
        } */ else if (workDaysLength <= 1) {
            this.snackbarText = "Por favor verifique la cantidad de dias laborales.";
            this.snackbarColor = "error";
            this.snackbar = true;
        }
    },

    getSede() {
      axios
        .post(globals.APIURL + "campus/foc", {
          campus_id: auth.getSelectedSede(),
        })
        .then((res) => {
          if (res.data) {
            this.generalCampus = res.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clearGroup() {
        this.newWG.name = "";
        this.newWG.cost_center = "";
        this.newWG.start_hour = "";
        this.newWG.finish_hour = "";
        this.newWG.extra_hours = false;
        this.newWG.extra_hours_allowed = "";
        this.newWG.festive_hours = false;
        this.newWG.sunday_hours = false;
        this.newWG.night_hours = false;
        this.newWG.hours_x_day = "";
        this.newWG.workDays = [
            false,
            false,
            false,
            false,
            false,
            false,
            false
        ]
    },

    removeEmployee(employee) {
      let cp_index = employee.company_person.findIndex(item => item.company_id === auth.getUserEnterpriseID())
      axios
        .put(globals.APIURL + "registry/ur", {
            company_person_id: employee.company_person[cp_index].uuid,
            update_company_obj: {
                working_group_id: null
            },
            person_id: employee.uuid,
            update_person_obj: {
                updated_at: globals.COdate()
            }
        })
        .then((res) => {
            if (res.data === 'Documents were updated successful') {
                this.dialogDelete = false
                let index = this.peopleList.indexOf(employee)
                this.peopleList.splice(index, 1)
                this.snackbarText = "Se removio la persona.";
                this.snackbarColor = "success";
                this.snackbar = true;
            }
        })
        .catch((err) => {
            console.log(err);
        });
      
    },

  },
  created() {
    //this.getRegistry()
    this.getSede()
    this.campusData()
    this.getCostCenters()
    this.getWorkingGroups()
  },
};
</script>
<style>
/* GROUP BOX FEATURES STYLE ↓ */
.ctctCardGroup {
  display: flex;
  flex-direction: row;
  width: 95%;
  border: 2px solid #69676751;
  border-radius: 12px;
  overflow-x: auto;
  margin: 10px auto;
}

.ctctCardGroup::-webkit-scrollbar-track {
  height: 5px;
  border-radius: 10px;
  margin: 10px;
}

.ctctCardGroup::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(136, 136, 136, 0.666);
}

.ctctCardGroup::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.609);
}

.card-hover:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* OTHERS ↓ */

.ctDayW {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 7px;
  margin: 0px 0;
}
.ctDayW span {
  background: #dbdbdb;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  padding-top: 8px;
  font-size: small;
}

/* DATA TABLE ↓ */
.ctDataTable {
  width: 95%;
  margin: 10px auto;
}
.ctDataTable table td {
  font-size: 1rem !important;
}

/* form group */
.ctWeekOpc {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}
.ctWeekOpc input {
  position: absolute;
  z-index: 1;
  opacity: 0;
}
.ctWeekOpc label {
  display: block;
  color: #666;
  height: 45px;
  width: 45px;
  background: #ededed;
  padding: 12px 14px;
  border-radius: 30px;
  text-align: center;
  margin: 0 auto;
}
.ctWeekOpc label:hover {
  cursor: pointer;
}
.ctWeekOpc input:checked + label {
  background: #60a5fa;
  color: #fff;
}
</style>
