<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <p class="grey--text font-weight-bold text-h3">Admin. de Equipos</p>
      </v-col>
    </v-row>
    <v-row class="px-5" v-if="devices && devices.length === 0">
        <v-col cols="12" md="3" v-for="(item, i) in 3" :key="i" class="d-flex justify-center pt-10">
            <v-skeleton-loader v-if="loading == true"
                transition="scale-transition"
                width="300px"
                type="card"
            ></v-skeleton-loader>
            <p v-if="loading == false">No hay equipos que mostrar.</p>
        </v-col>
    </v-row>
    <v-row class="" v-if="devices && devices.length > 0">
        <v-col cols="12" md="4" v-for="(item, i) in devices" :key="i" class="pt-10">
            <v-card elevation="10" class="rounded-corners">
                <v-card-title class="d-flex justify-center align-center">
                    <v-row>
                        <v-col cols="12" md="6" class="d-flex justify-center ma-0 pa-0">
                            <span class="text-subtitle-1">
                                Tipo: 
                                <strong>{{ item.type_device.toUpperCase() }}</strong>
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex justify-center ma-0 pa-0">
                            <span v-if="!item.editMode" class="text-subtitle-1">
                                Nombre: 
                            <strong v-if="!item.editMode">{{ item.name ? item.name : 'S/A' }}</strong>
                            </span>
                            <v-text-field
                                v-model="item.name"
                                v-if="item.editMode"
                                class="ma-0 pa-0"
                                label="Nombre"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" sm="12" class="d-flex justify-center ma-0 pa-0">
                            <span class="text-subtitle-1"> 
                                ID: <strong>{{ item.mac }}</strong>
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" sm="12" class="d-flex justify-center ma-0 pa-0">
                            <span class="text-subtitle-1"> 
                                IP: <strong>{{ item.ip }}</strong> 
                            </span>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-divider class="mx-3 mt-2"></v-divider>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-list two-line class="ma-0 pa-0">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center text-wrap">
                                            Salida Automática:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="d-flex justify-center">
                                            <v-switch class="ml-4" v-model="item.automatic_exit" color="primary" inset :disabled="!item.editMode"
                                            ></v-switch>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-list two-line class="ma-0 pa-0">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            Hora Salida:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="d-flex justify-center">
                                            <span v-if="!item.editMode">{{ item.automatic_exit && item.time_exit ? item.time_exit : 'N/A'}}</span>
                                            <span v-if="item.editMode && !item.automatic_exit">N/A</span>
                                            <v-menu
                                                ref="menu"
                                                v-model="menuPicker"
                                                v-if="item.editMode && item.automatic_exit"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="item.time_exit"
                                                transition="scale-transition"
                                                offset-y
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="item.time_exit"
                                                        class="centered-input"
                                                        placeholder="Seleccione Hora"
                                                        dense
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="menuPicker"
                                                    v-model="item.time_exit"
                                                    no-title
                                                    @click:minute="$refs.menu[0].save(item.time_exit)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-list two-line class="ma-0 pa-0">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            Modo:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            <v-chip v-if="!item.editMode" class="ma-0" :color="item.mode === 'ENTRY' ? 'primary' : 'red'" text-color="white">
                                                {{ item.mode === "ENTRY" ? "Acceso" : "Salida" }}
                                            </v-chip>
                                            <v-chip v-if="item.editMode && item.automatic_exit" class="ma-0" :color="actualTime >= item.time_exit ? 'red' : 'primary'" text-color="white">
                                                {{ actualTime >= item.time_exit ? "Salida" : "Acceso" }}
                                            </v-chip>
                                            <v-select
                                                v-if="item.editMode && !item.automatic_exit"
                                                v-model="item.mode"
                                                class="pt-10"
                                                :items="modes"
                                                item-text="Name"
                                                item-value="ID"
                                                dense
                                                label="Modo"
                                            ></v-select>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-list two-line class="ma-0 pa-0">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            Estado:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            <v-tooltip bottom transition="scale-transition">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-chip 
                                                    class="darken-1"
                                                    :color="validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8)) === true ? 'green' : 'error'" 
                                                    text-color="white"
                                                    v-bind="attrs"
                                                    v-on="on">
                                                        <v-icon v-if="validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8))" class="text-center" color="white">
                                                            mdi-power-plug-outline
                                                        </v-icon>
                                                        <v-icon v-if="!validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8))" class="text-center" color="white">
                                                            mdi-power-plug-off-outline
                                                        </v-icon>
                                                    </v-chip>
                                                </template>
                                                <span>{{!validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8)) ? 'El equipo no está activo - última conexión: ' + item.last_connection.slice(0, 10) + " | " + item.last_connection.slice(11, 16) : 'El equipo está activo - última conexión: ' + item.last_connection.slice(0, 10) + " | " + item.last_connection.slice(11, 16)}}</span>
                                            </v-tooltip>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-if="!item.editMode || item.editMode === false">
                            <v-btn color="primary" block rounded outlined @click="activateEditMode(item, i)">
                                Editar
                            </v-btn>
                        </v-col>
                        <v-col cols="6" v-if="item.editMode === true" class="text-center">
                            <v-btn color="success" rounded outlined :loading="loadingBtns" @click="updateDevice(item)">
                                Guardar
                            </v-btn>     
                        </v-col>
                        <v-col cols="6" v-if="item.editMode === true">
                            <v-btn color="error" rounded outlined @click="cancelEditMode(item, i)">
                                cancelar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";

export default {
  name: "AdminDevices",
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    menuPicker: false,
    devices: null,
    loading: false,
    loadingBtns: false,
    actualTime: globals.COdate().slice(11, globals.COdate().length - 8),
    modes: [
      { ID: "ENTRY", Name: "ACCESO" },
      { ID: "EXIT", Name: "SALIDA" },
    ], // TODO: Cambiar la variable description por la que se cree para que funcione la hora de salida
  }),
  methods: {
    validateDvcConnection(lastConnectionTime) {
      const todaysTime = new Date(globals.COdate().slice(0, 10) + " " + globals.COdate().slice(11, globals.COdate().length - 8))
      lastConnectionTime = new Date(lastConnectionTime); 
      let difference = Math.round((todaysTime.getTime() - lastConnectionTime.getTime()) / 60000); // Diferencia entre fechas en minutos

      if (difference > 3) {
        return false
      } else {
        return true
      }
    },

    activateEditMode(item, i) {
        this._beforeEditingCache = Object.assign({}, item);
        this.devices[i].editMode = true
    },

    cancelEditMode(item, i) {
        Object.assign(item, this._beforeEditingCache);
        this.devices[i].editMode = false
    },

    getDevices() {
        this.loading = true;
        this.devices = [];
        axios
          .post(globals.APIURL + "device/fb", {
            filter: {
                campus_id: auth.getSelectedSede()
            },
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
                res.data.forEach(element => {
                    element.editMode = false
                });
                this.devices = res.data;
                this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
    },

    updateDevice(device) {
        if (device.name !== '') {
            (device.automatic_exit === false) ? device.time_exit = "" : device.time_exit
            if ((device.automatic_exit && device.time_exit) || (!device.automatic_exit && !device.time_exit)) {
                this.loadingBtns = true
                if (device.automatic_exit && device.time_exit && (this.actualTime >= device.time_exit)) {
                    device.mode = 'EXIT'
                } else {
                    device.mode = 'ENTRY'
                }
                axios
                    .put(globals.APIURL + "device/ud", {
                        device_id: device.uuid,
                        update_obj: {
                            name: device.name,
                            mode: device.mode,
                            automatic_exit: device.automatic_exit,
                            time_exit: device.time_exit,
                        },
                    })
                    .then((res) => {
                        if (res.data == "Device updated successfuly") {
                            this.snackbar = true;
                            this.snackbarColor = "success";
                            this.snackbarText = "Se actualizó el dispositivo con exito.";
                            this.getDevices()
                            this.loadingBtns = false
                        }
                        this.loadingBtns = false
                    })
                    .catch((err) => {
                        this.loadingBtns = false
                        console.log(err);
                    });
            } else {
                this.snackbar = true;
                this.snackbarColor = "red";
                this.snackbarText = "Si selecciona salida automática es necesario especificar una hora para el cambio.";
            }
        } else {
            this.snackbar = true;
            this.snackbarColor = "red";
            this.snackbarText = "El equipo no puede tener el campo Nombre vacío.";
        }
    },
  },
  mounted() {
    this.getDevices()
  },
};
</script>
<style>
    tr:hover {
        background-color: transparent !important;
    }
    .rounded-corners {
        border-radius: 25px !important;
    }
    .centered-input >>> input {
      text-align: center
    }

    .theme--light.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty) .v-input--switch__track {
        color: black !important
    }
</style>
